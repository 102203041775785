import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { Subject } from "rxjs";
import { Router } from '@angular/router';
import { AppConstants } from './common-utility/appconstants';
import { Settings } from './models/settings';
import { filter, takeUntil } from 'rxjs/operators';
import { SettingService } from './shared/services/setting.service';
import { CommonService } from './shared/services/common/common.service';
import { UserClaim } from './models/userClaim';
import { ConfigurationSettings } from './configuration-settings';
import { RoleGuardService } from './shared/services/role-gaurd-service';
import { SecurityService } from './shared/services/security.service';
import { Location } from '@angular/common';
import { BrowserUtils, InteractionStatus } from '@azure/msal-browser';
import { DataService } from './shared/services/data.service';
import { SecurityGroupService } from './shared/services/security-group.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ConfigurationSettings, SecurityService, RoleGuardService]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'cerebro-ui';

  public previousRouteURL = '';
  currentEnvironment = ConfigurationSettings.CURRENT_ENVIRONMENT_NAME;
  public isOwner: boolean;
  public claims: UserClaim = new UserClaim();  
  public groups;
  isIframe = false;
  loginDisplay = false;
  public map = new Map<string, any[]>();  
  //To modify the Program Setup header text in form
  count: number = 0;
  activeUser = '';

  private readonly onDestroy$ = new Subject<void>()

  constructor(@Inject(SettingService) private config: Settings,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private authService: MsalService,
    private location: Location,
    private service: DataService,
    private securityGroupService: SecurityGroupService,
    private commonService: CommonService) {

    const currentPath = this.location.path();

    // Dont perform nav if in iframe or popup, other than for front-channel logout
    this.isIframe = BrowserUtils.isInIframe() && !window.opener && currentPath.indexOf("logout") < 0; // Remove this line to use Angular Universal

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();        
      })
  }

  ngOnInit(): void {
    if (this.location.path() !== AppConstants.CEREBROLOGIN) {
      this.commonService.setSessionStorageValue('previousURL', this.location.path());
      this.router.navigate([AppConstants.CEREBROLOGIN]);
      this.count = 1;
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    if (activeAccount) {
      this.claims.isCerebro_Owner = false;
      this.claims.securityRoles = [];

      this.activeUser = activeAccount.name;
      let loggedUserId = activeAccount.localAccountId;        
      this.commonService.setSessionStorageValue(AppConstants.LOGGEDINUSERINFO, JSON.stringify(activeAccount));

      this.securityGroupService.GetSecurityGroupByUserId(loggedUserId)
      .subscribe(async result => {
          this.claims.securityRoles = result;
          await new Promise<void>((resolve, reject) => {
            let ownerDetails;
            if (this.currentEnvironment.toLowerCase() === AppConstants.Prod_Env 
            || this.currentEnvironment.toLowerCase() === AppConstants.Uat_Env) {
            ownerDetails = AppConstants.CEREBRO_OWNER_ID;
          }
          else if (this.currentEnvironment.toLowerCase() === AppConstants.Dev_Env
            || this.currentEnvironment.toLowerCase() === AppConstants.Qa_Env
            || this.currentEnvironment.toLowerCase() === AppConstants.Local_Env) {
            ownerDetails = AppConstants.CEREBRO_OWNER_DEV_ID;
          }
            this.service.getData(ownerDetails)
              .subscribe(resu => {
                this.addOwnerRole(resu.value, loggedUserId);
                this.commonService.setSessionStorageValue(AppConstants.UIUSERROLE, JSON.stringify(this.claims));
      
                this.getPermissions()
                resolve();
              });
          });    
      },
        error => {
          console.log(error);
        }
      );
    }
  }
  
  addOwnerRole(item, loggedUserId) {
    for (let i = 0; i < item.length; i++) {
      if (item[i].id === loggedUserId) {
        this.claims.isCerebro_Owner = true;
        break;
      }
    }
  }

  public getPermissions() {
    //get the roles from session storage

    let rolesData = this.commonService.getSessionStorageValue(AppConstants.UIUSERROLE);

    if (rolesData !== '' && rolesData !== null) {
      this.claims = JSON.parse(rolesData) as UserClaim;

      if (this.claims.isCerebro_Owner) {
        this.isOwner = this.claims.isCerebro_Owner
        this.count = 1;
      }

      if (this.claims.securityRoles.length > 0 || this.claims.isCerebro_Owner) {
        if (this.commonService.getSessionStorageValue('previousURL') != "" && this.commonService.getSessionStorageValue('previousURL') !== null && this.commonService.getSessionStorageValue('previousURL') !== AppConstants.UNAUTHORIZED) {
          let url = this.commonService.getSessionStorageValue('previousURL');
          this.router.navigateByUrl(url);
        }
        else {
          // window.open("#" + AppConstants.ONERINGHOME,"_self");
          this.router.navigate([AppConstants.CEREBROHOME]);
        }
      } else {
        this.router.navigate([AppConstants.UNAUTHORIZED]);
      }
    }
    else if (this.location.path() !== AppConstants.CEREBROLOGIN && this.commonService.getSessionStorageValue('previousURL') === null) {
      this.commonService.setSessionStorageValue('previousURL', this.location.path());
      this.router.navigate([AppConstants.CEREBROLOGIN]);
      this.count = 1;
    }
    // this.isAdministrator = this.roleGuardService.doesUserHaveRole('Administrator');
  }

  logout(): void {
    this.authService.logoutRedirect();
  }
}
