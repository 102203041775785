import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from 'src/app/shared/services/loading.service';

import { ExperimentPlanningModel, ProcessDetailModel, WebClientAPI } from './webclient-api';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { catchError, map, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileProcessService {
  private messageSource;
  
  httpOptions = {
    headers: new HttpHeaders({ 
        'enctype': 'multipart/form-data' 
    })
};

  constructor(private webClientAPI: WebClientAPI, private loadingService: LoadingService) { }

  form: ExperimentPlanningModel;

  

  public GetFileErrors(): Observable<any> {
    this.loadingService.setMessage('Loading...');
    return this.webClientAPI.fileProcess_GetErrorFiles()
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public deleteRogueFiles(data):  Observable<any> {
    this.loadingService.setMessage('Deleting file(s)...');
    this.messageSource = new BehaviorSubject(data);
    return this.webClientAPI.fileProcess_DeleteErrorFiles(data)
    .map(result => result)
    .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }


  
  public cancelStatusUpdate() {
    this.messageSource = new BehaviorSubject('cancel');
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    return dialogConfig;

  }
}