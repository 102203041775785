import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
    selector: 'checkbox-cell',
    template: `<input type="checkbox" [disabled]="params.context.disableCheckBox" [checked]="params.value" (change)="onChange($event)">`
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
    public params: ICellRendererParams;
    constructor() { }
    agInit(params: ICellRendererParams): void {
        this.params = params;
    }
    public onChange(event) {
        this.params.data[this.params.colDef.field] = event.currentTarget.checked;
        this.params.context.componentParent.checkBoxUpdated(this.params.data);
    }
    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}