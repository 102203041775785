import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ApiTypeService } from 'src/app/shared/services/api-type-service/api-type.service';
import { ClonerService } from 'src/app/shared/services/deep-clone/deep-clone.service';
import { MessageService } from 'src/app/shared/services/message-service';
import { ApiTypeDetailModel, ApiTypeModel, ExperimentMetaDataModel, GroupModel, ProjectModel, VesselModel } from 'src/app/shared/services/webclient-api';
import { SortByPipe } from 'src/app/shared/sort/sort-pipe';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';

import { ProjectService } from 'src/app/shared/services/project-service/project.service'
import { GroupsService } from 'src/app/shared/services/group-service/groups.service'

@Component({
  selector: 'app-create-api-type',
  templateUrl: './create-api-type.component.html',
  styleUrls: ['./create-api-type.component.scss']
})
export class CreateApiTypeComponent implements OnInit {

  apiTypeId: any;
  apiType: ApiTypeModel;
  oneringMetaData: ExperimentMetaDataModel;
  groupTypes: any;
  filteredGroupTypes: Observable<Array<any>>;
  methods: any
  filteredMethodOptions: Observable<Array<any>>;
  vessels?: VesselModel[] | undefined;
  selectedVessel: any;
  selectedMethodsValue: any;
  selectedGroupType: any;
  selectedSampleDays: any;
  customDays: any;
  totalVesselEstimate: number;
  hasDataModified: boolean = false;
  methodFilterControl = new FormControl();
  groupTypeFilterControl = new FormControl();
  sampleDays: any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  apiTypeName = "";
  analysisFrequency = "daily";
  isError = false;
  apiTypeDescription: any;
  errorMsg: any = null;
  pattern = new RegExp('^\\d+(?:[,]\\d+)*?$');
  projects:ProjectModel[]=[];
  groups:GroupModel[]=[];
  groupsOld:GroupModel[]=[];
  projectId:any;
  groupId:any;
  hasProjectModified:boolean=false;
  projectIdOld:number;
  groupIdOld:number;
  isApiTypeUsed:boolean=false;
  constructor(private toastr: ToastrService,
    private service: ApiTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private sortPipe: SortByPipe,
    public objectClone: ClonerService,
    private groupService: GroupsService,
    private projectService: ProjectService,
    private _messageService: MessageService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.apiTypeId = params['id'];
      if (this.apiTypeId == 0) {
        this.sampleDays.push('Custom');
        this.initializeApiType();
      }
      else
      {
        this.checkApiTypeUsedInTemplate(this.apiTypeId )
      }
    });
    this.getOneRingExperimentMetaData();
    this.loadProjects();

  }

  initializeApiType() {
    this.apiType = new ApiTypeModel({
      apiTypeId: 0,
      name: "",
      description: "",
      groupId: 0,
      projectId: 0,
      processTypeId: 4,
      version: 1,
      isActive: true,
      apiTypeDetails:
        new ApiTypeDetailModel({
          apiTypeDetailId: 0,
          apiTypeId: 0,
          groupTypeId: 0,
          analyticalMethods: "",
          analysisFrequency: "",
          sampleDays: "",
          totalVesselEstimate: 0,
          vesselId: 0,
          isActive: true
        })

    });
  }

  getApiType() {
    this.service.getApiTypeById(this.apiTypeId)
      .subscribe(
        result => {
          this.apiType = result;
          this.apiTypeName = this.apiType.name;
          this.apiTypeDescription = this.apiType.description;
          let apiTypeDetails = this.apiType.apiTypeDetails;
          this.projectId= this.apiType.projectId;
          if( this.projectId!=undefined || this.projectId!=0)
          {
            
            this.hasProjectModified=true;
            this.loadGroups(this.projectId)
          }
          this.groupId= this.apiType.groupId;
          this.projectIdOld=this.apiType.projectId;
            this.groupIdOld=this.apiType.groupId;
            
          this.selectedGroupType = this.groupTypes.filter(x => x.sampleInfoGroupId == apiTypeDetails.groupTypeId)[0];
          this.analysisFrequency = apiTypeDetails.analysisFrequency;
          this.selectedVessel = this.vessels.filter(x => x.vesselId == apiTypeDetails.vesselId)[0];
          this.totalVesselEstimate = apiTypeDetails.totalVesselEstimate;
          this.selectedMethodsValue = this.getSelectedMethods(apiTypeDetails?.analyticalMethods);
          this.selectedSampleDays = apiTypeDetails.sampleDays.split(",");
          apiTypeDetails.sampleDays.split(",").forEach(x => {
            if (Number(x) > 9)
              this.sampleDays.push(x);
          });
          this.sampleDays.push('Custom');
        },
        error => {
          console.log(error);
        }
      )

  }

  checkApiTypeUsedInTemplate(Id: number)
  {
    this.isApiTypeUsed=false;
    this.service.checkApiTypeUsedInTemplate(Id).subscribe(result => {

      if(result!=undefined && result!=false)
        {
          this.isApiTypeUsed=true;
        }
    
    },
      error => {
        this.toastr.error('Save file mapping failed !!');
      });
  }

  getOneRingExperimentMetaData() {
    this.service.getOneRingExperimentMetaData()
      .subscribe(
        async result => {
          this.oneringMetaData = result;
          this.groupTypes = this.oneringMetaData?.groups.filter(r => r.isActive === true);
          this.groupTypes = this.sortPipe.transform(this.groupTypes, 'asc', 'groupName');
          this.methods = this.oneringMetaData?.methods;
          this.vessels = this.oneringMetaData?.vessels;

          await new Promise<void>((resolve, reject) => {

            this.filteredGroupTypes = this.groupTypeFilterControl.valueChanges.pipe(
              startWith(""),
              map((value: string) => {
                this.groupTypes.forEach(option => {
                  option.show = option.groupName
                    .toLocaleLowerCase()
                    .includes(value.toLowerCase());
                });
                return this.groupTypes;
              })
            );
            resolve();
          });

          await new Promise<void>((resolve, reject) => {

            this.filteredMethodOptions = this.methodFilterControl.valueChanges.pipe(
              startWith(""),
              map((value: string) => {
                this.methods.forEach(option => {
                  option.show = option.methodName
                    .toLocaleLowerCase()
                    .includes(value.toLowerCase());
                });
                return this.methods;
              })
            );
            resolve();
          });

          if (this.apiTypeId != 0) {
            this.getApiType();
          }
        },
        error => {
          console.log(error);
        }
      )

  }

  valueChange(event) {
    this.hasDataModified = true;
  }

  isCustom: boolean = false;
  sampleDaysChange(event) {
    var result = this.selectedSampleDays.filter(x => x === 'Custom');
    if (result !== undefined && result !== null && result.length !== 0)
      this.isCustom = true;
    else
      this.isCustom = false;
    this.hasDataModified = true;
  }

  customValueChange(event) {
    this.hasDataModified = true;
  }

  onCancel() {
    this.navigateToApiTypes();
  }

  onSubmit() {
    if (this.isFormValid()) {
      this.isError = false;
      this.createApiTypeModel();
      if (this.apiTypeId == 0) {
        this.createApiType();
      }
      else {
        if((this.projectIdOld!=this.apiType.projectId || this.groupIdOld!=this.apiType.groupId) && this.isApiTypeUsed==true)
          {
            
            this.apiTypeUsedInTemplatePopup();
            
            return;
          }
        this.updateApiType();
      }
    }
    else {
      this.isError = true;
    }

  }
  
  apiTypeUsedInTemplatePopup() {
    let dialogConfig = this.service.getDialogConfig();
    let headerMessage = "Info";
    let messageType = "Info"
    let _message = "A Template with this API Type has already been created.  You cannot change the Project/Group at this point.";
    dialogConfig.data = { message: _message, headerMessage: headerMessage, messageType: messageType };
    this.dialog.open(StatusDialogComponent, dialogConfig);   
    this.getApiType();
  }


  createApiTypeModel() {
    var days = this.customDays === undefined || this.customDays === '' ? this.selectedSampleDays.filter(x => x !== 'Custom').toString() : this.selectedSampleDays.filter(x => x !== 'Custom').toString() + ',' + this.customDays;
    this.apiType = new ApiTypeModel({
      apiTypeId: this.apiTypeId,
      name: this.apiTypeName,
      description: this.apiTypeDescription,
      groupId: this.groupId,
      projectId: this.projectId,
      processTypeId: 4,
      version: 1,
      isActive: true,
      apiTypeDetails:
        new ApiTypeDetailModel({
          apiTypeDetailId: 0,
          apiTypeId: this.apiTypeId,
          groupTypeId: this.selectedGroupType.sampleInfoGroupId,
          analyticalMethods: this.getSelectedMethodIds(),
          analysisFrequency: this.analysisFrequency,
          sampleDays: this.split(days).toString(),
          totalVesselEstimate: this.totalVesselEstimate,
          vesselId: this.selectedVessel.vesselId,
          isActive: true
        })


    });
  }

  split(days) {
    var array = days.split(",");
    array.sort(function (a, b) { return a - b });

    var uniqueArray = [array[0]];

    for (var i = 1; i < array.length; i++) {
      if (array[i - 1] !== array[i])
        uniqueArray.push(array[i]);
    }
    return uniqueArray;
  }

  getSelectedMethodIds() {
    let methodIds = "";
    this.selectedMethodsValue?.forEach(item => {
      methodIds = methodIds === "" ? item.methodId.toString() : methodIds + "," + item.methodId.toString();
    });
    return methodIds;
  }

  getSelectedMethods(methodIds) {
    let methods = [];
    let methodIdsArray = methodIds.split(",");
    methodIdsArray.forEach(item => {
      let method = this.methods?.filter(x => x.methodId == item)[0];
      methods.push(method);
    });
    return methods;
  }

  isFormValid() {
    let isValid = true;

    if (this.apiTypeName.trim().length == 0) {
      isValid = false;
    }
    else if (this.selectedGroupType == null || this.selectedGroupType == undefined) {
      isValid = false;
    }
    else if (this.selectedVessel == null || this.selectedVessel == undefined) {
      isValid = false;
    }
    else if (this.totalVesselEstimate === undefined || this.totalVesselEstimate === null || this.totalVesselEstimate <= 0) {
      isValid = false;
    }
    else if (this.selectedMethodsValue == null || this.selectedMethodsValue == undefined) {
      isValid = false;
    }
    else if (this.selectedSampleDays == null || this.selectedSampleDays == undefined) {
      isValid = false;
    }
    else if (this.customDays !== undefined && this.customDays !== '' && !this.pattern.test(this.customDays)) {
      isValid = false;
    }
    else if (this.projectId == undefined || this.groupId == undefined || this.projectId == 0 || this.groupId == 0) {
      isValid = false;
    }
    
    return isValid;
  }

  createApiType() {
    this.service.getApiTypeByName(this.apiTypeName)
      .subscribe(
        result => {
          if (result != null) {

            if (result?.apiTypeDetails?.groupTypeId == this.apiType?.apiTypeDetails?.groupTypeId) {
              let dialogConfig = this.service.getValidationDialogConfig();
              let headerMessage = "Warning";
              dialogConfig.panelClass = "dialog-responsive";
              let message = "Previous versions of this API type exists and will be marked inactive. Would you like to proceed?";
              dialogConfig.data = { message: message, headerMessage: headerMessage };
              let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(
                status => {
                  if (status) {
                    this.saveApiType(this.apiType);
                  }
                  this.hasDataModified = true;
                });
            }
            else {
              this.hasDataModified = true;
              this.toastr.error("API Type Name already exists.", "API Type");
            }
          }
          else {
            this.saveApiType(this.apiType);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  saveApiType(apiType) {
    this.service.createApiType(this.apiType)
      .subscribe(
        result => {
          this._messageService.filter('refresh');
          this.toastr.success('API Type created successfully !!', 'API Type');
          this.navigateToApiTypes();
        },
        error => {
          console.log(error);
          this.toastr.error('API Type creation failed !!', 'API Type');
        }
      );
  }
  updateApiType() {

    this.service.getApiTypeByName(this.apiTypeName)
      .subscribe(
        result => {

          this.service.updateApiType(this.apiType)
            .subscribe(
              result => {
                this._messageService.filter('refresh');
                this.toastr.success('API Type updated successfully !!', 'API Type');
                this.navigateToApiTypes();
              },
              error => {
                console.log(error);
                this.toastr.error('API Type update failed !!', 'API Type');
              }
            );
        },
        error => {
          console.log(error);
        }
      );
  }

  projectSelectionChange(projectId) {
    this.hasDataModified = true;
    this.hasProjectModified=true;
    this.groupId=0;
    this.loadGroups(projectId);
  }

 loadGroups(projectId) {   
    this.groupService.getGroupsByProjectId(projectId,false)
      .subscribe(
        result => {
          this.groups = result;
          this.groupsOld= result;

        },
        error => {
          console.log('error');
          this.toastr.error(error, 'Group load failed !!');
        });

  }
  loadProjects() {
    this.projectService.getProjectsForAdminUser()
      .subscribe(
        result => {
          this.projects = result;
        },
        error => {
          console.log('error');
          this.toastr.error(error, 'Project load failed !!');
        });

  }
  
  recordModify()
  {
    this.hasDataModified = true;
  }
  navigateToApiTypes() {
    this.router.navigate([AppConstants.CEREBROAPITYPESPAGE]);
  }

  compareVesslsFn: ((f1: any, f2: any) => boolean) | null = this.compareVesselsByValue;

  compareVesselsByValue(f1: any, f2: any) {
    return f1 && f2 && f1.vesselName === f2.vesselName;
  }

  compareSampleDaysFn: ((f1: any, f2: any) => boolean) | null = this.compareSampleDaysByValue;

  compareSampleDaysByValue(f1: any, f2: any) {
    return f1 && f2 && f1 == f2;
  }

  compareMethodsFn: ((f1: any, f2: any) => boolean) | null = this.compareethodsByValue;

  compareethodsByValue(f1: any, f2: any) {
    return f1 && f2 && f1.methodName === f2.methodName;
  }

  compareGroupTypesFn: ((f1: any, f2: any) => boolean) | null = this.compareGroupTypesByValue;

  compareGroupTypesByValue(f1: any, f2: any) {
    return f1 && f2 && f1.groupName === f2.groupName;
  }

}
