import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppModule } from './app/app.module';
import { ApplicationSettings, APPLICATION_SETTINGS } from './app/application-settings.service';
import { SettingService } from './app/shared/services/setting.service';
import { environment } from './environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { APP_INSIGHTS } from './app/shared/services/logging.service';

function msalLoggerCallback(logLevel: LogLevel, message: string) { console.log(`[MSAL][${LogLevel[logLevel]}]: ${message}`); }

LicenseManager.setLicenseKey('CompanyName=SHI International Corp._on_behalf_of_Pioneer Hi-Bred Intl Inc. (US, Johnston, IA 50131),LicensedGroup=DSI,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=25,LicensedProductionInstancesCount=0,AssetReference=AG-036752,SupportServicesEnd=2_September_2024_[v2]_MTcyNTIzMTYwMDAwMA==3211b93f03e1e90c9f013132ac4acca8');



fetch('/assets/application-settings.json')
  .then(response => response.json())
  .then((appSettings: ApplicationSettings) => {

    if (environment.production) {
      enableProdMode();
    }

    let appInsights: ApplicationInsights;

    if (appSettings.infrastructure.applicationInsightsConnectionString && appSettings.infrastructure.applicationInsightsConnectionString.length > 0) {
      appInsights = new ApplicationInsights({
        config: {
          connectionString: appSettings.infrastructure.applicationInsightsConnectionString,
          enableAutoRouteTracking: true,
        },
      });

      const telemetryInitializer = (envelope: any) => {
        envelope.tags['ai.cloud.role'] = 'Cerebro - UI';
      };

      appInsights.addTelemetryInitializer(telemetryInitializer);
      
      appInsights.loadAppInsights();
    } 

    platformBrowserDynamic([
      {
        provide: MSAL_INSTANCE, useValue: new PublicClientApplication({
          auth: appSettings.msal.config.auth,
          cache: appSettings.msal.config.cache,
          system: {
            loggerOptions: {
              loggerCallback: msalLoggerCallback,
              logLevel: LogLevel.Warning,
              piiLoggingEnabled: false
            }
          }
        })
      },
      {
        provide: MSAL_GUARD_CONFIG, useValue: { ...appSettings.msal.guard }
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useValue: { protectedResourceMap: new Map(appSettings.msal.interceptor.protectedResourceMap) }
      },
      {
        provide: APPLICATION_SETTINGS, useValue: { ...appSettings }
      },
      {
                provide: SettingService,
                useValue: { ...appSettings }
      },
      {
        provide: APP_INSIGHTS,
        useValue: appInsights,
      }
    ])
      .bootstrapModule(AppModule)
      .catch((err) => {
        if (appInsights) {
          appInsights.trackTrace({ message: 'Application failed to load.' }, err);
        }
        
        console.error(err);
      });
  });

  // function bootstrapApp() {
  //   var req = new XMLHttpRequest();
  
  //   req.addEventListener('error', (res: any) => {
  //     console.log('Error: ', res);
  //   })
  //   req.addEventListener('load', (res: any) => {
  //     const appConfig = JSON.parse(res.currentTarget.response);
  
  //     platformBrowserDynamic([
  //       {
  //         provide: MSAL_GUARD_CONFIG,
  //         useValue: appConfig.msalConfig
  //       },
  //       {
  //         provide: SettingService,
  //         useValue: appConfig
  //       },
  //     ]).bootstrapModule(AppModule)
  //       .catch(err => console.error(err));
  //   });
  
  //   req.open('GET', '/assets/application-settings.json');
  //   req.send(null);
  // }
  
  // bootstrapApp();