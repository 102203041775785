import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-files-popup',
  templateUrl: './delete-files-popup.component.html',
  styleUrls: ['./delete-files-popup.component.scss']
})
export class DeleteFilesPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
