import { EnvironmentDefinition } from "./environment-defination.interface"

/* 
    This is the class where i can define the key and its value 
    and it will transform the value of the key based on environment.

    --> If you want to add a new key and its value in the below section 
        then first you define that key in the EnvironmentDefinition interface. 
*/
export class Environments {

    public static environments: EnvironmentDefinition[] = [
        {
            name: 'LOCAL',
            hosts: ['localhost'],
            APIPath: 'https://localhost:44339',
            UIPath: 'http://localhost:4200',
            clientID: '7c7921d0-09b3-4f5b-a4cb-caca41158233',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CerebroBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "LclSession"
            }
        },
        {
            name: 'DEV',
            hosts: ['dev'],
            APIPath: 'https://cerebro-app-dev.azurewebsites.net/api',
            UIPath: 'https://cerebro-web-dev.azurewebsites.net/',
            clientID: '7c7921d0-09b3-4f5b-a4cb-caca41158233',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CerebroBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "DvSession"
            }
        },
        {
            name: 'TEST',
            hosts: ['test'],
            APIPath: 'https://cerebroapi-test.azurewebsites.net/api',
            UIPath: 'https://cerebro-test.azurewebsites.net',
            clientID: '7c7921d0-09b3-4f5b-a4cb-caca41158233',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CerebroBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::078183859623:role/dsi_qa-lexrole-sts",
                roleSessionName: "QASession"
            }
        },
        {
            name: 'UAT',
            hosts: ['uat'],
            APIPath: 'https://cerebroapi-uat.se.research.corteva.com/api',
            UIPath: 'https://cerebro-uat.se.research.corteva.com',
            clientID: '7c7921d0-09b3-4f5b-a4cb-caca41158233',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CerebroBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::078183859623:role/dsi_qa-lexrole-sts",
                roleSessionName: "QASession"
            }
        },
        {
            name: 'PROD',
            hosts: ['cerebro','prod'],
            APIPath: 'https://cerebroapi.se.research.corteva.com/api',
            UIPath: 'https://cerebro.se.research.corteva.com',
            clientID: '7c7921d0-09b3-4f5b-a4cb-caca41158233',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "CerebroBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::091853933339:role/dsi_pd-lexrole-sts",
                roleSessionName: "PdSession"
            }
        }
    ];
}