
import { GroupsService } from 'src/app/shared/services/group-service/groups.service';
import { GroupModel } from 'src/app/shared/services/webclient-api'
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/shared/services/message-service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';

@Component({
  selector: 'group-popup',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css']
})


export class CreateGroupComponent {
  isFormInvalid: boolean = false;
  clickEventTrigger: boolean = false;
  componentTitle = AppConstants.GROUPTITLE;
  componentNameNew = AppConstants.GROUPNEW;
  componentNameModify = AppConstants.MODIFYGROUPTITLE;
  isdataCopy: boolean = false;
  group: GroupModel;
  groups: GroupModel[] = [];
  validationErrorTxt: any;
  isError: boolean = false;
  oldGroupName: string;
  oldGroupDesc: string;
  groupName: string;
  groupDesc: string;
  hadDataChanged: boolean = false;
  isEditDisabled:boolean=false;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  constructor(private toastr: ToastrService,
    private service: GroupsService,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateGroupComponent>, private _messageService: MessageService) {
  }

  ngOnInit(): void {
    this.editProjectInfo();
    window.addEventListener('scroll', this.scrollEvent, true);
  }
  editProjectInfo() {
    this.isEditDisabled=false;        
    if (this.data.selectedData != undefined) {    
      this.group = this.data.selectedData;     
      this.oldGroupName = this.groupName = this.group.name;
      this.oldGroupDesc = this.groupDesc = this.group.description;      
      if(this.groupName!=undefined && this.groupName!="")
        {        
          this.isEditDisabled=true;
        }
    }
    if (this.data.selectedGridData != undefined) {      
      this.groups = this.data.selectedGridData;
    }
  }
  scrollEvent = (event: any): void => {
    this.autoComplete?.updatePosition();
  };

  onSubmit() {
    if (!this.checkValidRecord()) {
      this.group.name = this.groupName;
      this.group.description = this.groupDesc;
      if (this.group.groupId == 0) {

        this.service.addGroup(this.group).subscribe(result => {
          this._messageService.filter('refresh');
          this.toastr.success('Save group successful!!');
          this.onClose();
        },
          error => {
            this.toastr.error('Save group failed !!');
          });
      }
      else {
        if (this.isGroupModified(this.group)) {
          this.service.updateGroup(this.group).subscribe(result => {
            this._messageService.filter('refresh');
            this.toastr.success('Update group successful!!');
            this.onClose();
          },
            error => {
              this.toastr.error('Update group failed !!');
            });
        }
        else {
          let dialogConfig = this.service.getDialogConfig();    
          let headerMessage = "Information";
          let messageType = "Info"
          dialogConfig.data = { message: AppConstants.NOCHANGEMESSAGE, headerMessage: headerMessage, messageType: messageType };
          this.dialog.open(StatusDialogComponent, dialogConfig);
        }
      }
    }
    else {
      let dialogConfig = this.service.getDialogConfig();
      let headerMessage = "Error";
      let messageType = "Error"
      dialogConfig.data = { message: this.validationErrorTxt, headerMessage: headerMessage, messageType: messageType };
      this.dialog.open(StatusDialogComponent, dialogConfig);
    }
  }

  isGroupModified(group) {
    return group.name !== this.oldGroupName || group.description !== this.oldGroupDesc ? true : false;
  }

  checkValidRecord() {

    this.isError = false;
    if (this.groupName == null || this.groupName == "") {
      this.validationErrorTxt = "Please enter a valid group name."
      this.isError = true;
    }
    this.groups.forEach(e => {
      if (e.name != null && e.name == this.groupName && e.groupId !== this.group.groupId) {
        this.validationErrorTxt = "The group name already exists."
        this.isError = true;
      }
    });

    return this.isError;
  }

  onClose() {
    this.dialogRef.close();
  }

  popupHeader() {
    let result = this.componentNameNew;
    if (this.group != undefined) {
      result = this.group.groupId != 0 && this.group.groupId != null ? this.componentNameModify : this.componentNameNew;
    }
    return result
  }

  valueChange(event) {
    this.hadDataChanged = true;
  }
}