import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { FileMappingService } from 'src/app/shared/services/file-mapping-service/file-mapping.service';
import { FileMappingModel, MappingRuleModel, SubMappingRuleModel, GroupModel, ProjectModel } from 'src/app/shared/services/webclient-api'
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/shared/services/message-service';
import { Observable, of } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';
import { RoleGuardService } from 'src/app/shared/services/role-gaurd-service';
import { ProjectService } from 'src/app/shared/services/project-service/project.service'
import { GroupsService } from 'src/app/shared/services/group-service/groups.service'
import { OperationModel } from 'src/app/models/operation.model';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  templateUrl: './create-file-mapping-popup.component.html',
  styleUrls: ['./create-file-mapping-popup.component.scss']
})

export class CreateFileMappingPopupComponent {
  public context: any;
  private gridApi;
  public subscription: any;
  public savedFilter: any;
  public savedSort: any;

  rowData: FileMappingModel;
  componentTitle = AppConstants.FILEMAPPINGTITLE;
  componentNameNew = AppConstants.FILEMAPPINGNEW;
  componentNameModify = AppConstants.MODIFYFILEMAPPINGTITLE;
  isdataCopy: boolean = false;
  isCopyButtonVisible: boolean = false;
  isSaveDisabled: boolean = true;
  fileMapping: FileMappingModel;
  fileMappingOld: FileMappingModel;
  validationErrorTxt: string;
  isPageDisabled: boolean = false;
  isSaveBtnDisabled: boolean = false;
  mappingRule: any;
  subMappingRule: any;
  delimeters: any;
  friendlyHeaders: any;
  errorMsg: any;
  isEditFileMapping: boolean = false;
  projects: ProjectModel[] = [];
  groups: GroupModel[] = [];
  groupsOld: GroupModel[] = [];
  originalgroupId: number;
  originalProjectId: number;
  operations: OperationModel[] = [];
  preOperationSelected = "";
  operationOld: any;
  isRestrictedRole: boolean = false;
  isAdminRole:boolean = false;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  headerNameRegexPatternObject = new RegExp("^[a-zA-Z0-9\\-_ ]+$");
  headerNameRegexPattern = "[a-zA-Z0-9\\-_ ]*";
  hasDataChanged: boolean = false;
  pasteText: any;
  isFileMappingUsed: boolean = false;
  projectIdOld: number;
  groupIdOld: number;
  isProjectGroupDisabled:boolean=false;
  oldMappingRules: any;


  constructor(private toastr: ToastrService,
    private service: FileMappingService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private userRole: RoleGuardService,
    private commonService: CommonService,
    private groupService: GroupsService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateFileMappingPopupComponent>, private _messageService: MessageService) {
    this.context = { componentParent: this };

  }

  ngOnInit(): void {
    this.pasteText = AppConstants.PasteText;
    this.loadOperations();
    this.loadDelimeters();
    this.editFileMappingInfo();
    this.loadFriendlyHeaders();

    this.loadProjects();
    window.addEventListener('scroll', this.scrollEvent, true);
  }
  

  scrollEvent = (event: any): void => {
    this.autoComplete?.updatePosition();
  };

  editFileMappingInfo() {
    this.isCopyButtonVisible = false
    if (this.data.selectedData != undefined) {
      this.fileMapping = this.data.selectedData;
      this.fileMappingOld= this.data.selectedData;

      if (this.data.selectedData.name != null && this.data.selectedData.name != '') {
        this.isEditFileMapping = true;
        this.projectIdOld = this.fileMapping.projectId;
        this.groupIdOld = this.fileMapping.groupId;
        this.operationOld = this.fileMapping?.operation;
        this.oldMappingRules = JSON.stringify(this.fileMapping.mappingRules);;

        if (this.fileMapping.projectId != null && this.fileMapping.projectId != 0) {
          this.loadGroups(this.fileMapping.projectId);
          this.isRestrictedRole = this.commonService.applyRole(this.fileMapping.projectId, this.fileMapping.groupId)  
          this.isAdminRole = this.commonService.isAnalystOrObserverRole(this.fileMapping.projectId, this.fileMapping.groupId) 
          
        }
        if (this.data.fromRunSheet == true) {
          var expectedRoles: any = [AppConstants.CEREBRO_ADMIN, AppConstants.CEREBRO_ADMIN_DEV]          
          let role = this.userRole.doesUserHaveRole(expectedRoles)
          this.isProjectGroupDisabled=true;         
          if(!this.isAdminRole)
            {
            this.isPageDisabled = true;
            this.isRestrictedRole=true;            
            }

          
          if (!role ||  !this.isAdminRole) {
            this.isCopyButtonVisible = false;
            this.isSaveBtnDisabled = true;
            this.isPageDisabled = true;
          }
          else {
            this.isCopyButtonVisible = false;
            this.isSaveBtnDisabled = false;
            this.isPageDisabled = false;
            this.isRestrictedRole=false;  
          }
        }
        else {
          this.isCopyButtonVisible = true;
          this.isSaveBtnDisabled = false;
          this.isPageDisabled = false;
          this.isAdminRole =false;
        }

      }
      else {
        this.isPageDisabled = false;
        this.isSaveDisabled = false;
        this.isCopyButtonVisible = false;
      }
    }
  }

  getDimensions(name, fileLocation) {

    var obj = this.data.selectedGridData.filter(function (node) {
      return node.name.toLowerCase() == name.toLowerCase() || node.fileLocation.toLowerCase() == fileLocation.toLowerCase();
    });

    return obj;
  }

  onSubmit() {

    if (this.isValidated()) {

      if (this.fileMapping.fileMappingId == 0) {
        if (this.fileMapping.projectId == this.originalProjectId && this.fileMapping.groupId == this.originalgroupId && (this.getDimensions(this.fileMapping.name, this.fileMapping.fileLocation) != undefined && this.getDimensions(this.fileMapping.name, this.fileMapping.fileLocation) != null && this.getDimensions(this.fileMapping.name, this.fileMapping.fileLocation) != "")) {
          let dialogConfig = this.service.getValidationDialogConfig();
          let headerMessage = "Warning";
          dialogConfig.panelClass = "dialog-responsive";
          let message = "Previous versions of this file mapping/file location exists and will be marked inactive. Would you like to proceed?";
          dialogConfig.data = { message: message, headerMessage: headerMessage };
          let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(
            status => {
              if (status) {
                this.saveMappingInfo();
              }
            });
        }
        else {
          this.saveMappingInfo();
        }
      }
      else {        

        this.service.isFileMappingUsed(this.fileMapping.fileMappingId)
          .subscribe(
            isUsed => {           
              if (isUsed && this.data.fromRunSheet != true && !this.isAdminRole && (this.fileMapping.projectId != this.projectIdOld || this.fileMapping.groupId!=this.groupIdOld||this.fileMapping.operation!=this.operationOld)) {
                
                    this.errorMsg = "This File Mapping is already being used in an Experiment Process/Template and cannot be edited at this point."                       
                    this.loadProjects();     
                    this.loadGroups(this.projectIdOld);            
                    this.fileMapping.projectId = this.projectIdOld;                          
                    this.fileMapping.groupId= this.groupIdOld;
                    this.fileMapping.operation=this.operationOld;
                    this.fileMapping.mappingRules = JSON.parse(this.oldMappingRules, this.commonService.camelCaseReviver) 
                    this.showAlert();              
                    window.addEventListener('scroll', this.scrollEvent, true);
              }
              else {
                this.service.updateFileMapping(this.fileMapping).subscribe(result => {
                  this._messageService.filter('refresh');
                  this.toastr.success('Update file mapping successful!!');
                  this.onClose();
                },
                  error => {
                    this.toastr.error('Update file mapping failed !!');
                  });
              }
            },
            error => {
              this.toastr.error(error, 'Validation of file mapping failed !!');
            });
      }
    }
    else {
      this.showAlert();
    }
  }

 refreshGrid() {
    this.editFileMappingInfo();
    this.onCloseEvent();
  }

  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  showAlert() {
    let dialogConfig = this.service.getValidationDialogConfig();
    let headerMessage = "Info";
    let messageType = "Info"
    if(this.errorMsg.includes("unique friendly header")) {
      headerMessage = "Duplicate Friendly Header Detected";
    }
    dialogConfig.data = { message: this.errorMsg, headerMessage: headerMessage, messageType: messageType };
    this.dialog.open(StatusDialogComponent, dialogConfig);
  }

  saveMappingInfo() {
    this.service.saveFileMapping(this.fileMapping).subscribe(result => {
      this._messageService.filter('refresh');
      this.toastr.success('Save file mapping successful!!');
      this.onClose();
    },
      error => {
        this.toastr.error('Save file mapping failed !!');
      });
  }

  validateName() {
    let result = true;
    if (this.fileMapping.name != null && this.fileMapping.name != '') {
      this.data.selectedGridData.forEach(x => {

        if (x.projectId == this.originalProjectId && x.groupId == this.originalgroupId && x.name.trim() == this.fileMapping.name.trim() && x.fileMappingId === this.fileMapping.fileMappingId) {
          this.errorMsg = " A file mapping with the same name already exists.\n"
          result = false;
        }
      })
    }
    return result;
  }

  isValidated() {
    let typeNameErro = '';
    let projectErro = '';
    let groupErro = '';
    let frdhdrErro = '';
    let delimeterErro = '';
    let rowerror = '';
    let colErr = '';
    let result = true;
    let isKeyFound = false;
    let isKeyErr = '';
    let duplicateFileMapErr = '';
    if (this.fileMapping.name == null || this.fileMapping.name == '') {
      typeNameErro = " Please enter a valid file mapping name.\n";
      result = false;

    }
    if (this.fileMapping.fileLocation == null || this.fileMapping.fileLocation == '') {
      typeNameErro = " Please enter a valid file location.\n";
      result = false;

    }
    if (this.fileMapping.projectId == null || this.fileMapping.projectId == 0) {
      projectErro = " Please select a valid project.\n";
      result = false;

    }
    if (this.fileMapping.groupId == null || this.fileMapping.groupId == 0) {
      groupErro = " Please select a valid group.\n";
      result = false;

    }

    let count = 0;
    let headerNames = [];
    this.fileMapping.mappingRules.forEach(element => {
      if (element.row == 0) {
        result = false;
        rowerror = " Please enter a row number.\n";

      }
      if (element.column == '' || element.column == null || element.column == undefined) {
        colErr = " Please enter a column name.\n";
        result = false;
      }

      if (element.headerName == undefined || element.headerName == null || element.headerName === '') {
        frdhdrErro = " Please enter a friendly header name.\n";
        result = false;
      }

      if (element.headerName != undefined || element.headerName != null || element.headerName != '') {
        if (!this.headerNameRegexPatternObject.test(element.headerName?.trim())) {
          frdhdrErro = " Please enter a valid friendly header name (Only Space, Hyphen and Underscore allowed.).\n";
          result = false;
        }
        else {
          if(headerNames.includes(element.headerName.trim().toLocaleLowerCase())) {
            frdhdrErro = " The friendly header " + element.headerName.trim() + "  has already been used in this File Mapping. Please select a unique friendly header before saving your changes.\n";
            result = false;
          }
          else {
            headerNames.push(element.headerName.trim().toLocaleLowerCase())
          }
        }
      }
      if (element.isKey == true) {
        isKeyFound = true;
      }
      if (element.subMappingRules?.length > 0) {
        if (element.subMappingRules[0].firstHeaderName == undefined || element.subMappingRules[0].firstHeaderName == null || element.subMappingRules[0].firstHeaderName === '') {
          frdhdrErro = " Please enter a friendly header name.\n";
          result = false;
        }

        if (element.subMappingRules[0].firstHeaderName != undefined || element.subMappingRules[0].firstHeaderName != null || element.subMappingRules[0].firstHeaderName != '') {
          if (!this.headerNameRegexPatternObject.test(element.subMappingRules[0].firstHeaderName?.trim())) {
            frdhdrErro = " Please enter a valid friendly header name (Only Space, Hyphen and Underscore allowed.).\n";
            result = false;
          }
          else {
            if(headerNames.includes(element.subMappingRules[0].firstHeaderName.trim().toLocaleLowerCase())) {
              frdhdrErro = " The friendly header " + element.subMappingRules[0].firstHeaderName.trim() + "  has already been used in this File Mapping. Please select a unique friendly header before saving your changes.\n";
              result = false;
            }
            else {
              headerNames.push(element.subMappingRules[0].firstHeaderName.trim().toLocaleLowerCase())
            }
          }
        }
        if (element.subMappingRules[0].isFirstHeaderKey == true) {
          isKeyFound = true;
        }
      }
      element.subMappingRules?.forEach(subrl => {

        if (subrl.headerName == undefined || subrl.headerName == null || subrl.headerName === '') {
          frdhdrErro = " Please enter a friendly header name.\n";
          result = false;
        }

        if (subrl.headerName != undefined || subrl.headerName != null || subrl.headerName != '') {
          if (!this.headerNameRegexPatternObject.test(subrl.headerName?.trim())) {
            frdhdrErro = " Please enter a valid friendly header name (Only Space, Hyphen and Underscore allowed.).\n";
            result = false;
          }
          else {
            if(headerNames.includes(subrl.headerName.trim().toLocaleLowerCase())) {
              frdhdrErro = " The friendly header " + subrl.headerName.trim() + "  has already been used in this File Mapping. Please select a unique friendly header before saving your changes.\n";
              result = false;
            }
            else {
              headerNames.push(subrl.headerName.trim().toLocaleLowerCase())
            }
          }
        }
        if (subrl.delimeterId == 0) {
          delimeterErro = " Please select a delimeter.\n";
          result = false;
        }
        if (subrl.isKey == true) {
          isKeyFound = true;
        }
        count = count;
      })

    });
    if (isKeyFound == false) {
      result = false;
      isKeyErr = "Please select Key Identifier (At least one Key Identifier is required).";
    }

    this.errorMsg = duplicateFileMapErr + typeNameErro + projectErro + groupErro + rowerror + colErr + frdhdrErro + delimeterErro + isKeyErr;

    return result;
  }
  onCopy() {
    this.isEditFileMapping = false;
    this.isCopyButtonVisible = false;
    this.isdataCopy = true;
    this.fileMapping.fileMappingId = 0;
    this.isPageDisabled = false;
    this.fileMapping.name = "";
    this.fileMapping.description = "";
    this.originalProjectId = this.fileMapping.projectId;
    this.originalgroupId = this.fileMapping.groupId;
    this.popupHeader();

  }
  addRow() {

    let mappingRule = this.createMappingRule('');
    this.fileMapping.mappingRules.push(mappingRule);

  }

  createSubMappingRule() {
    let subMappingRule = new SubMappingRuleModel({
      subMappingId: 0,
      mappingRuleId: 0,
      delimeterId: 0,
      firstSubMappingId: 0,
      firstHeaderId: 0,
      firstHeaderName: null,
      isFirstHeaderKey: false,
      headerId: 0,
      headerName: null,
      isKey: false,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      userFriendlyHeaders: this.friendlyHeaders,
      firstUserFriendlyHeaders: this.friendlyHeaders,
      delimeters: this.delimeters
    });

    return subMappingRule;
  }

  createMappingRule(columnName) {
    let mappingRule = new MappingRuleModel({
      mappingRuleId: 0,
      fileMappingId: 0,
      row: 1,
      column: columnName,
      headerId: 0,
      headerName: null,
      isKey: false,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      userFriendlyHeaders: this.friendlyHeaders,
      subMappingRules: []
    });

    return mappingRule;
  }

  addSubRow(index) {
    let subMappingRule = new SubMappingRuleModel({
      subMappingId: 0,
      mappingRuleId: 0,
      delimeterId: 0,
      firstSubMappingId: 0,
      firstHeaderId: 0,
      firstHeaderName: null,
      isFirstHeaderKey: false,
      headerId: 0,
      headerName: null,
      isKey: false,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      userFriendlyHeaders: this.friendlyHeaders,
      firstUserFriendlyHeaders: this.friendlyHeaders,
      delimeters: this.delimeters
    });
    if (this.fileMapping.mappingRules[index].subMappingRules !== undefined)
      this.fileMapping.mappingRules[index].subMappingRules.push(subMappingRule);

    if (this.fileMapping.mappingRules[index].isKey == true && this.fileMapping.operation == 'join')
      this.fileMapping.mappingRules[index].isKey = false;
  }
  deletechildRow(row) {
    this.fileMapping.mappingRules[row].subMappingRules = [];
    this.hasDataChanged = true;
  }

  recordModify() {
    this.hasDataChanged = true;
  }

  deleteRow(index) {
    this.hasDataChanged = true;
    this.fileMapping.mappingRules.splice(index, 1);
  }

  onFilterSubFrindlyHeaders(event: string, row, col) {

    this.fileMapping.mappingRules[row].subMappingRules[col].userFriendlyHeaders = this.friendlyHeaders.filter(item => item.name.toLowerCase().includes(event.toLowerCase()));
  }

  onFilterSubFirstFrindlyHeaders(event: string, row, col) {

    this.fileMapping.mappingRules[row].subMappingRules[col].firstUserFriendlyHeaders = this.friendlyHeaders.filter(item => item.name.toLowerCase().includes(event.toLowerCase()));
  }

  onFilterFrindlyHeaders(event: string, row) {

    this.fileMapping.mappingRules[row].userFriendlyHeaders = this.friendlyHeaders.filter(item => item.name.toLowerCase().includes(event.toLowerCase()));
  }


  loadFriendlyHeaders() {
    this.service.getFrindlyHeaders().subscribe(
      result => {
        this.friendlyHeaders = result.filter(x => x.isActive);
      },
      error => {
        console.log(error);
      }
    );
  }
  loadDelimeters() {
    this.service.getDelimeters()
      .subscribe(
        result => {
          this.delimeters = result;
        },
        error => {
          console.log('error');
          this.toastr.error(error, 'Delimiters load failed !!');
        });
  }
  popupHeader() {
    let result = this.componentNameNew;
    if (this.data != undefined && this.fileMapping != undefined) {
      result = this.fileMapping.fileMappingId != 0 ? this.componentNameModify : this.componentNameNew;
      if (this.isdataCopy == true) {
        result = this.componentNameNew
      }
    }
    return result
  }

  onChangeFileType(event) {

  }
  displayInformation() {
    let dialogConfig = this.service.getDialogConfig();
    let mesage = "File Mapping How To:\nFile Name: Enter the name you wish to call the file mapping. This will be displayed in dropdowns when attaching to a Template.\nFile Location: Location of the data file(s) that will be attached to this File Mapping.\nProject: Project this File Mapping will be associated to.\nGroup: Group this File Mapping will be associated to. \nDescription: Optional. The purpose of the File Mapping\nRow: Enter the row number where the data will start. This will be the same number for all columns you enter.\nColumn:  Enter the column name for the data you want to save into Cerebro. This name must match the column names in the data file(s) that will be associated to the File Mapping.\nFriendly Header: Enter the name of the header for the data that will be displayed.\nIs Key: One of these must be checked.  Is Key will identify the header that can be used to 'connect' this file to other files.";
    let headerMessage = "Information";
    let messageType = "Info"
    dialogConfig.data = { message: mesage, headerMessage: headerMessage, messageType: messageType };
    this.dialog.open(StatusDialogComponent, dialogConfig);
  }

  onClose() {
    this.dialogRef.close();
  }
  convert<T>(array: Array<T>): Observable<Array<T>> {
    return of(array);
  }

  valueChange(event) {
    this.hasDataChanged = true;
  }

  onPasteClick(event) {
    this.pasteText = "";
  }

  onClickOut(event) {
    this.pasteText = AppConstants.PasteText;
  }

  pasteData(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedData = clipboardData.getData('text');
    let row_data = pastedData.split('\n');
    row_data[0] = row_data[0].replace(/(\r\n|\n|\r)/gm, "");
    let displayedColumns = row_data[0].split('\t');
    this.createColumns(displayedColumns);
    this.pasteText = AppConstants.PasteText;
  }

  createColumns(displayedColumns) {
    if (this.fileMapping.mappingRules[0].column == null || (this.fileMapping.mappingRules[0].column != null && this.fileMapping.mappingRules[0].column.trim() == '')) {
      this.fileMapping.mappingRules = [];
    }

    displayedColumns.forEach(columnName => {
      let mappingRule = this.createMappingRule(columnName);
      mappingRule.headerName = this.friendlyHeaders.some(item => item.name.toLowerCase() == columnName.toLowerCase()) ? this.friendlyHeaders.find(item => item.name.toLowerCase() == columnName.toLowerCase()).name : columnName;
      if (mappingRule.subMappingRules !== undefined && mappingRule.subMappingRules.length > 0)
        mappingRule.subMappingRules?.push(this.createSubMappingRule());
      this.fileMapping.mappingRules.push(mappingRule);
    });

  }
  projectSelectionChange(projectId) {
    this.hasDataChanged = true;
    this.loadGroups(projectId);
  }

  operationSelectionChange(operation) {
    this.hasDataChanged = true;
    if (operation == "") {
      let isSubMappingRulesExists = false;
      this.fileMapping.mappingRules.forEach(element => {
        if (element.subMappingRules?.length > 0) {
          isSubMappingRulesExists = true;
        }
      });
      if (isSubMappingRulesExists) {
        let dialogConfig = this.service.getValidationDialogConfig();
        let headerMessage = "Warning";
        dialogConfig.panelClass = "dialog-responsive";
        let message = "You are about to switch the operation from join/split to none. This change will remove any sub-headers and delimiter associations that make up the Friendly Headers. Do you want to proceed?";
        dialogConfig.data = { message: message, headerMessage: headerMessage };
        let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          status => {
            if (status) {

              this.fileMapping.mappingRules.forEach(element => {
                if (element.subMappingRules?.length > 0) {
                  element.subMappingRules = [];
                }
              });
            }
            else {
              this.fileMapping.operation = this.preOperationSelected;
            }
          });
      }
    }
    else {
      if (operation === 'join') {
        this.fileMapping.mappingRules.forEach(element => {
          element.isKey = element.subMappingRules.length > 0 ? false : element.isKey;
        });
      }
      this.preOperationSelected = operation;
    }

  }

  loadGroups(projectId) {
    this.groupService.getGroupsByProjectId(projectId, false)
      .subscribe(
        result => {
          this.groups = result;
          this.groupsOld=result;
        },
        error => {
          console.log('error');
          this.toastr.error(error, 'Group load failed !!');
        });

  }
  loadProjects() {
    if (this.fileMapping.projectId != null && this.fileMapping.projectId != 0) {
      this.projectService.GetProjectsForAnalystAndObserverUser()
        .subscribe(
          result => {
            this.projects = result;
          },
          error => {
            console.log('error');
            this.toastr.error(error, 'Project load failed !!');
          });
    }
    else {
      this.projectService.getProjectsForAdminUser()
        .subscribe(
          result => {
            this.projects = result;
          },
          error => {
            console.log('error');
            this.toastr.error(error, 'Project load failed !!');
          });
    }

  }

  loadOperations() {
    let emptyOperation = new OperationModel("", "");
    let joinOperation = new OperationModel("join", "Join");
    let splitOperation = new OperationModel("split", "Split");
    this.operations.push(emptyOperation);
    this.operations.push(joinOperation);
    this.operations.push(splitOperation);
  }

  getToolTipDelimiters(delimeterId) {

    let DelimeterName = "";
    if (this.delimeters != undefined) {
      this.delimeters.forEach(element => {
        if (element.DelimeterId == delimeterId) {
          DelimeterName = element.DelimeterName
        }
      });
    }
    return DelimeterName;
  }

  getToolTipFriendlyHeaders(headerId) {

    let headerName = "";
    if (this.friendlyHeaders != undefined) {
      this.friendlyHeaders.forEach(element => {
        if (element.headerId == headerId) {
          headerName = element.name
        }
      });
    }
    return headerName;
  }
}
