
import { ProjectService } from 'src/app/shared/services/project-service/project.service';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/shared/services/message-service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';
import { GroupsService } from 'src/app/shared/services/group-service/groups.service'
import { GroupModel, ProjectGroupModel, ProjectModel } from 'src/app/shared/services/webclient-api';


@Component({
  selector: 'project-popup',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})


export class CreateProjectComponent {
  isFormInvalid: boolean = false;
  clickEventTrigger: boolean = false;
  componentTitle = AppConstants.PROJECTTITLE;
  componentNameNew = AppConstants.PROJECTNEW;
  componentNameModify = AppConstants.MODIFYPROJECTTITLE;
  isdataCopy: boolean = false;
  project: ProjectModel;
  projects: any[] = [];
  validationErrorTxt: any;
  isError: boolean = false;
  oldProjectName: string;
  oldProjectDesc: string;
  projectName: string;
  projectDesc: string;
  hadDataChanged: boolean = false;
  public groups: GroupModel[] = [];
  public selectedGroups: number[] = [];
  public oldSelectedGroups: number[] = [];
  isProjectGroupUsed: boolean = false;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  isEditDisabled: boolean = false;
  constructor(private toastr: ToastrService,
    private service: ProjectService,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    public groupService: GroupsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateProjectComponent>, private _messageService: MessageService) {
  }
  ngOnInit(): void {
    this.editProject();
    this.getGroups();
    window.addEventListener('scroll', this.scrollEvent, true);
  }
  editProject() {
    if (this.data.selectedData != undefined) {
      this.project = this.data.selectedData;

      this.data.selectedData.groups?.forEach(a => {
        this.selectedGroups.push(a?.groupId)

      });
      this.oldSelectedGroups = this.selectedGroups;
      this.oldProjectName = this.projectName = this.project.name;
      this.oldProjectDesc = this.projectDesc = this.project.description;



      if (this.projectName != undefined && this.projectName != "") {
        this.isEditDisabled = true;
      }
    }
    if (this.data.selectedGridData != undefined) {
      this.projects = this.data.selectedGridData;
    }

  }

  scrollEvent = (event: any): void => {
    this.autoComplete?.updatePosition();
  };

  
  removedGroups: number[] = [];
  onSubmit() {
    this.removedGroups = [];
    if (!this.checkValidRecord()) {
      this.project.name = this.projectName;
      this.project.description = this.projectDesc
      this.project.projectGroupsModel = [];
  

      this.selectedGroups?.forEach(element => {
        var grp = new ProjectGroupModel({
          projectGroupId: 0,
          projectId: 0,
          groupId: Number(element),
          isActive: true,
          createdDate: new Date(),
          createdBy: null,
          modifiedDate: new Date(),
          modifiedBy: null
        });
        this.project.projectGroupsModel.push(grp)
      })

      if (this.project?.projectId == 0) {

        this.service.addProject(this.project).subscribe(result => {
          this._messageService.filter('refresh');
          this.toastr.success('Save project successful!!');
          this.onClose();
        },
          error => {
            this.toastr.error('Save project failed !!');
          });
      }
      else {
        if (this.isProjectModified(this.project) || (this.isGroupChanged() && this.validateProjectGroup())) {

          this.oldSelectedGroups?.forEach(x => {
            let grpOldDt = this.selectedGroups.find(y => y == x);
            if (grpOldDt == undefined || grpOldDt == null) {             
              this.removedGroups.push(x)
            }
          })

          if (this.removedGroups?.length <= 0) {
            this.updateProject(this.project);
          }
          else {
            this.service.checkProjectGroupUsedInAdmin(this.project?.projectId, this.removedGroups).subscribe(result => {
              if (result != undefined && result == true) {
                this.projectGroupUsedInAdminPopup();
                this.selectedGroups = this.oldSelectedGroups;
                this.hadDataChanged = false;
              }
              else {
                this.updateProject(this.project);
              }
              error => {
                this.toastr.error('Get project group assigned status failed !!');
              }
            });
          }

        }
        else {
          let dialogConfig = this.service.getDialogConfig();
          let headerMessage = "Information";
          let messageType = "Info"
          dialogConfig.data = { message: AppConstants.NOCHANGEMESSAGE, headerMessage: headerMessage, messageType: messageType };
          this.dialog.open(StatusDialogComponent, dialogConfig);
        }
      }
    }
    else {
      let dialogConfig = this.service.getDialogConfig();
      let headerMessage = "Error";
      let messageType = "Error"
      dialogConfig.data = { message: this.validationErrorTxt, headerMessage: headerMessage, messageType: messageType };
      this.dialog.open(StatusDialogComponent, dialogConfig);

    }
  }

  updateProject(project) {
    this.service.updateProject(project).subscribe(result => {
      this._messageService.filter('refresh');
      this.toastr.success('Update project successful!!');
      this.onClose();

    },
      error => {
        this.toastr.error('Update project failed !!');
      });
  }

  projectGroupUsedInAdminPopup() {
    let dialogConfig = this.service.getDialogConfig();
    let headerMessage = "Info";
    let messageType = "Info"
    let _message = "This Project/Group combination is already being used elsewhere in an Admin record and cannot be edited.";
    dialogConfig.data = { message: _message, headerMessage: headerMessage, messageType: messageType };
    this.dialog.open(StatusDialogComponent, dialogConfig);

  }

  isGroupChanged() {
    let status = false;
    if (this.selectedGroups.length == this.oldSelectedGroups.length) {
      this.oldSelectedGroups?.forEach(a => {
        if (this.selectedGroups?.filter(x => x == a)?.length == 0) {
          status = true;
        }
      })
    }
    else {
      status = true;
    }
    return status;
  }

  isProjectModified(project) {
    return project.name !== this.oldProjectName || project.description !== this.oldProjectDesc ? true : false;
  }

  validateProjectGroup() {
    let status = true;
    if (this.selectedGroups == undefined || this.selectedGroups?.length == 0) {
      status = false;
      var errorMsg = "Please select valid group(s)."
      this.isError = true;
      let dialogConfig = this.service.getDialogConfig();
      let headerMessage = "Error";
      let messageType = "Info"
      dialogConfig.data = { message: errorMsg, headerMessage: headerMessage, messageType: messageType };
      this.dialog.open(StatusDialogComponent, dialogConfig);
    }
    return status;
  }

  checkValidRecord() {

    this.isError = false;
    if (this.projectName == null || this.projectName == "") {
      this.validationErrorTxt = "Please eneter valid project name."
      this.isError = true;
    }
    if (this.selectedGroups == undefined || this.selectedGroups?.length == 0) {
      this.validationErrorTxt = "Please select valid group(s)."
      this.isError = true;
    }
    this.projects.forEach(element => {
      if (element.name != null && element.name == this.projectName && element.projectId !== this.project.projectId) {
        this.validationErrorTxt = "The project name already exists."
        this.isError = true;
      }
    });

    return this.isError;
  }

  getGroups() {
    this.groupService.getGroups().subscribe(result => {

      this.groups = result.filter(x => x.isActive);
    },
      error => {
        this.toastr.error('Save project failed !!');
      });
  }


  onClose() {
    this.dialogRef.close();
  }

  popupHeader() {
    let result = this.componentNameNew;
    if (this.project != undefined) {
      result = this.project.projectId != 0 && this.project.projectId != null ? this.componentNameModify : this.componentNameNew;
    }
    return result
  }

  valueChange(event) {
    this.hadDataChanged = true;
  }
}