import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';
import { AppConstants } from 'src/app/common-utility/appconstants';
@Component({
    selector: 'slide-cell',
    template: `<mat-slide-toggle [ngModel]="params.data[this.params.colDef.field]" [disabled]="isControlDisabled" (change)="onChange($event)"></mat-slide-toggle>`,
    styleUrls: ['./slide-cell-renderer.component.css']
})
export class SlideCellRendererComponent implements ICellRendererAngularComp {
    public params: ICellRendererParams;
    isControlDisabled: boolean = false;
    constructor(private dialog: MatDialog) { }
    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.isControlDisabled=this.params?.data?.isControlDisabled;
    }

    public onChange(event) {
        let dialogConfig = this.getDialogConfig();        
        dialogConfig.data = { isActive: event.checked, componentName: this.params.data.name==undefined?this.params.data.fileName: this.params.data.name};
        let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
        status => {
            if (status) {
                this.params.context.componentParent.updateStatus(this.params.data, event.checked);
            }     
            else {        
                this.params.context.componentParent.cancelStatusUpdate();        
                //event.checked = !event.checked;
            }
        },
        error =>{
            console.log(error);
        },
        () =>{
            this.params.context.componentParent.refreshGrid(AppConstants.UPDATESTATUS);
        }
        );
    }
    refresh(params: ICellRendererParams): boolean {
        return true;
    }


    getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "30%";
        return dialogConfig;

      }
}