import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pause-status-dialog',
  templateUrl: './pause-status-dialog.component.html',
  styleUrls: ['./pause-status-dialog.component.scss']
})
export class PauseStatusDialogComponent implements OnInit {

  message: any;
  isControlDisabled: boolean = false;
  constructor(public dialogRef: MatDialogRef<PauseStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    this.message = this.data.isActive?"Are you sure you want to pause the experiment" + " " + this.data.componentName+ " " +
    "?" : "Are you sure you want to resume the experiment" + " " + this.data.componentName+ " " + "?"

    // this.data.isActive?"Are you sure, would you like to enable the" + " " + this.data.componentName + "?":"Are you sure, would you like to disable the" + " " + this.data.componentName + "?";
  }
}
