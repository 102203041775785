import { Component, OnDestroy, OnInit } from '@angular/core';
import { SlideCellRendererComponent } from 'src/app/custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from 'src/app/custom/edit-button-renderer/edit-button-renderer.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { FileMappingService } from 'src/app/shared/services/file-mapping-service/file-mapping.service';
import { CreateFileMappingPopupComponent } from './file-mapping-popup/create-file-mapping-popup.component'
import { FileMappingModel, MappingRuleModel, SubMappingRuleModel } from 'src/app/shared/services/webclient-api'
import { MessageService } from 'src/app/shared/services/message-service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  templateUrl: '../../shared/grid-component/grid.component.html',
  styleUrls: ['./file-mapping.component.scss']
})

export class FileMappingComponent implements OnInit,OnDestroy {
  private gridApi;
  public subscription: any;
  private gridColumnApi;
  public savedFilter: any;
  public savedSort: any;
  public context: any;
  rowData: any[] = [];
  fileMapping: FileMappingModel;
  mappingRule: any;
  subMappingRule: any;
  delimeters: any;
  friendlyHeaders: any;
  componentTitle = AppConstants.FILEMAPPINGTITLE;
  componentName = AppConstants.FILEMAPPINGTITLE;
  
  constructor(private toastr: ToastrService,
    private _messageService: MessageService,  
    private commonService: CommonService,
    private service: FileMappingService, private dialog: MatDialog, public datepipe: DatePipe) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    })

  }

  initializeTemplates() {

    this.subMappingRule = new SubMappingRuleModel({
      subMappingId: 0,
      mappingRuleId: 0,
      delimeterId: 0,
      headerId: 0,
      headerName: null,
      isKey: false,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      userFriendlyHeaders: this.friendlyHeaders,
      delimeters: this.delimeters
    });
    this.mappingRule = new MappingRuleModel({
      mappingRuleId: 0,
      fileMappingId: 0,
      row: 1,
      column: null,
      headerId: 0,
      headerName: null,
      isKey: false,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      userFriendlyHeaders: this.friendlyHeaders,
      subMappingRules: []
    })
    //this.mappingRule.subMappingRules.push(this.subMappingRule);
    this.fileMapping = new FileMappingModel({
      fileMappingId: 0,
      name: '',
      description: null,
      fileLocation: null,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      mappingRules: []
    });
    this.fileMapping.mappingRules.push(this.mappingRule)
  }

  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  ngOnInit(): void {
    this.service.initializeFileMap();this.getDelimeters();
    this.getFriendlyHeaders();
    this.getFileMappingInfo();

  }
  update(row) {
    if (row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      if (row.data !== null) {

        this.getFileMappingById(row.data.fileMappingId);
      }
    }
  }

  getFileMappingById(fileMappingId: number) {
    if (fileMappingId != 0) {
      this.service.getFileMappingById(fileMappingId)
        .subscribe(
          result => {
            this.fileMapping = result;
            this.fileMapping.mappingRules.forEach(element => {
              element.userFriendlyHeaders = this.friendlyHeaders;
              element.subMappingRules.forEach(element => {
                element.delimeters = this.delimeters;
                element.firstUserFriendlyHeaders = this.friendlyHeaders;
                element.userFriendlyHeaders = this.friendlyHeaders;
              });
            });
            let dialogConfig = this.service.getDialogConfig();
            dialogConfig.panelClass = "dialog-responsive";
            dialogConfig.data = { selectedData: result, selectedGridData: this.rowData, fromRunSheet: false };
            this.dialog.open(CreateFileMappingPopupComponent, dialogConfig);
          },
          error => {
            console.log(error);
          })
    }
  }

  columnDefs = [
    { headerName: 'Name', field: 'name', sortable: true, filter: true, width: 135, tooltipValueGetter: (params) => params.value },
    { headerName: 'Project | Group', field: 'projectGroup', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value },
    { headerName: 'File Location', field: 'fileLocation', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value },
    { headerName: 'Description', field: 'description', sortable: true, filter: true, width: 100, tooltipValueGetter: (params) => params.value },
    { headerName: 'Version', field: 'version', sortable: true, filter: true, width: 70, tooltipValueGetter: (params) => params.value },
    { headerName: 'Modified By', field: 'modifiedBy', sortable: true, filter: true, width: 105, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Modified Date', field: 'modifiedDate', sortable: true, filter: true, width: 110, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) : '';
      }
    },
    { headerName: 'Active', field: 'isActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 75 },
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      width: 75,
      tooltipValueGetter: (params) => 'Edit'
    },
  ];


  add() {
    this.initializeTemplates();
    let dialogConfig = this.service.getDialogConfig()
    dialogConfig.data = { selectedData: this.fileMapping, selectedGridData: this.rowData, fromRunSheet: false };
    this.dialog.open(CreateFileMappingPopupComponent, dialogConfig);

  }

  refreshGrid() {
    this.getFileMappingInfo();
    this.gridApi.refreshClientSideRowModel('aggregate')
    // this.clearSortandFilter();
    this.onCloseEvent();
  }

  refreshGridFilters() {
    //this. getApiTypes();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.clearSortandFilter();
    this.onCloseEvent();
  }
  
  getFileMappingInfo() {
    this.service.getFileMappings()
      .subscribe(
        result => {
        // this.rowData = result;

        this.rowData = [];
        result.forEach(r => {      
          this.rowData.push({
            fileMappingId: r.fileMappingId,
            name: r.name,
            projectGroup:r.project?.name+" | "+r.group?.name,
            description: r.description,
            fileLocation: r.fileLocation,
            version:r.version,
            groupId:r.groupId,
            projectId:r.projectId,
            isActive: r.isActive,
            createdDate: r.createdDate,
            createdBy: r.createdBy,
            modifiedDate: r.modifiedDate,
            modifiedBy: r.modifiedBy,
            mappingRules:r.mappingRules,
            group:r.group,
            project:r.project
          });
        })

        
        this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDFILEMAPPINGFILTER);
        this.savedSort = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDFILEMAPPINGSORT);
        },
        error => {
          console.log(error);
        }
      )

  }
  getDelimeters() {
    this.service.getDelimeters().subscribe(
      result => {
        this.delimeters = result;

      },
      error => {
        console.log(error);
      }
    );
  }

  getFriendlyHeaders() {
    this.service.getFrindlyHeaders().subscribe(
      result => {
        this.friendlyHeaders = result;
      },
      error => {
        console.log(error);
      }
    );
  }
  updateStatus(fileMapModel: FileMappingModel, isActive: boolean) {
    this.service.updateFileMappingStatus(fileMapModel, isActive)
      .subscribe(result => {
        this._messageService.filter('refresh');
        this.toastr.success('File mapping updated successfully !!');

      },
        error => {
          this.toastr.error(error, 'File mapping update failed !!');
        })
  }
  
  onColumnMoved(event) {
    this.gridApi.sizeColumnsToFit();
  }

  clearSortandFilter() {
    this.gridColumnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
    this.gridApi.setFilterModel(null);    
    this.gridApi.sizeColumnsToFit();
  }
  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(GridSizeChangedEvent) {
    GridSizeChangedEvent.api.sizeColumnsToFit();
  }
  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  onSortChanged(params) {
    this.savedSort = this.gridColumnApi.getColumnState();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDFILEMAPPINGSORT, JSON.stringify(this.savedSort));
  }

  onFilterChanged(params) {
    this.savedFilter = this.gridApi.getFilterModel();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDFILEMAPPINGFILTER, JSON.stringify(this.savedFilter));
  }

  onFirstDataRender(params) {

    if (this.savedSort != undefined && this.savedSort != null && this.savedSort.length != 0) {
      this.gridColumnApi.applyColumnState({
        state: this.savedSort,
        applyOrder: true,
      });
    }


    this.gridApi.setFilterModel(this.savedFilter);
  }

  ngOnDestroy() {
    try { 
        if (this.gridApi) {
            this.gridApi.destroy();
            this.gridApi = false;
        }

    } catch (error) {
      console.log(error);

    }
}

}