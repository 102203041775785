import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from 'src/app/shared/services/loading.service'
import {WebClientAPI ,GroupModel} from '../webclient-api';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';

@Injectable({
    providedIn: 'root'
  })
export class GroupsService {
  private messageSource;
  // currentMessage = this.messageSource.asObservable();
     
  httpOptions = {
    headers: new HttpHeaders({ 
        'Content-Type': 'application/json' 
    })
};

constructor(private webClientAPI: WebClientAPI, private http: HttpClient,private loadingService: LoadingService) { }


    public addGroup(model: GroupModel):  Observable<any> {

      this.loadingService.setMessage('Save Group...');
      this.messageSource = new BehaviorSubject(model);
      return this.webClientAPI.group_SaveGroup(model)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
     
    }

    public getGroups(): Observable<any> {
      this.loadingService.setMessage('Loading Groups...');
      return this.webClientAPI.group_GetGroups()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public getGroupById(Id: number): Observable<any> {
      this.loadingService.setMessage('Loading...');
      return this.webClientAPI.group_GetGroupById(Id)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }
    public getGroupsByProjectId(projectId: number, isSecurityGroup:boolean): Observable<any> {
      this.loadingService.setMessage('Loading...');
      return this.webClientAPI.group_GetGroupsByProjectId(projectId,isSecurityGroup)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public updateGroup(model: GroupModel):  Observable<any> {
      this.loadingService.setMessage('Updating Group...');
      return this.webClientAPI.group_UpdateGroup(model)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public updateGroupStatus(id, isActive): Observable<any> {
      this.loadingService.setMessage('Updating Group Status...');
      return this.webClientAPI.group_UpdateGroupStatus(id,isActive)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public cancelStatusUpdate() {
      this.messageSource = new BehaviorSubject('cancel');
    }
 

      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }

      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "23%";
        return dialogConfig;

      }
}