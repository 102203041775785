import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppConstants } from '../common-utility/appconstants';
import { CommonService } from '../shared/services/common/common.service';
import { ExperimentTypeService } from '../shared/services/experiment-type.service';
import { ExperimentService } from '../shared/services/experiment.service';
import { MessageService } from '../shared/services/message-service';
import { ProcessTypes, TemplateService } from '../shared/services/template-service/template.service';
import { AnalystModel, ExperimentPlanningModel, NotifyEmailModel, SecurityGroupModel, SecurityGroupUserModel, TemplateModel, ValidationModel } from '../shared/services/webclient-api';
import { SortByPipe } from '../shared/sort/sort-pipe';
import { StatusDialogComponent } from '../shared/status-dialog/status-dialog.component';
import { PauseStatusDialogComponent } from './pause-status-dialog/pause-status-dialog.component';
import { DataService } from '../shared/services/data.service';
import { UserClaim } from 'src/app/models/userClaim';
import { ConfigurationSettings } from '../configuration-settings';
import { SecurityGroupService } from '../shared/services/security-group.service';



@Component({
  selector: 'app-experiment',
  templateUrl: './experiment.component.html',
  styleUrls: ['./experiment.component.scss']
})
export class ExperimentComponent implements OnInit {
  experimentTypeList: any;
  analysts: any;
  templateList: any = [];
  experimentPlanningId: any;
  dialogRef: MatDialogRef<any, any>
  isPaused: boolean = false;
  isDisabled: boolean = false;
  oldResults: any;
  showSpinners = true;
  defaultTime = [8, 0, 0]
  showSeconds = true;
  touchUi = false;
  enableMeridian = false;
  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  color: ThemePalette = 'primary';
  newExperimentId = "";
  latestExperimentId = "";
  isOneRingUser: boolean = false;
  invalidModel:boolean=false
  @ViewChild('updateExperimentStatusDialog') updateExperimentStatusDialog: TemplateRef<any>;
  minDate: Date;
  maxDate: Date;
  hasChange: boolean = false;
  filteredExperimentTypes: Observable<Array<any>>;
  experimentTypeFilterControl = new FormControl();
  userEmailFilterControl = new FormControl();
  filteredTemplates: Observable<Array<any>>;
  templateFilterControl = new FormControl();

  filteredAnalysts: Observable<Array<any>>;
  analystFilterControl = new FormControl();
  isRestrictedRole: boolean = false;
  filteredUserList: Observable<any>;
  usersEmailList: any;
  selectedEmailUsers: NotifyEmailModel[];


  constructor(public dialog: MatDialog,
    public experimentService: ExperimentService,
    public securityGroupService: SecurityGroupService,
    private toastr: ToastrService,
    private _messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private sortPipe: SortByPipe,
    private experimentTypeService: ExperimentTypeService,
    private templateService: TemplateService,
    private dataService: DataService,
    private commonService: CommonService, @Inject(LOCALE_ID) private locale: string) {
    experimentService.initializeFormGroup();
    this.oneRingHasUser();
  }

  ngOnInit(): void {
    
    this.setDateRange();
    this.populateModelData();
    this.oneRingHasUser();
    this.route.paramMap.subscribe(params => {
      this.experimentPlanningId = Number(params.get("id"));
    });

    if (this.experimentPlanningId != undefined && this.experimentPlanningId !== '' && this.experimentPlanningId !== null && this.experimentPlanningId > 0) {
      this.experimentService.GetExperimentById(this.experimentPlanningId)
        .subscribe(
          result => {
            this.experimentService.form = result;
            this.oldResults = JSON.parse(JSON.stringify(result));
            this.analysts = result.experimentType.analysts; 
            var hasAnalyst = this.analysts.find(a => a.analystId === result.analyst.analystId);
            if(hasAnalyst === undefined || hasAnalyst === null)
              this.analysts.push(result.analyst);
            this.selectedEmailUsers = this.experimentService.form.notifyEmails;              
            this.isRestrictedRole=  this.commonService.applyRole(result.experimentType.projectId,result.experimentType.groupId)
            this.getFilteredAnalysts();
            this.getUsersForProjectGroup(result.experimentType.projectId,result.experimentType.groupId);
            this.isPaused = !this.experimentService.form.isActive;
            this.isDisabled = this.isPaused || this.experimentService.form.experimentStatus.toLowerCase() === AppConstants.COMPLETE.toLowerCase();
            let expType = this.experimentTypeList?.find(t => t.experimentTypeId === this.experimentService.form.experimentType.experimentTypeId);
            if ((expType === undefined || expType === null || expType === '') && this.experimentTypeList !== undefined) {
              this.populateModelData(this.experimentService.form.experimentType);
            }
          }
        );
    }
  }

  experimentStartDateChange(date) {
    let currentDate = new Date();
    let dialogRef;
    let dialogConfig = this.experimentService.getDialogConfig();
    let message = `The timeframe for the scheduled experiment is short notice.  Please contact the lab manager to confirm timely processing.`;
    let headerMessage = "Warning";
    let messageType = "Info";
    dialogConfig.data = { message: message, headerMessage: headerMessage, messageType: messageType };

    date = new Date(date);
    var diff_in_time = date.getTime() - currentDate.getTime();

    let diff_in_days = diff_in_time / (1000 * 3600 * 24);
    if (diff_in_days <= 4) {
      dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
    }
    return dialogRef;
  }
  dateFormatter(data) {
    return data ? formatDate(data, 'MM/dd/yyyy', this.locale) : '';
  }

  public onSubmit() {
    if (this.isFormValid()) {
      this.experimentService.form.analystId = this.experimentService.form.analyst.analystId;
      this.experimentService.form.templateModel.templateId = this.experimentService.form.templateModel.templateId;
      this.experimentService.form.experimentTypeId = this.experimentService.form.experimentType.experimentTypeId;
      if (this.experimentService.form.experimentPlanningId == 0) {
        this.hasChange = false;
        this.experimentService.form.experimentStatus = 'New';
        this.experimentService.getNewExperimentId(this.experimentService.form.experimentTypeId)
          .subscribe(async result => {
            this.latestExperimentId = result;
            if (this.latestExperimentId != this.newExperimentId) {
              let dialogConfig = this.experimentService.getDialogConfig();
              let message = `Experiment with ID ${this.newExperimentId} has already been created. New experiment ID is ${this.latestExperimentId}.`;
              dialogConfig.data = { message: message, headerMessage: AppConstants.MESSAGEHEADERINFO, messageType: AppConstants.MESSAGETYPECONFIRM };
              this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
              this.dialogRef.afterClosed().subscribe(
                status => {
                  if (status) {
                    this.validateAndCreateExperiment()
                  }
                },
                error => {
                  console.log(error);
                },
                () => { });
            }
            else {
              this.validateAndCreateExperiment();
            }
          },
            error => {
              console.log(error);
            }
          );
      }
      else {
        if (this.isExperimentModified(this.experimentService.form)) {
          let dialogRef = this.experimentStartDateChange(this.experimentService.form.experimentStartDate);
          if (dialogRef !== undefined) {
            dialogRef.afterClosed().subscribe(
              status => {
                if (status) {
                  this.updateExperiment();
                }
              },
              error => {
                console.log(error);
              },
              () => {
                // this.params.context.componentParent.refreshGrid(AppConstants.UPDATESTATUS);
              }
            );
          }
          else {
            this.updateExperiment();
          }
        }
        else {
          let dialogConfig = this.experimentService.getDialogConfig();
          dialogConfig.data = { message: AppConstants.NOCHANGEMESSAGE, headerMessage: AppConstants.MESSAGEHEADERINFO, messageType: AppConstants.MESSAGETYPEINFO };
          this.dialog.open(StatusDialogComponent, dialogConfig);
        }
      }
    }
  }

  validateAndCreateExperiment() {
    this.experimentService.form.experimentId = this.latestExperimentId;
    if (this.getOneRingSteps(this.experimentService.form.templateModel).length > 0) {
      if (!this.isOneRingUser) {
        this.createExperimentStatus();
      }
      else {
        this.validateModelAndCreateExperiment();
      }
    }
    else
    {
      this.invalidModel=false;
      this.createExperiment();
    }
  }

  createExperimentStatus() {
    let dialogConfig = this.experimentService.getDialogConfig();
    dialogConfig.data = { message: AppConstants.MESSAGEUSERONERING, headerMessage: AppConstants.MESSAGETYPEWARNING };
    this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe(
      status => {
        if (status) {
          this.createExperiment();
        }
        else
          this.hasChange = true;
      },
      error => {
        console.log(error);
      },
      () => {
      });
  }

  // Validate the one riing user when creating experiment
  oneRingHasUser() {
    let userInfo = this.commonService.getSessionStorageValue(AppConstants.LOGGEDINUSERINFO);
    if (userInfo !== undefined && userInfo !== null) {
      let localAccountId = JSON.parse(userInfo).localAccountId;
      this.dataService.getOneRingMembersGroups().subscribe(async res => {
        for (const group of res.value) {
          await new Promise<void>((resolve, reject) => {
            this.dataService.getOneRingMembers(group.id)
              .subscribe(resu => {
                for (let i = 0; i < resu.value.length; i++) {
                  if (resu.value[i].id === localAccountId) {
                    this.isOneRingUser = true;
                    break;
                  }
                }
                resolve();
              });
          });
        }
      });
    }
  }

  createExperiment(skipExperimentId?: boolean) {    
    this.experimentService.CreateExperiment(this.experimentService.form, skipExperimentId)
      .subscribe(
        result => {         

         if(result.ExperimentPlanningId>0)
          this.toastr.success('Experiment created successfully !!');
          
          if(result.OneRingExpCreateStatus>0 && !this.invalidModel) 
          {
           this.toastr.success('Experiments '+result.OneRingExperiments+' created successfully in One Ring!!');
          }
          else if(result.OneRingErrorMsg!="")
           this.toastr.error("One Ring experiment creation failed."+result.OneRingErrorMsg+"!!");

          
          this._messageService.filter('refresh');          
          this.newExperimentId = "";
          this.latestExperimentId = "";
          this.reloadPage(result.ExperimentPlanningId);
        },
        error => {
          console.log(error);
          this.toastr.error(error, 'Experiment creation failed !!');
        }
      );
  }
  
  validateModelAndCreateExperiment() {
    this.invalidModel=false;
    var oneRingSteps = this.getOneRingSteps(this.experimentService.form.templateModel).length;
    if (oneRingSteps > 0) {
      var experimentType = this.experimentService.form.experimentType.name;
      var project = this.experimentService.form.experimentType.project != null && this.experimentService.form.experimentType.project != undefined ? this.experimentService.form.experimentType.project.name : this.experimentService.form.templateModel.project.name;
      var group = this.experimentService.form.experimentType.group != null ? this.experimentService.form.experimentType.group.name : this.experimentService.form.templateModel.group.name;
      var model = new ValidationModel({ experimentType: experimentType, project: project, group: group, experimentIds: [], experimentPurposeModels: []});
      this.experimentService.ValidateModel(this.experimentService.form.experimentId,this.experimentService.form.templateModel.templateId,model).subscribe(
        result => {
          if (result != null) {

            let dialogConfig = this.experimentService.getDialogConfig();

            let message = ''; 
            let expTypeMessage = '';            
            if (result.project === null || result.project === undefined)
              message = `Project ${project}`;
            if (result.group === null || result.group === undefined)
              message = message === '' ? message + `Group ${group}` : message + `, Group ${group}`;
            if ((result.experimentType === null || result.experimentType === undefined) && result.isExperimentTypeRequired)
              expTypeMessage = `ExperimentType ${experimentType} is not associated with Group ${group} in One-Ring. `;

            if (result.project === null || result.project === undefined || result.group === null || result.group === undefined || ((result.experimentType === null || result.experimentType === undefined) && result.isExperimentTypeRequired)) {
              message = message === '' ? `${expTypeMessage}Please press 'Ok' to create experiment in Cerebro or 'Cancel' to return.` : `${message} does not exist in One-Ring. ${expTypeMessage}Please press 'Ok' to create experiment in Cerebro or 'Cancel' to return.`;
              dialogConfig.data = { message: message, headerMessage: AppConstants.MESSAGETYPECONFIRMATION, messageType: AppConstants.MESSAGETYPECONFIRM };
              this.invalidModel = true;
            }
            else {
              let stringMessage = '';
              if (result.experimentPurposeModels !== undefined && result.experimentPurposeModels.length > 0) {
                result.experimentPurposeModels.forEach(element => {
                  stringMessage += element.experimentId + ':' + '"' + element.experimentPurpose + '".'
                });
                message = `${result.experimentPurposeModels.length} of ${oneRingSteps} experiment(s) already exist in One-Ring. ${stringMessage} Please press 'Ok' to continue, 'Skip' to increment the Experiment ID or 'Cancel' to return.`;
                dialogConfig.data = { message: message, headerMessage: AppConstants.MESSAGETYPECONFIRMATION, messageType: AppConstants.MESSAGETYPECONFIRMATION };

              }
            }
            if (message === '') {
              //everything is validated. Create Cerebro and OneRing Experiment.
              this.createExperiment();          
            }
            else {
              this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
              this.dialogRef.afterClosed().subscribe(
                status => {
                  // If Ok is pressed
                  if (status === 'confirm') {
                    if (this.invalidModel) {
                      // project/group/experiment type does not exist in one-ring so only create cerebro experiment
                      this.createExperiment();
                    }
                    else {
                      // create cerebro experiment and the experiments in one-ring which are not present in one-ring.                      
                      this.createExperiment();
                    }
                  }
                  else if (status === 'skip') {
                    this.createExperiment(true);
                  }
                  else
                    this.hasChange = true;
                },
                error => {
                  console.log(error);
                },
                () => {
                }
              );
            }
          }
        },
        error => {
          console.log(error);
          this.toastr.error(error, 'Model validation failed !!');
        }
      );
    }
    else {
      this.createExperiment();
    }

  }

  
  isExperimentModified(experiment: ExperimentPlanningModel) {
    return this.oldResults.ExperimentType.Name !== experiment.experimentType.name || this.oldResults.isNotificationEnabled != experiment.isNotificationEnabled || this.selectedEmailUsers.length !== experiment.notifyEmails.length || this.oldResults.NumberoFPools !== experiment.numberoFPools || this.oldResults.Analyst.AnalystName !== experiment.analyst.analystName || this.oldResults.TemplateModel.Name !== experiment.templateModel.name || this.oldResults.ExperimentStartDate !== experiment.experimentStartDate.toJSON() || this.oldResults.ProjectedOutcome !== experiment.projectedOutcome || this.oldResults.ExperimentalDesign !== experiment.experimentalDesign ? true : false
  }

  getOneRingSteps(templateModel: TemplateModel) {
    return templateModel.stageDetails.filter(x => x.processDetails.filter(y => y.processTypeId !== null && y.processTypeId === ProcessTypes.OneRing).length > 0);
  }

  updateExperiment() {
    this.experimentService.UpdateExperiment(this.experimentService.form)
      .subscribe(
        result => {
          this._messageService.filter('refresh');
          this.toastr.success('Experiment updated successfully !!');
          this.reloadPage(this.experimentService.form.experimentPlanningId);
        },
        error => {
          console.log(error);
          this.toastr.error(error, 'Experiment update failed !!');
        }
      );
  }

  onclickExperimentId(experimentPlanningId) {
    if (experimentPlanningId != "") {
      this.router.navigate([AppConstants.CEREBRORUNSHEETPAGE, experimentPlanningId]);
    }

  }

  populateUserDropdown() {
    this.userList = []
    this.dataService.getUserGroup().subscribe(async res => {
      res.value.forEach(item => {
          if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env) {
              if (!item.displayName.includes(AppConstants.CEREBRO_OBSERVER)) {
                  this.addEmailUsers(item);
              }
          }
          else if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Dev_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Qa_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Local_Env) {
              if (!item.displayName.includes(AppConstants.CEREBRO_OBSERVER_DEV)) {
                  this.addEmailUsers(item);
              }
          }
      });

        await new Promise<void>((resolve, reject) => {
          this.usersEmailList = this.userList
          this.filteredUserList = this.userEmailFilterControl.valueChanges.pipe(
            startWith(""),
            map((value: string) => {
              this.usersEmailList.forEach(option => {
                option.show = (option.displayName)
                  .toLocaleLowerCase()
                  .includes(value.toLowerCase());
              });
              this.usersEmailList = this.sortPipe.transform(this.usersEmailList, 'asc', 'displayName');
              return this.usersEmailList;
            })
          );
          resolve();
        });
      });  
    } 

  userList: Array<NotifyEmailModel> = [];
  addEmailUsers(item) {
    for (let i = 0; i < item.members.length; i++) {
        let user = this.userList?.find(x => x?.email === item.members[i].mail);
        let analyst = this.seucrityGroupUsers?.find(x => x.userADId === item.members[i].id)
        if ((user === undefined || user === null) && item.members[i].accountEnabled && item.members[i].displayName !== null && (analyst != undefined && analyst != null)) {
            this.userList.push(new NotifyEmailModel({
                notifyEmailId: 0,
                experimentPlanningId: this.experimentService.form.experimentPlanningId,
                displayName: item.members[i].displayName,
                email: item.members[i].mail,
                createdBy: '', modifiedBy: '', createdDate: null, modifiedDate: null, isActive: true,

            }));
        }
    }
}

seucrityGroupUsers: SecurityGroupUserModel[];
    public getUsersForProjectGroup(projectId, groupId) {

        this.securityGroupService.GetUsersByProjectGroup(projectId, groupId).subscribe(res => {
            this.seucrityGroupUsers = res;
            this.populateUserDropdown();
        },
            error => {
                console.log(error);
            });
    }

  isFormValid() {
    let isValid = true;

    if (this.experimentService.form.experimentType === undefined) {
      this.toastr.error('Please select the experiment type.');
      isValid = false;
    }
    else if (this.experimentService.form.experimentType !== undefined && this.experimentService.form.experimentType.name === 'Mutagenesis' && (this.experimentService.form.numberoFPools === null || this.experimentService.form.numberoFPools == 0)) {
      this.toastr.error('Please enter the number of pools.');
      isValid = false;
    }
    if (this.experimentService.form.analyst === undefined || this.experimentService.form.analyst === null) {
      this.toastr.error('Please select the analyst.');
      isValid = false;
    }
    if (this.experimentService.form.templateModel === undefined || this.experimentService.form.templateModel === null || this.experimentService.form.templateModel.templateId === 0) {
      this.toastr.error('Please select the template.');
      isValid = false;
    }
    if (this.experimentService.form.experimentStartDate === null) {
      this.toastr.error('Please select experiment start date.');
      isValid = false;
    }
    else if (this.experimentService.form.experimentStartDate < this.minDate || this.experimentService.form.experimentStartDate > this.maxDate) {
      this.toastr.error('Please select valid start date.');
      isValid = false;
    }
    return isValid;
  }

  setDateRange() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();
    this.minDate = new Date(currentYear - 1, currentMonth, currentDate);
    this.maxDate = new Date(currentYear + 1, currentMonth, currentDate);
  }

  populateModelData(experimentType = null) {
    this.experimentTypeService.getExperimentTypes(true)
      .subscribe(async result => {
        this.experimentTypeList = result.filter(r => r.isActive === true);
        if(experimentType != null) {
          this.experimentTypeList.push(this.experimentService.form.experimentType);
        }

        this.experimentTypeList = this.sortPipe.transform(this.experimentTypeList, 'asc', 'Name');
        await new Promise<void>((resolve, reject) => {
          
          this.filteredExperimentTypes = this.experimentTypeFilterControl.valueChanges.pipe(
            startWith(""),
            map((value: string) => {
              this.experimentTypeList.forEach(option => {
                option.show = (option.project?.name + ' | ' + option.name + ' | ' + option.group?.name)
                  .toLocaleLowerCase()
                  .includes(value.toLowerCase());
              });
              return this.experimentTypeList;
            })
          );
          resolve();
        });

      },
        error => {
          console.log(error);
        }
      );

  }

  getNewExperimentId(experimentTypeId) {
    this.experimentService.getNewExperimentId(experimentTypeId)
      .subscribe(async result => {
        this.newExperimentId = result;
      },
        error => {
          console.log(error);
        }
      );
  }

  public onChange() {
    let dialogConfig = this.experimentService.getDialogConfig();
    dialogConfig.data = { isActive: !this.isPaused, componentName: this.experimentService.form.experimentId };
    this.dialogRef = this.dialog.open(PauseStatusDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {
          this.experimentService.UpdateStatus(this.experimentService.form.experimentPlanningId, this.isPaused)
            .subscribe(result => {
              if (this.isPaused)
                this.toastr.success('Experiment resumed successfully !!');
              else
                this.toastr.success('Experiment paused successfully !!');

              this.reloadPage(this.experimentService.form.experimentPlanningId);
            },
              error => {
                this.toastr.error('Experiment status update failed !!');
              })
        }
      },
      error => {
        console.log(error);
      },
      () => {
        // this.params.context.componentParent.refreshGrid(AppConstants.UPDATESTATUS);
      }
    );
  }

  onCancel() {
    this.router.navigate([AppConstants.DASHBOARD]);
  }

  reloadPage(experimentPlanningId) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([AppConstants.CEREBROEXPERIMENTPAGE, experimentPlanningId]);
  }

  editExperimentTypeSelection(event) {
    this.getNewExperimentId(event.value.experimentTypeId);
    this.experimentService.form.templateModel = undefined;
    this.experimentService.form.analyst = event.value.defaultAnalyst;
    this.analysts = event.value.analysts.filter((g: any) => g.isActive === true);
    this.hasChange = true;
    this.getFilteredAnalysts();
    this.getUsersForProjectGroup(event.value.project.projectId,event.value.group.groupId);
  }

  getFilteredAnalysts() {
    new Promise<void>((resolve, reject) => {

      this.filteredAnalysts = this.analystFilterControl.valueChanges.pipe(
        startWith(""),
        map((value: string) => {
          this.analysts.forEach(option => {
            option.show = option.analystName
              .toLocaleLowerCase()
              .includes(value.toLowerCase());
          });
          return this.analysts;
        })
      );
      resolve();
    });

    new Promise<void>((resolve, reject) => {

      this.templateService.GetTemplatesByProjectGroupId(this.experimentService.form.experimentType?.projectId, this.experimentService.form.experimentType?.groupId)
      .subscribe(async result => {
        this.templateList = result?.filter(r => r.isActive === true);
        this.templateList = this.sortPipe.transform(this.templateList, 'asc', 'Name');
        if(this.experimentService.form.templateModel?.templateId > 0)
        {
          var selectedTemplate = this.templateList.filter(x => x.templateId === this.experimentService.form.templateModel?.templateId);
          if(selectedTemplate === undefined || selectedTemplate.length === 0)
            this.templateList.push(this.experimentService.form.templateModel);
        }
        await new Promise<void>((resolve, reject) => {

          this.filteredTemplates = this.templateFilterControl.valueChanges.pipe(
            startWith(""),
            map((value: string) => {
              this.templateList.forEach(option => {
                option.show = option.name
                  .toLocaleLowerCase()
                  .includes(value.toLowerCase());
              });
              return this.templateList;
            })
          );
          resolve();
        });

      },
        error => {
          console.log(error);
        }
      );
      resolve();
    });
  }  

  valueChange(event) {
    this.hasChange = true;
  }

  compareExperimentTypeFn: ((f1: any, f2: any) => boolean) | null = this.compareExperimentTypeByValue;

  compareExperimentTypeByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  compareAnalystFn: ((f1: any, f2: any) => boolean) | null = this.compareAnalystByValue;

  compareAnalystByValue(f1: any, f2: any) {
    return f1 && f2 && f1.analystName === f2.analystName;
  }

  compareUserEmailFn: ((f1: any, f2: any) => boolean) | null = this.compareUserByValue;

  compareUserByValue(f1: any, f2: any) {
    return f1 && f2 && f1.email === f2.email;
  }

  compareTemplateFn: ((f1: any, f2: any) => boolean) | null = this.compareTemplateByValue;

  compareTemplateByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

}
