import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GridSizeChangedEvent } from 'ag-grid-community';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { EditButtonRendererComponent } from 'src/app/custom/edit-button-renderer/edit-button-renderer.component';
import { SlideCellRendererComponent } from 'src/app/custom/slide-cell-renderer/slide-cell-renderer.component';
import { ExperimentTypeService } from 'src/app/shared/services/experiment-type.service';
import { MessageService } from 'src/app/shared/services/message-service';
import { ExperimentTypeModel } from 'src/app/shared/services/webclient-api';
import { ExperimentTypePopupComponent } from './experiment-type-popup/experiment-type-popup.component';
import { CommonService } from 'src/app/shared/services/common/common.service';


@Component({
  selector: 'app-experiment-type',
  templateUrl: '../../shared/grid-component/grid.component.html',
  styleUrls: ['./experiment-type.component.scss']
})
export class ExperimentTypeComponent implements OnInit, OnDestroy {
  rowData: any[] = [];
  private gridApi;
  private gridColumnApi;
  public savedFilter: any;
  public savedSort: any;
  componentName = AppConstants.EXPERIMENTTYPE;
  componentTitle = AppConstants.EXPERIMENTTYPETITLE;
  public context: any;
  public subscription: any;


  constructor(private dialog: MatDialog,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private _messageService: MessageService,
   
    private commonService: CommonService,

    private service: ExperimentTypeService) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    });
  }

  ngOnInit(): void {
 
    this.getExperimentTypes()
  }

  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }

  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  columnDefs = [
    { headerName: 'Name', field: 'name', sortable: true, filter: true, width: 150, tooltipValueGetter: (params) => params.value },
    { headerName: 'Project | Group', field: 'projectGroup', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value },
    { headerName: 'Description', field: 'description', sortable: true, filter: true, width: 150, tooltipValueGetter: (params) => params.value },
    { headerName: 'Prefix', field: 'prefix', sortable: true, filter: true, width: 50, tooltipValueGetter: (params) => params.value },
    { headerName: 'Sequence', field: 'sequence', sortable: true, filter: true, width: 60, tooltipValueGetter: (params) => params.value },
    { headerName: 'Default Analyst', field: 'defaultAnalyst.analystName', sortable: true, filter: true, width: 100, tooltipValueGetter: (params) => params.value },
    { headerName: 'Modified By', field: 'modifiedBy', sortable: true, filter: true, width: 100, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Modified Date', field: 'modifiedDate', sortable: true, filter: true, width: 70, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) : '';
      }
    },
    { headerName: 'Active', field: 'isActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 50 },
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      width: 50,
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  getExperimentTypes() {
    this.service.getExperimentTypes(false)
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {          
            this.rowData.push({
              experimentTypeId: r.experimentTypeId,
              name: r.name,
              projectGroup: r.project?.name+" | "+r.group?.name,
              analysts: r.analysts,
              defaultAnalyst: r.defaultAnalyst,
              prefix: r.prefix,
              sequence: r.sequence,
              description: r.description,
              isActive: r.isActive,
              createdDate: r.createdDate,
              createdBy: r.createdBy,
              modifiedDate: r.modifiedDate,
              modifiedBy: r.modifiedBy,
              projectId:r.projectId,
              zeroPadding:r.zeroPadding,
              groupId:r.groupId
            });
          });
          
          this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDEXPERIMENTTYPEFILTER);
          this.savedSort = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDEXPERIMENTTYPESORT);
        },
        error => {
          console.log(error);
        }
      )
  }

  add() {
    this.showDialog();
  }

  showDialog() {

    let dialogConfig = this.service.getDialogConfig();

    dialogConfig.data = this.componentName;
    this.dialog.open(ExperimentTypePopupComponent, dialogConfig);
  }

  update(row) {
    if (row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      let data = this.rowData.find(x => x.experimentTypeId === row.data.experimentTypeId);
      row.data.Analysts = data.analysts;
      let dialogConfig = this.service.getDialogConfig();
      dialogConfig.data = { componentName: this.componentName, selectedData: row.data };
      this.dialog.open(ExperimentTypePopupComponent, dialogConfig);
    }
  }

  updateStatus(experimentTypeModel: ExperimentTypeModel, isActive: boolean) {
    experimentTypeModel.isActive = isActive;
    this.service.UpdateExperimentType(experimentTypeModel)
      .subscribe(result => {
        this._messageService.filter('refresh');
        this.toastr.success('Experiment Type updated successfully !!');
      },
        error => {
          console.log('error');
          this.toastr.error(error, 'Experiment Type update failed !!');
        });
  }
  
  refreshGrid() {
    this.getExperimentTypes();
    this.gridApi.refreshClientSideRowModel('aggregate')
    // this.clearSortandFilter();
    this.onCloseEvent();
  }

  refreshGridFilters() {
    //this. getApiTypes();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.clearSortandFilter();
    this.onCloseEvent();
  }

  
  onColumnMoved(event) {
    this.gridApi.sizeColumnsToFit();
  }

  clearSortandFilter() {
    this.gridColumnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
    this.gridApi.setFilterModel(null);
    this.gridApi.sizeColumnsToFit();
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }


  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onSortChanged(params) {
    this.savedSort = this.gridColumnApi.getColumnState();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDEXPERIMENTTYPESORT, JSON.stringify(this.savedSort));
  }

  onFilterChanged(params) {
    this.savedFilter = this.gridApi.getFilterModel();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDEXPERIMENTTYPEFILTER, JSON.stringify(this.savedFilter));
  }

  onFirstDataRender(params) {

    if (this.savedSort != undefined && this.savedSort != null && this.savedSort.length != 0) {
      this.gridColumnApi.applyColumnState({
        state: this.savedSort,
        applyOrder: true,
      });
    }


    this.gridApi.setFilterModel(this.savedFilter);
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  ngOnDestroy() {
    try { 
        if (this.gridApi) {
            this.gridApi.destroy();
            this.gridApi = false;
        }

    } catch (error) {
      console.log(error);

    }
}

}
