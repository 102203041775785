import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from "src/app/configuration-settings";
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from 'src/app/shared/services/loading.service'
import { WebClientAPI,ApiTypeModel,ApiTypeDetailModel} from '../webclient-api'
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';



@Injectable({
    providedIn: 'root'
  })

  export class ApiTypeService {
    private messageSource;

      httpOptions = {
          headers: new HttpHeaders({ 
              'Content-Type': 'application/json' 
          })
      };

      apiType:ApiTypeModel;
      apiTypes:ApiTypeModel[];
      apiTypeDetailModel:ApiTypeDetailModel;

     initializeApiType() 
            {      
              this.apiTypeDetailModel=new ApiTypeDetailModel({ 
                apiTypeDetailId: 0,
                apiTypeId: 0,
                groupTypeId: 0,
                analyticalMethods: "",
                analysisFrequency: "",
                sampleDays: "",
                totalVesselEstimate: 0,
                vesselId: 0,
                isActive: true,
                createdDate: new Date(),
                createdBy:null,
                modifiedDate: new Date(),
                modifiedBy: null
                });

              this.apiType=new ApiTypeModel({ 
                                apiTypeId: 0,
                                name: "",
                                description:"",
                                groupId: 0,
                                projectId: 0,
                                processTypeId:0,
                                version: 0,
                                isActive: true,
                                createdDate: new Date(),
                                createdBy: null,
                                modifiedDate: new Date(),
                                modifiedBy: null,
                                apiTypeDetails: this.apiTypeDetailModel
                                });
            }
      
      constructor(private webClientAPI: WebClientAPI, private http: HttpClient,private loadingService: LoadingService) { }

      getApiTypes(): Observable<any> {
        this.loadingService.setMessage('Loading API Types...');
        return this.webClientAPI.apiType_GetApiTypes()
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }
      

      getOneRingExperimentMetaData(): Observable<any> {
        this.loadingService.setMessage('Loading One-Ring data...');
        return this.webClientAPI.apiType_GetOneRingExperimentMetaData()
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      getApiTypeById(apiTypeId): Observable<any> {
        this.loadingService.setMessage('Loading...');
        return this.webClientAPI.apiType_GetApiTypeById(apiTypeId)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      getApiTypeByName(apiTypeName): Observable<any> {
        this.loadingService.setMessage("Loading API Type..");
        return this.webClientAPI.apiType_GetApiTypeByName(apiTypeName)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      public createApiType(model: ApiTypeModel)
      {
        this.loadingService.setMessage('Creating API Type...');
        this.messageSource = new BehaviorSubject(model);
        return this.webClientAPI.apiType_CreateApiType(model)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      checkApiTypeUsedInTemplate(Id: number): Observable<any> {
        this.loadingService.setMessage('Loading...');
       return this.webClientAPI.apiType_CheckApiTypeUsedInTemplate(Id)
       .map(result => result)
       .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
       
      }
      public updateApiType(model: ApiTypeModel)
      {
        this.loadingService.setMessage('Updating API Type...');
        this.messageSource = new BehaviorSubject(model);
        return this.webClientAPI.apiType_UpdateApiType(model.apiTypeId, model)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }
      
      public updateStatus(model: ApiTypeModel,isActive: boolean)
      {
        this.loadingService.setMessage('Updating api type status...');
        this.messageSource = new BehaviorSubject(model);
        return this.webClientAPI.apiType_UpdateStatus(model.apiTypeId, isActive)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }

      public cancelStatusUpdate() {
        this.messageSource = new BehaviorSubject('cancel');
      }

      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "45%";       
        return dialogConfig;
  
      }
      getValidationDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "25%";
   
        return dialogConfig;
  
      }
    }