import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from 'src/app/shared/services/loading.service';

import { ApiTypeDetailModel, ExperimentPlanningModel, NotifyEmailModel, ProcessDetailModel, TemplateModel, ValidationModel, WebClientAPI } from './webclient-api';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { catchError, map, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExperimentService {
  private messageSource;
  // currentMessage = this.messageSource.asObservable();

  httpOptions = {
    headers: new HttpHeaders({ 
        'enctype': 'multipart/form-data' 
    })
};

  constructor(private webClientAPI: WebClientAPI, private http: HttpClient, private loadingService: LoadingService) { }

  form: ExperimentPlanningModel;
  initializeFormGroup() {
    this.form = new ExperimentPlanningModel({
      experimentPlanningId: 0,
      isNotificationEnabled: true,
      experimentId: null,
      analystId: 0,     
      templateModel: new TemplateModel({
      templateId: 0
      }),
      experimentTypeId: 0,
      numberoFPools: null,
      notifyEmails: new Array<NotifyEmailModel>(),
      experimentStartDate: null,
      experimentalDesign: null,
      projectedOutcome: null,
      isActive: true

    });
  }
 
  public GetExperiments(showallrecords: boolean): Observable<any> {
    this.loadingService.setMessage('Loading Experiments...');
    return this.webClientAPI.experiment_GetExperiments(showallrecords)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }


  public GetExperimentById(experimentPlanningId): Observable<any> {
    this.loadingService.setMessage('Loading Experiment...');
    return this.webClientAPI.experiment_GetExperimentById(experimentPlanningId)
      .map(result => result)
      .catch(error => error).finally(() => this.loadingService.clearMessage());
  }


  public hasExperimentWithExperimentTypeId(id): Observable<any> {
    this.loadingService.setMessage('Loading Experiment...');
    return this.webClientAPI.experiment_HasExperimentWithExperimentTypeId(id)
      .map(result => result)
      .catch(error => error).finally(() => this.loadingService.clearMessage());
  }

  public CreateExperiment(model: ExperimentPlanningModel, skipExperimentId: boolean): Observable<any> {
    this.loadingService.setMessage('Creating New Experiment...');
    this.messageSource = new BehaviorSubject(model);

    return this.webClientAPI.experiment_CreateExperiment(skipExperimentId, model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public ValidateModel(experimentId: string, templateId: number, model: ValidationModel): Observable<any> {
    this.loadingService.setMessage('Validating Model...');
   
    return this.webClientAPI.experiment_ValidateModel(experimentId, templateId, model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public UpdateExperiment(model: ExperimentPlanningModel): Observable<any> {
    this.loadingService.setMessage('Updating Experiment...');
    this.messageSource = new BehaviorSubject(model);
    return this.webClientAPI.experiment_UpdateExperiment(model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }
  public UpdateExperimentTemplate(model:TemplateModel): Observable<any> {   
    this.loadingService.setMessage('Updating Experiment Template...');
    this.messageSource = new BehaviorSubject(model);   
    return this.webClientAPI.experiment_UpdateExperimentTemplate(model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }
  public UpdateStatus(id, isActive): Observable<any> {
    this.loadingService.setMessage('Updating Experiment Status...');
    // let inputParams = { params: { "experimentPlanningId": id, "isActive" : isActive, "isRequester" : isRequester } }

    return this.webClientAPI.experiment_PauseExperiment(id, isActive)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public UpdateProcess(model: ProcessDetailModel): Observable<any> {
    this.loadingService.setMessage('Updating Experiment Process...');

    return this.webClientAPI.experiment_UpdateProcess(model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public UpdateExperimentStatus(experimentPlanningId, experimentStatus): Observable<any> {
    this.loadingService.setMessage('Updating Experiment Status...');

    return this.webClientAPI.experiment_UpdateExperimentStatus(experimentPlanningId, experimentStatus)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public MarkProcessComplete(model: ProcessDetailModel, areAllProcessComplete: boolean): Observable<any> {
    if(model.isComplete)
      this.loadingService.setMessage('Marking process complete...');
    else
      this.loadingService.setMessage('Marking process inprogress...');

    return this.webClientAPI.experiment_MarkProcessComplete(areAllProcessComplete, model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public initiateFileWatcher(model: ProcessDetailModel): Observable<any> {
    this.loadingService.setMessage('Initiating file upload...');
    return this.webClientAPI.experiment_InitiateFileWatcher(model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public GetFileTypes(): Observable<any> {
    this.loadingService.setMessage('Loading File Types...');
    return this.webClientAPI.fileMapping_GetFileMappings()
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public UploadFile(formData: FormData):  Observable<any> {
    this.loadingService.setMessage('Uploading file(s)...');
    this.messageSource = new BehaviorSubject(formData);
    var model: any;
    let url = ConfigurationSettings.REST_API_URL + "/Experiment/UploadAsync";
    return this.http.post(
        url,
        formData, this.httpOptions)
        .pipe(
              map(result => {
                return result;
              }), 
              catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(error);
              }),
              finalize(() => {
                this.loadingService.clearMessage();
              })
        );
  }

  public getAnalystByName(): Observable<any> {
    this.loadingService.setMessage('Getting Analyst information...');
    return this.webClientAPI.experiment_GetAnalystByName()
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
        
  }

  public UpdateFileStatusById(fileId,isActive): Observable<any> {
    this.loadingService.setMessage('Updating File Status...');

    return this.webClientAPI.experiment_UpdateFileStatusById(fileId,isActive)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public GetFileStatusRecords(experimentProcessId): Observable<any> {
    this.loadingService.setMessage('Loading File Statuses...');

    return this.webClientAPI.experiment_GetFileStatusRecords(experimentProcessId)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public GetResultFiles(experimentProcessId, fileMappingId): Observable<any> {
    this.loadingService.setMessage('Loading Files...');

    return this.webClientAPI.experiment_GetResultFilesByExperimentProcessId(experimentProcessId, fileMappingId)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public DeactivateFileStatusRecords(experimentProcessId): Observable<any> {
    this.loadingService.setMessage('Clear File Statuses...');

    return this.webClientAPI.experiment_DeactivateFileStatusRecords(experimentProcessId)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }


  public GetFileDetailsById(fileId): Observable<any> {
    this.loadingService.setMessage('Loading...');

    return this.webClientAPI.experiment_GetFileDetailsById(fileId)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public GetFileDetailsByExperimentProcessId(experimentProcessId): Observable<any> {
    this.loadingService.setMessage('Loading data...');

    return this.webClientAPI.experiment_GetFileDetailsByExperimentProcessId(experimentProcessId)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public GetExperimentFileData(experimentPlanningId): Observable<any> {
    this.loadingService.setMessage('Loading...');

    return this.webClientAPI.experiment_GetExperimentFileData(experimentPlanningId)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }
  deactivateFileDetails(processDetail: ProcessDetailModel[]): Observable<any> {    
    this.loadingService.setMessage('Deactivating Mapped Files...');
    return this.webClientAPI.experiment_DeactivateFileDetails(processDetail).map(result => result)
    .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public getNewExperimentId(experimentTypeId): Observable<any> {
    this.loadingService.setMessage('Getting new experiment ID...');
    return this.webClientAPI.experiment_GetNewExperimentId(experimentTypeId)
            .map(result => result)
            .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }  

  public GetOneRingDetails(groupTypeId, vesselId, analyticalMethods): Observable<any> {
    this.loadingService.setMessage('Loading One Ring Details...');
    return this.webClientAPI.experiment_GetOneRingDetails(groupTypeId,vesselId,analyticalMethods)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }
  
  public GetOneRingStrainInfo(processDetailId): Observable<any> {
    this.loadingService.setMessage('Loading Process Results...');
    return this.webClientAPI.experiment_GetDynamicGridInformation(processDetailId)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public ValidateOneRingStrainInfo(apiTypeDetail): Observable<any> {
    this.loadingService.setMessage('Validating strain information...');
    return this.webClientAPI.experiment_ValidateOneRingStrainInfo(apiTypeDetail)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public ValidateDataJoinGridHeadersInfo(apiTypeDetails, fileMappingId): Observable<any> {
    this.loadingService.setMessage('Validating data to join...');
    return this.webClientAPI.experiment_ValidateDataJoinGridHeadersInfo(fileMappingId, apiTypeDetails)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public SubmitToOneRing(model): Observable<any> {
      this.loadingService.setMessage('Submit to One Ring...');
      this.messageSource = new BehaviorSubject(model);
  
      return this.webClientAPI.experiment_SubmitToOneRing(model)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public registerToBionexus(experimentProcessId, dataToRegister): Observable<any> {
      this.loadingService.setMessage('Registering strains to Bionexus...');
      this.messageSource = new BehaviorSubject(dataToRegister);
  
      return this.webClientAPI.experiment_RegisterToBionexus(experimentProcessId,dataToRegister)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public joinColumns(experimentId, experimentProcessId, dataToJoin): Observable<any> {
      this.loadingService.setMessage('Joining columns...');
      this.messageSource = new BehaviorSubject(dataToJoin);
  
      return this.webClientAPI.experiment_JoinColumns(experimentId, experimentProcessId,dataToJoin)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public getDataFromBionexus(experimentId, experimentProcessId, filteredData): Observable<any> {
      this.loadingService.setMessage('Loading strain information from Bionexus...');
      this.messageSource = new BehaviorSubject(filteredData);
  
      return this.webClientAPI.experiment_GetStrainInfoFromBionexus(experimentId, experimentProcessId,filteredData)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public validateDataForRegisterToBionexus(experimentProcessId, dataToRegister): Observable<any> {
      this.loadingService.setMessage('Validating Strain Information...');
      this.messageSource = new BehaviorSubject(dataToRegister);
  
      return this.webClientAPI.experiment_ValidateStrainInformation(experimentProcessId,dataToRegister)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }
        
  public cancelStatusUpdate() {
    this.messageSource = new BehaviorSubject('cancel');
  }

  refreshGrid() {
    let currentMessage;
    if(this.messageSource !== undefined) {
      currentMessage = this.messageSource.asObservable();
      this.messageSource = undefined;
    }
    return currentMessage;
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    return dialogConfig;

  }
}