import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import * as CryptoJS from 'crypto-js';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { UserClaim } from 'src/app/models/userClaim';
import { SecurityGroupModel} from 'src/app/shared/services/webclient-api'

const SECRET_KEY = 'secret_key';

@Injectable()
export class CommonService {
  private isShowAllRecords=false;
  private groupId;
  private experimentType;
  constructor(private _http: HttpClient) {}

  downloadFile(file: any, name: string) {
    let blob = new Blob([file.byteArray], { type: file.type });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
}

formatDate(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
}

setSessionStorageValue(key: any, value: string) {
  let encryptedValue = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
  sessionStorage.setItem(key, encryptedValue);
}

getSessionStorageValue(key: any): any {
  let data: any;
  if (sessionStorage.getItem(key) !== null) {
      data = CryptoJS.AES.decrypt(sessionStorage.getItem(key), SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
  } else {
      data = sessionStorage.getItem(key)
  }
  return data;
}

getAnalystsStorageValue(key: any): any {
  let data: any;
  if (sessionStorage.getItem(key) !== null) {
      data = CryptoJS.AES.decrypt(sessionStorage.getItem(key), SECRET_KEY);
      data = JSON.parse(data.toString(CryptoJS.enc.Utf8));
      // data = data.toString(CryptoJS.enc.Utf8).split(',');
  } else {
      data = sessionStorage.getItem(key)
  }
  return data;
}

convertToLocalTime(date: any) {
  var splitedDate = date.split(/[- T :]/);
  return new Date(Date.UTC(splitedDate[0], splitedDate[1] - 1, splitedDate[2], splitedDate[3], splitedDate[4], splitedDate[5]));
}

convertToLocalDate(date: any) {  
  return new Date(date.value).toLocaleDateString();
}

camelCaseReviver(key, value) {
  if (value && typeof value === 'object') {
      for (var k in value) {
          if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
              value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
              delete value[k];
          }
      }
  }
  return value;
}
applyRole(projectId,groupId) {
   let isRestrictedRole=false;
  let claims = new UserClaim();
  let rolesData = this.getSessionStorageValue(AppConstants.UIUSERROLE);
  if (rolesData !== '' && rolesData !== null) {
    claims = JSON.parse(rolesData, this.camelCaseReviver) as UserClaim;
    let securityGroupModel = claims.securityRoles as SecurityGroupModel[];    
    let securityGroupForUserRole=securityGroupModel.filter(x => x.projectId == projectId && x.groupId == groupId && (x.groupAD.aDName == AppConstants.CEREBRO_ADMIN_DEV || x.groupAD.aDName == AppConstants.CEREBRO_ANALYST_DEV ||x.groupAD.aDName == AppConstants.CEREBRO_ADMIN || x.groupAD.aDName == AppConstants.CEREBRO_ANALYST));
 
    if (securityGroupForUserRole.length == 0) {
      isRestrictedRole = true;      
    }      
  }
  return isRestrictedRole;
}

isAnalystOrObserverRole(projectId,groupId) {
  let isAdmin=false;
 let claims = new UserClaim();
 let rolesData = this.getSessionStorageValue(AppConstants.UIUSERROLE);
 if (rolesData !== '' && rolesData !== null) {
   claims = JSON.parse(rolesData, this.camelCaseReviver) as UserClaim;
   let securityGroupModel = claims.securityRoles as SecurityGroupModel[];
   let securityGroupForUserRole=securityGroupModel.filter(x => x.projectId == projectId && x.groupId == groupId && (x.groupAD.aDName == AppConstants.CEREBRO_ADMIN || x.groupAD.aDName == AppConstants.CEREBRO_ADMIN_DEV));   
   if (securityGroupForUserRole.length>0) {
    isAdmin = true;      
   }      
 }
 return isAdmin;
}

isAnalystRole(projectId,groupId) {
  let isAnalystRole=false;
 let claims = new UserClaim();
 let rolesData = this.getSessionStorageValue(AppConstants.UIUSERROLE);
 if (rolesData !== '' && rolesData !== null) {
   claims = JSON.parse(rolesData, this.camelCaseReviver) as UserClaim;
   let securityGroupModel = claims.securityRoles as SecurityGroupModel[];    
   let securityGroupForUserRole=securityGroupModel.filter(x => x.projectId == projectId && x.groupId == groupId && (x.groupAD.aDName == AppConstants.CEREBRO_ANALYST_DEV || x.groupAD.aDName == AppConstants.CEREBRO_ANALYST));

   if (securityGroupForUserRole.length > 0) {
    isAnalystRole = true;      
   }      
 }
 return isAnalystRole;
}

getLoggedInUserName() {
  let userName = "";
  let userInfo = this.getSessionStorageValue(AppConstants.LOGGEDINUSERINFO);
  if(userInfo !== undefined && userInfo !== null) {
      userName = JSON.parse(userInfo).name;
  }

  return userName;
}
// isLoggedInUserRequester()
// {
//     let isRequester : boolean;
//     let rolesData = this.getSessionStorageValue(AppConstants.UIUSERROLE);
//     let claims: UserClaim;
//     if (rolesData !== '' && rolesData !== null) {
//         claims = JSON.parse(rolesData) as UserClaim;
//         if(claims.isCerebro_Requester && !(claims.isCerebro_Analyst || claims.isCerebro_Admin))
//         {
//           isRequester = true;  
//         }
//         else
//         {
//           isRequester = false; 
//         }
//       }
//       else
//       {
//         console.log("Roles Data not found.")
//       }

//       return isRequester;
// }

setShowAllRecords(isShowAllRecordsUI) {
  this.isShowAllRecords = isShowAllRecordsUI;
}
  getshowAllRecordsStatus() {
    return this.isShowAllRecords;

  }

  setGroupId(groupId) {
    this.groupId = groupId;
  }
  getGroupId() {
      return this.groupId;      
  }

  setExperimentType(experimentType) {
    this.experimentType = experimentType;
  }
  getExperimentType() {
    return this.experimentType;      
  }

  formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }

}
