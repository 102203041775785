import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from 'src/app/shared/services/loading.service'
import { AttributeDetailModel, FileMappingKeyValueModel, FileMappingModel,ApiTypeModel, GroupModel, ProcessTypeModel,LineageDetailModel, ProcessDetailModel, ProjectModel, StageDetailModel, TemplateModel, WebClientAPI } from '../webclient-api';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';

export enum ProcessTypes {
  ManualUpload=1,
  FileWatcherUpload=2,
  BioNexus=3,
  OneRing=4,
  FieldConcatenation=5
}

@Injectable({
    providedIn: 'root'
  })

  export class TemplateService {
    private messageSource;
    // currentMessage = this.messageSource.asObservable();
    
      httpOptions = {
          headers: new HttpHeaders({ 
              'Content-Type': 'application/json' 
          })
      };
  
      constructor(private webClientAPI: WebClientAPI, private http: HttpClient,private loadingService: LoadingService) { }

      templatesModel: TemplateModel;  
      stageModel:StageDetailModel;
      processModel:ProcessDetailModel;
      attributesModel: AttributeDetailModel;     
      fileMappingsModel:FileMappingModel[];
      templates: TemplateModel[];
      projectsModel:ProjectModel[];
      groupsModel:GroupModel[];
      lineageModel:LineageDetailModel;     
      mappingKeyValues:FileMappingKeyValueModel;
      processType:ProcessTypeModel;
      processTypes:ProcessTypeModel[];
      apiTypes:ApiTypeModel[];

  initializeTemplates() {
    this.templatesModel = new TemplateModel({
      templateId: 0,
      name: null,
      description: null,
      groupId: null,
      projectId: null,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      stageDetails: [],
      lineageDetails: [],
      group:null,
      project: null
    });
  }
  initializeStageDetails() {
    this.stageModel = new StageDetailModel({
      stageDetailId: 0,
      templateId: 0,
      stageId: 0,
      name: null,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,      
      sortOrder:1,
      stages: [],
      processDetails: []
    });
  }
  initializeProcessDetails() {
    this.processModel = new ProcessDetailModel({
      processDetailId: 0,
      stageDetailId: 0,
      processId: 0,
      name: null,
      processTypeId:null, 
      apiTypeId:null,
      fileMappingId:null, 
      canModifyProcess:true,
      isFileAssigned:false,    
      turnAroundTime: 0,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      sortOrder:1,
      attributeDetails: [],
      processes: []//,
      //processTypes:[]
    });
   }

   initializeAttributesDetails() {
    this.attributesModel = new AttributeDetailModel({
      attributeDetailId :0 ,
      attributeId :0 ,
      processDetailId :0 ,
      name :null,
      value :null ,
      isActive :true ,
      createdDate :new Date(),
      createdBy :null,
      modifiedDate :new Date(),
      modifiedBy :null ,      
    });
   }
   initializeLineageDetails() {
    this.lineageModel=new LineageDetailModel({
    lineageDetailId: 0,
    templateId: null,
    sourceFileMappingId: null,
    destinationFileMappingId: null,
    isActive: true,
    createdDate: new Date(),
    createdBy: null,
    modifiedDate:new Date(),
    modifiedBy:null,
    sortOrder: 1,
    mappingKeyValues: []
    });
   }
   initializeProcessType() {
    this.processType=new ProcessTypeModel({
    processTypeId: 0,
    name: "",
    description:"",
    isActive: true,
    createdDate: new Date(),
    createdBy: null,
    modifiedDate:  new Date(),
    modifiedBy: null,
    periodEnd: new Date(),
    periodStart:  new Date()
    });
   }

   initializeAPIType() {
    this.processType=new ApiTypeModel({
      apiTypeId: 0,
      name:null,
      description:null,
      groupId: null,
      projectId:null,
      processTypeId: null,
      version: null,
      isActive:true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy:null, 
      apiTypeDetails: null,
      group: null,
      project: null,
      processType:null, 
    });
   }
   initializeMappingKeyValues() {
    this.mappingKeyValues=new FileMappingKeyValueModel({
      sourceKeyId: null,
      destinationKeyId: null,
    });
   }
   
    // ---------------------------------------------------------------------
    // Getting GetTemplates
    // ---------------------------------------------------------------------
      
    GetTemplates(): Observable<any> {
      this.loadingService.setMessage('Loading Templates...');
      return this.webClientAPI.template_GetTemplates()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    GetTemplatesByProjectGroupId(projectId: number, groupId: number): Observable<any> {
      this.loadingService.setMessage('Loading Templates...');
      return this.webClientAPI.template_GetTemplatesByProjectGroupId(projectId, groupId)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    checkTemplateUsedInExperiment(Id: number): Observable<any> {
      this.loadingService.setMessage('Loading...');
      return this.webClientAPI.template_CheckTemplateUsedInExperiment(Id)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }It

    GetProcessTypes(): Observable<any> {
      this.loadingService.setMessage('Loading Process Types...');
      return this.webClientAPI.template_GetProcessTypes()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    GetAPITypes(): Observable<any> {
      this.loadingService.setMessage('Loading API Types...');
      return this.webClientAPI.apiType_GetApiTypes()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    getApiTypesByProjectGroupId(projectId,GroupId): Observable<any> {
      this.loadingService.setMessage('Loading API Types...');
      return this.webClientAPI.apiType_GetApiTypesByProjectGroupId(projectId,GroupId)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    getFileMappingById(Id: number): Observable<any> {
      this.loadingService.setMessage('Loading...');
     return this.webClientAPI.fileMapping_GetFileMappingById(Id)
     .map(result => result)
     .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
     
    }
    getFrindlyHeaders(): Observable<any> {
      this.loadingService.setMessage('Loading Friendly Headers...');
      return this.webClientAPI.fileMapping_GetFriendlyHeaders()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }
    SaveTemplateInfo(model:TemplateModel)
    {
      this.loadingService.setMessage('Saving Template...');
      this.messageSource = new BehaviorSubject(model);
      return this.webClientAPI.template_SaveTemplateInfo(model)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    
    public updateTemplate(template: TemplateModel): Observable<any> {
      this.loadingService.setMessage('Updating Template...');
      this.messageSource = new BehaviorSubject(template);
      return this.webClientAPI.template_UpdateTemplate(template)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }


     GetTemplateById(Id: number): Observable<any> {
      this.loadingService.setMessage('Loading...');      
     return this.webClientAPI.template_GetTemplateInfoById(Id)
     .map(result => result)
     .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
     
    }

    public UpdateTemplate(template: TemplateModel, isActive)
    {
      this.loadingService.setMessage('Deactivating Template...');
      this.messageSource = new BehaviorSubject(template);
      return this.webClientAPI.template_UpdateTemplateStatus(template.templateId, isActive)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    GetStages():Observable<any>
    {
      this.loadingService.setMessage('Loading Stages...');
      return this.webClientAPI.template_GetStages()
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    GetProcesses():Observable<any>
    {
      this.loadingService.setMessage('Loading Processes...');
      return this.webClientAPI.template_GetProcesses()
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    GetAttributes():Observable<any>
    {
      this.loadingService.setMessage('Loading Attributes...');
      return this.webClientAPI.template_GetAttributes()
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    UpdateStageDetails(stageDetailId): Observable<any> { 
      this.loadingService.setMessage('Updating Stage...');
      this.messageSource = new BehaviorSubject(stageDetailId);
      return this.webClientAPI.template_UpdateStageDetailsStatus(stageDetailId).map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

    UpdateProcessDetails(stgIdx,prsIdx): Observable<any> {  
      this.loadingService.setMessage('Updating Process...');
      return this.webClientAPI.template_UpdateProcessDetailsStatus(stgIdx,prsIdx).map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    UpdateAttributeDetails(stgIdx,prsIdx,att): Observable<any> {
      this.loadingService.setMessage('Updating Attribute...');
      return this.webClientAPI.template_UpdateAttributeDetailsStatus(stgIdx,prsIdx,att).map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }
  
  
    refreshGrid() {
      let currentMessage;
      if(this.messageSource !== undefined) {
        currentMessage = this.messageSource.asObservable();
        this.messageSource = undefined;
      }
      return currentMessage;
    }
    
    public cancelStatusUpdate() {
      this.messageSource = new BehaviorSubject('cancel');
    }

    getDialogConfig() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "45%";       
      return dialogConfig;

    }
    
   
    getValidationDialogConfig() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "25%";
      return dialogConfig;

    }

    GetBionexusEntities(name): Observable<any> {
      this.loadingService.setMessage('Loading Bionexus Dropdown Data...');
      return this.webClientAPI.experiment_GetBioNexusColumns(name)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    GetBionexusSecurityGroups(): Observable<any> {
      this.loadingService.setMessage('Loading Bionexus Dropdown Data...');
      return this.webClientAPI.experiment_GetBioNexusSecurityGroups()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    GetBionexusBundles(): Observable<any> {
      this.loadingService.setMessage('Loading Bionexus Dropdown Data...');
      return this.webClientAPI.experiment_GetBioNexusBundles()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    GetOperationTypes(): Observable<any> {
      this.loadingService.setMessage('Loading bionexus Operation Types...');
      return this.webClientAPI.apiType_GetOperationTypes()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }
      

}