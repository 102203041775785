import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { finalize} from "rxjs/operators";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/finally";
import { LoadingService } from "./loading.service";
import { SettingService } from "./setting.service";
import { AppConstants } from "src/app/common-utility/appconstants";
import { Settings } from "src/app/models/settings";
import { ConfigurationSettings } from 'src/app/configuration-settings';


@Injectable({
    providedIn: "root",
})
export class DataService {   
  
    constructor(private http: HttpClient, private loader: LoadingService, @Inject(SettingService) private config: Settings) { }


    getUserGroup(): Observable<any> {
        this.loader.setMessage("Loading...");
        let filter;
        // let graphApiUrl = ConfigurationSettings.REST_API_URL + "/api/User/getUserGroups"
        if(ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env) {
            filter = "?$expand=members&$filter=startswith(displayName,'RES-SG-Cerebro_Admin') or startswith(displayName,'RES-SG-Cerebro_Analyst') or startswith(displayName,'RES-SG-Cerebro_Observer')  or startswith(displayName,'RES-SG-Cerebro_Owner')";
        }
        else if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Dev_Env 
        || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Qa_Env
        || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Local_Env) {
            filter = "?$expand=members&$filter=startswith(displayName,'RES-SG-Cerebro_DevT_Admin') or startswith(displayName,'RES-SG-Cerebro_DevT_Analyst') or startswith(displayName,'RES-SG-Cerebro_DevT_Observer')  or startswith(displayName,'RES-SG-Cerebro_DevT_Owner')";
        }
        
        const graphApiUrl = `${this.config.graphGroupApi}${filter}`;
        // const graphApiUrl = `https://graph.microsoft.com/v1.0/groups?$expand=members&$filter=startswith(displayname,'RES-SG-Cerebro_Admin')`;
        return this.http.get(graphApiUrl).pipe(finalize(() => {
            this.loader.clearMessage();
        }));
    }

    getGroups(): Observable<any> {
        let filter;
        if(ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env) {
            filter = "?$count=true&$select=id,displayName&$filter=displayName eq 'RES-SG-Cerebro_Admin' or displayName eq 'RES-SG-Cerebro_Analyst' or displayName eq 'RES-SG-Cerebro_Observer' or displayName eq 'RES-SG-Cerebro_Owner'";
        }
        else if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Dev_Env 
        || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Qa_Env
        || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase().trim() === AppConstants.Local_Env) {
            filter = "?$count=true&$select=id,displayName&$filter=displayName eq 'RES-SG-Cerebro_DevT_Observer' or displayName eq 'RES-SG-Cerebro_DevT_Analyst' or displayName eq 'RES-SG-Cerebro_DevT_Admin' or displayName eq 'RES-SG-Cerebro_DevT_Owner'";
        }
        const graphApiUrl = `${this.config.graphGroupApi}${filter}`;
        return this.http.get(graphApiUrl).pipe(finalize(() => {
            this.loader.clearMessage();
        }));
    }

    getOneRingMembersGroups(): Observable<any> {
            let filter;
            if(ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env) {
                filter = "?$count=true&$select=id,displayName&$filter=displayName eq 'RES-SG-One-Ring_Admin' or displayName eq 'RES-SG-One-Ring_Requestor' or displayName eq 'RES-SG-One-Ring_Analyst'";
            }
            else if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Dev_Env 
            || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Qa_Env
            || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Local_Env) {
                filter = "?$count=true&$select=id,displayName&$filter=displayName eq 'RES-SG-1Ring_DevT_Requestor' or displayName eq 'RES-SG-1Ring_DevT_Analyst' or displayName eq 'RES-SG-1Ring_DevT_Admin'";
            }
            const graphApiUrl = `${this.config.graphGroupApi}${filter}`;
            return this.http.get(graphApiUrl).pipe(finalize(() => {
                this.loader.clearMessage();
            }));
        }
            
    getData(id): Observable<any> {
        let filter = "/" + id + "/members?$count=true&$select=id,userPrincipalName,displayName"
        const graphApiUrl = `${this.config.graphGroupApi}${filter}`;
        return this.http.get(graphApiUrl).pipe(finalize(() => {
            this.loader.clearMessage();
        }));
    }
    getOneRingMembers(id): Observable<any> {
        let filter = "/" + id + "/members?$count=true&$select=id,userPrincipalName"
        const graphApiUrl = `${this.config.graphGroupApi}${filter}`;
        return this.http.get(graphApiUrl).pipe(finalize(() => {
            this.loader.clearMessage();
        }));
    }

    public getUsers(filter: string): Observable<any> {
        this.loader.setMessage("Loading...");
        const graphApiUrl = `${this.config.graphUserApi + filter}&${AppConstants.USERPROPERTYFILTER}`;
        return this.http.get(graphApiUrl).pipe(finalize(() => {
            this.loader.clearMessage();
        }));
    }

    public getLoggedInUserInfo(): Observable<any> {
        this.loader.setMessage("Loading...");
        let filter = AppConstants.USERPROPERTYFILTER;
        const graphApiUrl = `${this.config.graphMeApi}?${filter}`;
        return this.http.get(graphApiUrl).pipe(finalize(() => {
            this.loader.clearMessage();
        }));;
    }

    public getGroupMembers(groupName: string, clearMessage: boolean = true): Observable<any> {
        this.loader.setMessage("Loading...");

        let filter = '$expand=members&$filter=displayName eq ' + `'${groupName}'`;
        const graphApiUrl = `${this.config.graphGroupApi}?${filter}`;
        return this.http.get(graphApiUrl).pipe(finalize(() => {
            if (clearMessage)
                this.loader.clearMessage();
        }));
    }
}