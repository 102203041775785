import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions, GridSizeChangedEvent } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from '../common-utility/appconstants';
import { LinkRendererComponent } from '../custom/link-renderer/link-renderer.component';
import { ExperimentService } from '../shared/services/experiment.service';
import { MessageService } from '../shared/services/message-service';
import { ExperimentPlanningModel, FilterDataModel } from '../shared/services/webclient-api';
import * as _ from 'lodash';
import { CommonService } from '../shared/services/common/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit,OnDestroy {
  rowData: ExperimentPlanningModel[] = [];
  rowDataMaster: ExperimentPlanningModel[] = [];
  private gridApi;
  private gridColumnApi;
  componentName = AppConstants.DASHBOARD;
  componentTitle = AppConstants.DASHBOARD;
  public context: any;
  public subscription: any;
  public gridOptions: GridOptions;
  public showallrecords: boolean = false;
  public dashboardUIdata: ExperimentPlanningModel[] = [];
  public savedFilter: any;
  public filterData: FilterDataModel[] = [];
  public savedSort: any;
  public sortModel = [
    { colId: 'experimentStartDate', sort: 'asc' }
  ];

  public savedGroupId: any;
  public savedExperimentType: any;
  public selectedGroup: any;


  constructor(private dialog: MatDialog,
    private toastr: ToastrService,
    private _messageService: MessageService,
    private service: ExperimentService,
    private commonService: CommonService) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.showallrecords = this.commonService.getshowAllRecordsStatus();
      this.refreshGrid(this.showallrecords);
    });
  }
  ngOnInit(): void {
    this.showallrecords = this.commonService.getshowAllRecordsStatus();
    this.getExperiments(this.showallrecords);
    this.gridOptions = {
      getRowStyle: this.getRowStyleScheduled
    }
  }

  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }

  frameworkComponents = {
    linkRenderer: LinkRendererComponent
  };

  columnDefs = [
    { headerName: 'Experiment ID', field: 'experimentId', sortable: true, filter: true, width: 85, cellRenderer: 'linkRenderer', tooltipValueGetter: (params) => params.value },
    { headerName: 'Project', field: 'experimentType.project.name', sortable: true, filter: true, width: 75, tooltipValueGetter: (params) => params.value },
    { headerName: 'Experiment Type', field: 'experimentType.name', sortable: true, filter: true, width: 95, tooltipValueGetter: (params) => params.value },
    { headerName: 'Analyst', field: 'analyst.analystName', sortable: true, filter: true, width: 100, tooltipValueGetter: (params) => params.value },
    { headerName: 'Current Stage | Process', field: 'currentProcess', sortable: true, filter: true, width: 110, tooltipValueGetter: (params) => params.value },
    { headerName: 'Next Stage | Process', field: 'nextProcess', sortable: true, filter: true, width: 110, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Next Process Start Date', field: 'nextProcessStartDate', sortable: true, filter: true, width: 115, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }) : '';
      }, tooltipValueGetter: (params) => params.value
    },
    {
      headerName: 'Experiment Start Date', field: 'experimentStartDate', sortable: true, filter: true, width: 115, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }) : '';
      }, tooltipValueGetter: (params) => params.value
    },
    { headerName: 'Experiment Status', field: 'experimentStatus', sortable: true, filter: true, width: 85, tooltipValueGetter: (params) => params.value },
    { headerName: 'Experimental Design', field: 'experimentalDesign', sortable: true, filter: true, width: 125, tooltipValueGetter: (params) => params.value }
  ];

  getExperiments(showallrecords: boolean) {
    this.service.GetExperiments(showallrecords)
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {
            this.rowData.push(new ExperimentPlanningModel({
              experimentPlanningId: r.experimentPlanningId,
              analyst: r.analyst,
              templateModel: r.templateModel,
              experimentId: r.experimentId,
              experimentType: r.experimentType,
              currentProcessEndDate: r.currentProcessEndDate,
              currentProcess: r.currentStage !== null && r.currentStage !== "" && r.currentProcess !== null ? r.currentStage + ' | ' + r.currentProcess : "",
              nextProcess: r.nextStage !== null && r.nextStage !== "" && r.nextProcess !== null ? r.nextStage + ' | ' + r.nextProcess : "",
              nextProcessStartDate: r.nextProcessStartDate,
              experimentStartDate: r.experimentStartDate,
              experimentStatus: r.experimentStatus === AppConstants.INPROGRESS ? AppConstants.INPROGRESSUI : r.experimentStatus,
              modifiedBy: r.modifiedBy,
              filterData: r.filterData,
              experimentalDesign:r.experimentalDesign
            }));
          });

          if (showallrecords == true) {
            this.commonService.setShowAllRecords(showallrecords);
            this.gridApi.setRowData([]);
            this.gridApi.applyTransaction({ add: this.rowData });

          }
          this.rowDataMaster = this.rowData;
          this.filterData = this.rowData[0]?.filterData;
          this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDDASHBOARDFILTER);
          this.savedSort = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDDASHBOARDSORT);
          this.savedGroupId = this.commonService.getGroupId();
          this.savedExperimentType = this.commonService.getExperimentType();
          if (this.savedGroupId != undefined && this.savedGroupId != "" && this.savedExperimentType != undefined && this.savedExperimentType != "") {
            this.rowData = this.rowDataMaster.filter(result => {
              return (result.experimentType.name == this.savedExperimentType && result.templateModel.groupId == this.savedGroupId);

            });
            this.selectedGroup = this.filterData.find(x => x.experimentTypeName == this.savedExperimentType && x.groupId == this.savedGroupId);
          }
        },
        error => {
          console.log(error);
        }
      )

  }

  filterGroup(event) {
    if (this.selectedGroup != null) {
      this.savedGroupId = this.selectedGroup.groupId;
      this.savedExperimentType = this.selectedGroup.experimentTypeName;
      this.commonService.setGroupId(this.selectedGroup.groupId);
      this.commonService.setExperimentType(this.selectedGroup.experimentTypeName);
      this.gridApi.refreshClientSideRowModel('aggregate');
      this.rowData = this.rowDataMaster.filter(result => {
        return (result.experimentType.name == this.selectedGroup.experimentTypeName && result.templateModel.groupId == this.selectedGroup.groupId);

      });
    }
    else {
      this.savedGroupId = "";
      this.savedExperimentType = "";
      this.commonService.setGroupId("");
      this.commonService.setExperimentType("");
      this.gridApi.refreshClientSideRowModel('aggregate');
      this.rowData = this.rowDataMaster;
    }

  }

  getRowStyleScheduled(params) {
    let endDate = new Date(params.data.currentProcessEndDate);
    var currentDate = new Date();

    if (params.data.experimentStatus != AppConstants.PAUSED && params.data.experimentStatus != AppConstants.COMPLETE) {
      if (endDate <= currentDate) {
        return {
          'background-color': 'rgb(224, 65, 65)',
        }
      }
      else {
        var timeDiff = endDate.getTime() - currentDate.getTime();

        var hours = timeDiff / (1000 * 60 * 60);
        // var absoluteHours = Math.floor(hours);    
        if ((hours <= 1))
          return {
            'background-color': 'rgb(255, 255, 0)',
          }
      }
    }
    else if (params.data.experimentStatus == AppConstants.COMPLETE) {
      return {
        'background-color': 'rgb(147, 238, 144)',
      }
    }
    return null;

  };

  public onShowAllRecordsChange() {
    this.showallrecords = !this.showallrecords
    this.commonService.setShowAllRecords(this.showallrecords);
    this.getExperiments(this.showallrecords);
  }

  refreshGrid(showallrecords) {
    this.getExperiments(this.showallrecords);
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.clearSortandFilter();
    this.onCloseEvent();
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }


  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  clearSortandFilter() {
    this.commonService.setGroupId("");
    this.commonService.setExperimentType("");
    this.selectedGroup = "";
    this.gridColumnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
    this.gridApi.setFilterModel(null);
    this.gridApi.sizeColumnsToFit();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDDASHBOARDSORT, JSON.stringify(this.gridColumnApi.getColumnState()));
    this.commonService.setSessionStorageValue(AppConstants.SAVEDDASHBOARDFILTER, JSON.stringify(this.gridApi.getFilterModel()));

  }

  onFirstDataRender(params) {

    if (this.savedSort != undefined && this.savedSort != null && this.savedSort.length != 0) {
      this.gridColumnApi.applyColumnState({
        state: this.savedSort,
        applyOrder: true,
      });
    }


    this.gridApi.setFilterModel(this.savedFilter);
  }

  ngOnDestroy() {
    try { 
        if (this.gridApi) {
            this.gridApi.destroy();
            this.gridApi = false;
        }

    } catch (error) {
      console.log(error);

    }
}

  onSortChanged(params) {
    this.savedSort = this.gridColumnApi.getColumnState();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDDASHBOARDSORT, JSON.stringify(this.savedSort));
  }

  onFilterChanged(params) {
    this.savedFilter = this.gridApi.getFilterModel();
    let dashboardUi: ExperimentPlanningModel[] = [];
    this.gridApi.context.beanWrappers.rowModel.beanInstance.rowsToDisplay.forEach(element => {
      dashboardUi.push(element.data);
    });
    this.commonService.setSessionStorageValue(AppConstants.SAVEDDASHBOARDFILTER, JSON.stringify(this.savedFilter));
  }

  compareGroupFn: ((f1: any, f2: any) => boolean) | null = this.compareGroupByValue;

  compareGroupByValue(f1: any, f2: any) {
    return f1 && f2 && f1.experimentTypeName === f2.experimentTypeName && f1.groupId === f2.groupId;
  }


}
