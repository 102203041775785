import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from "src/app/configuration-settings";
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from 'src/app/shared/services/loading.service'
import { DelimeterModel, FileMappingModel, MappingRuleModel, SubMappingRuleModel, UserFriendlyHeaderModel, WebClientAPI } from '../webclient-api';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
@Injectable({
    providedIn: 'root'
  })

  export class FileMappingService {
    private messageSource;
    // currentMessage = this.messageSource.asObservable();
    
      httpOptions = {
          headers: new HttpHeaders({ 
              'Content-Type': 'application/json' 
          })
      };
      fileMapping: FileMappingModel;
      mappingRules:MappingRuleModel;
      subMappingRule:SubMappingRuleModel;
      userFriendlyHeaders:UserFriendlyHeaderModel[];
      delimeters:DelimeterModel[];

  initializeFileMap() {

    this.subMappingRule=new SubMappingRuleModel({
      subMappingId: 0,
      mappingRuleId: 0,
      delimeterId: 0,
      headerId: 0,
      headerName: null,
      isKey: true,
      isActive: true,
      createdDate:new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      userFriendlyHeaders: this.userFriendlyHeaders,
      delimeters:this.delimeters
    });
    this.mappingRules=new MappingRuleModel({
    mappingRuleId: 0,
    fileMappingId: 0,
    row: 1,
    column: "",
    headerId: 0,
    headerName: null,
    isKey: true,
    isActive: true,
    createdDate:new Date(),
    createdBy: null,
    modifiedDate: new Date(),
    modifiedBy: null,
    subMappingRules:[]
    })
    //this.mappingRules.subMappingRules.push(this.subMappingRule)
    this.fileMapping = new FileMappingModel({
      fileMappingId: 0,
      name: "",
      description: "",
      fileLocation: "",
      isActive: true,
      createdDate:new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      mappingRules: []
    });
    this.fileMapping.mappingRules.push(this.mappingRules)
  }
      constructor(private webClientAPI: WebClientAPI, private http: HttpClient,private loadingService: LoadingService) { }


      
    getFileMappings(): Observable<any> {
        this.loadingService.setMessage('Loading File Mappings...');
        return this.webClientAPI.fileMapping_GetFileMappings()
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      getFileMappingsByProjectGroupId(projectId,GroupId): Observable<any> {
        this.loadingService.setMessage('Loading File Mappings...');
        return this.webClientAPI.fileMapping_GetFileMappingsByProjectGroupId(projectId,GroupId)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      getDelimeters(): Observable<any> {
        this.loadingService.setMessage('Loading Delimeters...');
        return this.webClientAPI.fileMapping_GetDelimeters()
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      getFrindlyHeaders(): Observable<any> {
        this.loadingService.setMessage('Loading Friendly Headers...');
        return this.webClientAPI.fileMapping_GetFriendlyHeaders()
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      saveFileMapping(model:FileMappingModel)
      {
        this.loadingService.setMessage('Saving file mapping...');
        this.messageSource = new BehaviorSubject(model);
        return this.webClientAPI.fileMapping_SaveFileMapping(model)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }
  
      
      public updateFileMapping(model: FileMappingModel): Observable<any> {
        this.loadingService.setMessage('Updating file mapping...');
        this.messageSource = new BehaviorSubject(model);
        return this.webClientAPI.fileMapping_UpdateFileMapping(model)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      getFileMappingById(Id: number): Observable<any> {
        this.loadingService.setMessage('Loading...');
       return this.webClientAPI.fileMapping_GetFileMappingById(Id)
       .map(result => result)
       .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
       
      }
      public updateFileMappingStatus(model: FileMappingModel, isActive)
      {
        this.loadingService.setMessage('Updating file mapping...');
        this.messageSource = new BehaviorSubject(model);
        return this.webClientAPI.fileMapping_UpdateFileMappingStatus(model.fileMappingId, isActive)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      isFileMappingUsed(fileMappingId): Observable<any> {
        this.loadingService.setMessage('Validating file mapping...');
        return this.webClientAPI.fileMapping_IsFileMappingUsed(fileMappingId)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      }

      public cancelStatusUpdate() {
        this.messageSource = new BehaviorSubject('cancel');
      }

      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }
      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "70%";  
        dialogConfig.maxWidth = '100vw';//overrides default width of dialog
             
        return dialogConfig;
  
      }
     
      getValidationDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "25%";
   
        return dialogConfig;
  
      }

  }
