import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { PostLoginComponent } from './post-login/post-login.component';
import { ExperimentComponent } from './experiment/experiment.component';
import { ExperimentTypeComponent } from './admin/experiment-type/experiment-type.component';
import { TemplateComponent } from './admin/template/template.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { RoleGuardService } from './shared/services/role-gaurd-service';
import { AppConstants } from './common-utility/appconstants';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RunSheetComponent } from './run-sheet/run-sheet.component';
import { ApiTypeComponent } from './admin/api-type/api-type.component';
import { FileMappingComponent } from './admin/file-mapping/file-mapping.component';
import { FileProcessingErrorsComponent } from './analyst-operations/file-processing-errors/file-processing-errors.component';
import { GroupComponent } from './admin/group/group.component';
import { ProjectComponent } from './admin/project/project.component';
import { CreateTemplateComponent } from './admin/template/create-template/create-template.component'
import { CreateApiTypeComponent } from './admin/api-type/create-api-type/create-api-type.component';
import { SecurityGroupComponent } from './admin/security-group/security-group.component';
const routes: Routes = [
  { path: 'Home', component: HomeComponent, canActivate:[MsalGuard, RoleGuardService],  data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV,AppConstants.CEREBRO_ANALYST,AppConstants.CEREBRO_ANALYST_DEV,AppConstants.CEREBRO_OBSERVER,AppConstants.CEREBRO_OBSERVER_DEV]} },
  { path: 'ExperimentSetup', component: ExperimentComponent, canActivate:[MsalGuard, RoleGuardService],  data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV,AppConstants.CEREBRO_ANALYST,AppConstants.CEREBRO_ANALYST_DEV]} },
  { path: 'ExperimentSetup/:id', component: ExperimentComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV,AppConstants.CEREBRO_ANALYST,AppConstants.CEREBRO_ANALYST_DEV,AppConstants.CEREBRO_OBSERVER,AppConstants.CEREBRO_OBSERVER_DEV]} },
  { path: 'ExperimentInformation/:id', component: RunSheetComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV,AppConstants.CEREBRO_ANALYST,AppConstants.CEREBRO_ANALYST_DEV,AppConstants.CEREBRO_OBSERVER,AppConstants.CEREBRO_OBSERVER_DEV]} },
  { path: 'Dashboard', component: DashboardComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV,AppConstants.CEREBRO_ANALYST,AppConstants.CEREBRO_ANALYST_DEV,AppConstants.CEREBRO_OBSERVER,AppConstants.CEREBRO_OBSERVER_DEV]} },
  { path: 'ApiTypes', component: ApiTypeComponent,  canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV]} },
  { path: 'ApiType/:id', component: CreateApiTypeComponent,  canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV]} },
  { path: 'ExperimentTypes', component: ExperimentTypeComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV]} },
  { path: 'Templates', component: TemplateComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV]} },
  { path: 'FileMappings', component: FileMappingComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV]} },
  { path: 'Groups', component: GroupComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_OWNER_DEV,AppConstants.CEREBRO_OWNER]} },
  { path: 'Projects', component: ProjectComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_OWNER_DEV,AppConstants.CEREBRO_OWNER]} },
  { path: 'SecurityGroup', component: SecurityGroupComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_OWNER_DEV,AppConstants.CEREBRO_OWNER]} },
  { path: 'Template/:id/:fromExperiment', component: CreateTemplateComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV,AppConstants.CEREBRO_ANALYST,AppConstants.CEREBRO_ANALYST_DEV,AppConstants.CEREBRO_OBSERVER,AppConstants.CEREBRO_OBSERVER_DEV]} },
  { path: 'UnprocessedFiles', component: FileProcessingErrorsComponent, canActivate:[MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.CEREBRO_ADMIN,AppConstants.CEREBRO_ADMIN_DEV,AppConstants.CEREBRO_ANALYST,AppConstants.CEREBRO_ANALYST_DEV]} },
  { path: 'postlogin', component: PostLoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  //Below route is used to redirect the user on login page when given route in URL is empty.(http://localhost:4200/)
  { path: '', redirectTo: '/postlogin', pathMatch: 'full'},
  //Below route is used to redirect the user on login page when given route in URL is not defined in application routes.(http://localhost:4200/**)
  { path: '**', redirectTo: '/postlogin', pathMatch: 'full'}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
