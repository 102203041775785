import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from "@azure/msal-angular";
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { AppRoutingModule } from './app-routing.module'; 
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AgGridModule } from '@ag-grid-community/angular';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { FormsModule } from '@angular/forms';
import { ApplicationSettings, ApplicationSettingsService } from './application-settings.service';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { PostLoginComponent } from './post-login/post-login.component';
import { ExperimentComponent } from './experiment/experiment.component';
import { TemplateComponent } from './admin/template/template.component';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { RoleGuardService } from './shared/services/role-gaurd-service';
import { SecurityService } from './shared/services/security.service';
import { CommonService } from './shared/services/common/common.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StatusDialogComponent } from './shared/status-dialog/status-dialog.component';
import { TemplateService } from './shared/services/template-service/template.service'
import { SlideCellRendererComponent } from './custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from './custom/edit-button-renderer/edit-button-renderer.component';
import { DeleteButtonRendererComponent } from './custom/button-delete/button-delete.component';
import { DatePipe } from '@angular/common';
import { API_BASE_URL, WebClientAPI } from './shared/services/webclient-api';
import * as tokens from './app.tokens';
import { CreateTemplateComponent } from './admin/template/create-template/create-template.component';
import { ButtonRendererComponent } from './custom/button-renderer/button-renderer.component';
import { CheckboxCellRendererComponent } from './custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { DashboardAlertDialogComponent } from './custom/link-renderer/dashboard-alert-dialog/dashboard-alert-dialog.component';
import { MaterialModule } from './material-module'
import { ExperimentTypeComponent } from './admin/experiment-type/experiment-type.component';
import { ExperimentTypePopupComponent } from './admin/experiment-type/experiment-type-popup/experiment-type-popup.component';
import { ExperimentTypeService } from './shared/services/experiment-type.service';
import { MessageService } from './shared/services/message-service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { LoadingService } from './shared/services/loading.service';
import { httpSetHeaders } from './shared/services/interceptor-service/httpSetHeaders.interceptor';
import { LoadingComponent } from './loading/loading.component';
import { ConfigurationSettings } from './configuration-settings';
import { SortByPipe } from './shared/sort/sort-pipe';
import { PauseStatusDialogComponent } from './experiment/pause-status-dialog/pause-status-dialog.component';
import { RunSheetComponent } from './run-sheet/run-sheet.component';
import { AutocompletePositionDirective } from './shared/directives/AutocompletePositionDirective'
import { FileMappingComponent } from './admin/file-mapping/file-mapping.component';
import { CreateFileMappingPopupComponent } from './admin/file-mapping/file-mapping-popup/create-file-mapping-popup.component';
import { FileMappingService } from './shared/services/file-mapping-service/file-mapping.service'
import { PendingChangesGuard } from './shared/services/pending-changes.guard';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileProcessingErrorsComponent } from './analyst-operations/file-processing-errors/file-processing-errors.component';
import { DeleteFilesPopupComponent } from './analyst-operations/file-processing-errors/delete-files-popup/delete-files-popup.component';
import { ProjectService } from './shared/services/project-service/project.service';
import { GroupsService } from './shared/services/group-service/groups.service';
import { CreateGroupComponent } from './admin/group/group-popup/create-group.component';
import { ProjectComponent } from './admin/project/project.component';
import { CreateProjectComponent } from './admin/project/project-popup/create-project.component';
import { GroupComponent } from './admin/group/group.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { ApiTypeComponent } from './admin/api-type/api-type.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { CreateApiTypeComponent } from './admin/api-type/create-api-type/create-api-type.component';
import { CommaDigitOnlyDirective } from './shared/directives/comma-digit-only.directive';
import { ValidationErrorsPopupComponent } from './run-sheet/validation-errors-popup/validation-errors-popup.component';
import { EnvironmentService } from './shared/services/environment-service/environment.service';
import { SecurityGroupComponent } from './admin/security-group/security-group.component';
import { SecurityGroupService } from './shared/services/security-group.service';
export const configFactory = (configService: ApplicationSettings) => {
  return () => configService;
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoadingComponent,
        PostLoginComponent,
        ExperimentComponent,
        TemplateComponent,
        UnauthorizedComponent,
        DashboardComponent,
        StatusDialogComponent,
        SlideCellRendererComponent,
        EditButtonRendererComponent,
        ExperimentTypeComponent,
        ExperimentTypePopupComponent,
        CreateTemplateComponent,
        ButtonRendererComponent,
        CheckboxCellRendererComponent,
        DashboardAlertDialogComponent,
        PauseStatusDialogComponent,
        RunSheetComponent,
        AutocompletePositionDirective,
        CommaDigitOnlyDirective,
        SortByPipe,
        FileMappingComponent,
        CreateFileMappingPopupComponent,
        FileProcessingErrorsComponent,
        DeleteFilesPopupComponent,
        ProjectComponent,
        GroupComponent,
        CreateGroupComponent,
        CreateProjectComponent,       
        DeleteButtonRendererComponent,
        ApiTypeComponent,
        CreateApiTypeComponent,
        ValidationErrorsPopupComponent,     
        SecurityGroupComponent
    ],
    imports: [
        BrowserModule, AppRoutingModule, HttpClientModule,
        ResearchComponentsCoreModule.forRoot('cerebro-ui'),
        ResearchComponentsMsalIntegrationModule,
        AgGridModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatTabsModule,
        MatIconModule,
        MatCardModule,
        DigitOnlyModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDividerModule,
        MatRadioModule,
        MatTooltipModule,
        MatDialogModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        ToastrModule.forRoot({ timeOut: 15000, closeButton: true }),
        MaterialModule,
        NgxFileDropModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: httpSetHeaders, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [ApplicationSettingsService],
            multi: true
        },
        ApplicationSettingsService,
        MsalService,
        MsalGuard,
        RoleGuardService,
        SecurityService,
        CommonService,
        MsalBroadcastService,
        MessageService,
        ToastrService,
        LoadingService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: API_BASE_URL, useValue: ConfigurationSettings.REST_API_URL },
        { provide: tokens.DATA_SERVICE_TOKEN, useClass: WebClientAPI },
        { provide: MatDialogRef, useValue: {} },
        TemplateService,
        DatePipe,
        SortByPipe,
        ExperimentTypeService,
        WebClientAPI,
        FileMappingService,
        PendingChangesGuard,
        EnvironmentService,
        ProjectService,
        GroupsService,
        SecurityGroupService
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule { }
