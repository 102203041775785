import { Inject, Injectable, InjectionToken } from "@angular/core";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { Configuration as msalConfig } from '@azure/msal-browser';

export const APPLICATION_SETTINGS = new InjectionToken<ApplicationSettings>('APPLICATION_SETTINGS');

export interface ApplicationSettings {
    environment: {
        production: boolean;
        name: string;
    }

    msal: {
        config: msalConfig,
        guard: MsalGuardConfiguration,
        interceptor: MsalInterceptorConfiguration
    }

    infrastructure: {
        baseApiUrl: string,
        applicationInsightsConnectionString: string,
        logLevel: string;
    }
}

export enum LogLevel {
    debug,
    info,
    warn,
    error
  }

/** Simple Service to Inject, so you don't have to directly inject the Injection Token */
@Injectable()
export class ApplicationSettingsService {
    constructor(@Inject(APPLICATION_SETTINGS) public settings: ApplicationSettings) { }
    
    public getLogLevel(): LogLevel {
        const intValue = LogLevel[this.settings.infrastructure.logLevel as keyof typeof LogLevel];
        return intValue;
      }
}