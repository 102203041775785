import { Component, OnDestroy, OnInit } from '@angular/core';
import { SlideCellRendererComponent } from 'src/app/custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from 'src/app/custom/edit-button-renderer/edit-button-renderer.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { MessageService } from 'src/app/shared/services/message-service';
import { ToastrService } from 'ngx-toastr';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { ApiTypeModel } from 'src/app/shared/services/webclient-api';
import { ApiTypeService } from 'src/app/shared/services/api-type-service/api-type.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common/common.service';


@Component({
  templateUrl: '../../shared/grid-component/grid.component.html',
  styleUrls: ['./api-type.component.scss']
})

export class ApiTypeComponent implements OnInit, OnDestroy {
  rowData: any[] = [];
  apiTypeModel: ApiTypeModel;
  public savedFilter: any;
  public savedSort: any;
  private gridApi;
  private gridColumnApi;
  componentName =  AppConstants.APITYPETITLE;;
  componentTitle = AppConstants.APITYPETITLE;
  public context: any;
  public subscription: any;
  constructor(private dialog: MatDialog,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private router: Router,
    private commonService: CommonService,
    public service:ApiTypeService,   
    private _messageService: MessageService) 
    { 
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    })
      service.initializeApiType();
    }

  ngOnInit(): void {
    this. getApiTypes(); 
  }

  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };
  columnDefs = [
    { headerName: 'Name', field: 'name', sortable: true, filter: true, width: 135, tooltipValueGetter: (params) => params.value },
    { headerName: 'Project | Group', field: 'projectGroup', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value }, 
    { headerName: 'Description', field: 'description', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value },   
    { headerName: 'Version', field: 'version', sortable: true, filter: true, width: 70, tooltipValueGetter: (params) => params.value },
    { headerName: 'Modified By', field: 'modifiedBy', sortable: true, filter: true, width: 105, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Modified Date', field: 'modifiedDate', sortable: true, filter: true, width: 110, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) : '';
      }
    },
    { headerName: 'Active', field: 'isActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 75 },
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      width: 75,
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  add() {
    this.navigateToApiTypeDetails(0);
  }

  navigateToApiTypeDetails(apiTypeId) {
    this.router.navigate([AppConstants.CEREBROAPITYPEDETAILSPAGE, apiTypeId]);
  }

    getApiTypes()
    {
      this.service.getApiTypes()
      .subscribe(
        result => {   
          this.rowData = [];
          result.forEach(r => {
          
            this.rowData.push({
              apiTypeId:r.apiTypeId, 
              name:r.name,
              projectGroup:r.project?.name+" | "+r.group?.name,
              description:r.description, 
              groupId:r.groupId, 
              projectId:r.projectId, 
              processTypeId:r.processTypeId, 
              version:r.version, 
              isActive:r.isActive,
              createdDate:r.createdDate, 
              createdBy:r.createdBy, 
              modifiedDate:r.modifiedDate, 
              modifiedBy:r.modifiedBy, 
              apiTypeDetails:r.apiTypeDetails, 
              group:r.group,
              project:r.project,
              processType:r.processType 
            });
          });
          this.service.apiTypes=result;        
          
          this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDAPITYPEFILTER);
          this.savedSort = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDAPITYPESORT);  
          },
        error => {
          console.log(error);
        }
      )

    }

  update(row) {
    if (row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      if (row.data !== null) {
        this.navigateToApiTypeDetails(row.data?.apiTypeId)
        }
      }
    }

  updateStatus(apitype: ApiTypeModel, isActive: boolean) {   
    apitype.isActive = isActive;
    this.service.updateStatus(apitype,isActive)
      .subscribe(result => {
        this._messageService.filter('refresh');
        this.toastr.success('Api type status updated successfully !!');
      },
        error => {
          console.log('error');
          this.toastr.error(error, 'Api type status update failed !!');
        });
  }

  refreshGrid() {
    this. getApiTypes();
    this.gridApi.refreshClientSideRowModel('aggregate')
    // this.clearSortandFilter();
    this.onCloseEvent();
  }

  refreshGridFilters() {
    //this. getApiTypes();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.clearSortandFilter();
    this.onCloseEvent();
  }
  
  onColumnMoved(event) {
    this.gridApi.sizeColumnsToFit();
  }

  clearSortandFilter() {
    this.gridColumnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
    this.gridApi.setFilterModel(null);
    this.gridApi.sizeColumnsToFit();
  }

  cancelStatusUpdate() {
   this.service.cancelStatusUpdate();
  }


  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onSortChanged(params) {
    this.savedSort = this.gridColumnApi.getColumnState();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDAPITYPESORT, JSON.stringify(this.savedSort));
  }

  onFilterChanged(params) {
    this.savedFilter = this.gridApi.getFilterModel();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDAPITYPEFILTER, JSON.stringify(this.savedFilter));
  }

  onFirstDataRender(params) {

    if (this.savedSort != undefined && this.savedSort != null && this.savedSort.length != 0) {
      this.gridColumnApi.applyColumnState({
        state: this.savedSort,
        applyOrder: true,
      });
    }


    this.gridApi.setFilterModel(this.savedFilter);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  ngOnDestroy() {
    try { 
        if (this.gridApi) {
            this.gridApi.destroy();
            this.gridApi = false;
        }

    } catch (error) {
      console.log(error);

    }
}
}
