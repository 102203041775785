import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from '../common-utility/appconstants';
import { ExperimentService } from '../shared/services/experiment.service';
import { SortByPipe } from '../shared/sort/sort-pipe';
import { StatusDialogComponent } from '../shared/status-dialog/status-dialog.component';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { LinkRendererComponent } from '../custom/link-renderer/link-renderer.component';
import { SlideCellRendererComponent } from 'src/app/custom/slide-cell-renderer/slide-cell-renderer.component';
import { GridSizeChangedEvent, SideBarDef } from 'ag-grid-community';
import { FileMappingService } from 'src/app/shared/services/file-mapping-service/file-mapping.service';
import { CreateFileMappingPopupComponent } from 'src/app/admin/file-mapping/file-mapping-popup/create-file-mapping-popup.component'
import { ApiTypeModel, Bundle, FileMappingModel, ProcessDetailModel, SearchColumnListEntryDto } from 'src/app/shared/services/webclient-api'
import { CommonService } from '../shared/services/common/common.service';
import { ProcessTypes, TemplateService } from '../shared/services/template-service/template.service';
import { ClonerService } from '../shared/services/deep-clone/deep-clone.service';
import { ConfigurationSettings } from '../configuration-settings';
import { ValidationErrorsPopupComponent } from './validation-errors-popup/validation-errors-popup.component';
import { EnvironmentService } from '../shared/services/environment-service/environment.service';
import { MessageService } from 'src/app/shared/services/message-service';


interface ExperimentStatus {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-run-sheet',
  templateUrl: './run-sheet.component.html',
  styleUrls: ['./run-sheet.component.scss']
})
export class RunSheetComponent implements OnInit, OnDestroy {
  private gridApi;
  private registerGridApi;
  private registerGridColumnApi;
  public subscription: any;
  private gridColumnApi;
  public gridApiResults;
  public gridColumnApiResults;
  public context: any;
  rowData: any = [];
  resultsRowData: Array<any> = [];

  experimentPlanningId: any;
  defaultAnalyst: any = "Default Analyst";
  templateName: any = "Template Name";
  experimentDetails: any;
  isDisabled: boolean = false;
  selectedStage = new FormControl(0);
  selectedProcess = new FormControl(0);
  selectedSourceInfoTab = new FormControl(0);
  isDataChanged: boolean = false;
  dialogRef: MatDialogRef<any, any>;
  activeProcess: any = new ProcessDetailModel;
  activeStage: any;
  selectedFileType: any;
  fileTypes: any;
  public dropFiles: NgxFileDropEntry[] = [];
  failedFiles: Array<any> = [];
  successFiles: Array<any> = [];
  isFilesProcessed: boolean = false;
  fileCount: number = 0;
  allowedExtensions = ['csv'];
  foundInvalidFiles: boolean = false;
  filename = '';
  browseFiles: File[] = [];
  isValidAnalyst: boolean = false;
  loggedInAnalyst: any;
  previousExperimentStatus: string;
  isFileDetailsVisible: boolean = false;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  fileMapping: FileMappingModel;
  delimeters: any;
  friendlyHeaders: any;
  public savedFilter: any;
  checkAll: boolean;
  optionName: string = AppConstants.DESELECT;
  paginationPageSize = 10;
  strainRegistrationApiId: any;
  getStrainInfoApiId: any;
  operationTypes: ApiTypeModel[];
  isRestrictedRole: boolean = false;
  isAnalystRole: boolean = false;
  loggedInUserName: string = "";
  public sortModel = [
    { colId: 'SampleDate', sort: 'asc' }
  ];
  paginationNumberFormatter = function (params) {
    return "[" + params.value.toLocaleString() + "]";
  };

  registerData: Array<any> = [];
  registerColumnDefs: any = [];
  getDynamicGridData: Array<any> = [];
  getStrainInfoColumnDefs: any = [];
  bionexusEntities: SearchColumnListEntryDto[];
  bionexusProjects: SearchColumnListEntryDto[];
  entityName: any = "";
  projectNames: any = "";  
  bundleNames: any = "";
  registerGridPageSize = 10;

  slectedSampleDay: any;
  isBNXDataValidatedForRegister: boolean = false;
  getDataGridApi: any;
  getDataGridColumnApi: any;
  joinDataApiId: any;
  isJoinDisabled: boolean;
  isPageRefresh: boolean = true;
  constructor(public dialog: MatDialog,
    public environmentService: EnvironmentService,
    public experimentService: ExperimentService,
    private toastr: ToastrService,
    private fileMappingService: FileMappingService,
    private commonService: CommonService,
    public objectClone: ClonerService,
    private _messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private sortPipe: SortByPipe,
    private service: TemplateService) {
    //this.LoadData();
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    })
    this.context = { componentParent: this };
  }

  ExperimentStatusDetails: ExperimentStatus[] = [
    { value: 'New', viewValue: 'New' },
    { value: 'InProgress', viewValue: 'In Progress' },
    { value: 'Completed', viewValue: 'Completed' },
    { value: 'Paused', viewValue: 'Paused' }
  ];

  ngOnInit(): void {
    this.LoadData();

  }

  LoadData() {

    this.loadOperationTypes();
    this.loadBionexusEntities();
    this.loadBionexusBundles();
    this.loadBionexusProjects();
    this.getDelimeters();
    this.getFriendlyHeaders();
    this.route.paramMap.subscribe(params => {
      this.experimentPlanningId = Number(params.get("id"));
    });
    this.populateFileTypes();
    this.getExperimentDetails();


  }

  getExperimentDetails() {
    this.experimentService.GetExperimentById(this.experimentPlanningId)
      .subscribe(
        result => {
          this.experimentDetails = result;
          this.isRestrictedRole = this.commonService.applyRole(this.experimentDetails?.experimentType?.projectId, this.experimentDetails?.experimentType?.groupId);
          this.isAnalystRole = this.commonService.isAnalystRole(this.experimentDetails?.experimentType?.projectId, this.experimentDetails?.experimentType?.groupId);
          this.loggedInUserName = this.commonService.getLoggedInUserName();
          var stage = this.experimentDetails.templateModel.stageDetails.find(x => x.name === this.experimentDetails.currentStage);
          this.experimentDetails.templateModel?.stageDetails?.forEach(x => {
            x.processDetails?.forEach(x => {
              if (this.experimentDetails?.experimentStatus == 'Completed' || this.isRestrictedRole || (this.isAnalystRole && this.experimentDetails.analyst.analystName.trim() != this.loggedInUserName.trim())) {
                x.resultFileModel?.forEach(x => x.isControlDisabled = true)
              }
            });
          })

          if (stage !== undefined) {
            var process = stage.processDetails.find(x => x.name === this.experimentDetails.currentProcess);
            this.activeProcess = this.objectClone.deepClone(process);
            if (this.isPageRefresh) {
              this.selectedProcess.setValue(process.sortOrder - 1);
              this.selectedStage.setValue(stage.sortOrder - 1);
            }
            this.loadOneRingDetails(stage.sortOrder - 1);
          }
          this.previousExperimentStatus = this.experimentDetails.experimentStatus;
          this.isDisabled = !result.isActive || result.experimentStatus === AppConstants.COMPLETE;
          this.experimentService.getAnalystByName()
            .subscribe(async result => {
              this.loggedInAnalyst = result;
              if (this.loggedInAnalyst != undefined && this.experimentDetails.experimentType.analysts.find(x => x.analystId == this.loggedInAnalyst.analystId) != undefined) {
                this.isValidAnalyst = true;
              }
            },
              error => {
                console.log(error);
              }
            );
        },
        error => {
          console.log(error);
          this.toastr.error('Get experiment details failed !!');
        }
      );
  }

  experimentStatus(value) {
    //this.groupRequestId=1;// used for dev  testing 
    let dialogConfig = this.experimentService.getDialogConfig();
    let headerMessage = "Update Status";
    let messageText = (value != undefined && value.value == AppConstants.COMPLETE) ? AppConstants.COMPLETEEXPRERIMENTSTATUSMESSAGE : AppConstants.CHANGEEXPRERIMENTSTATUSMESSAGE;
    dialogConfig.data = { message: messageText, headerMessage: headerMessage };
    let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      status => {
        if (status) {
          if (this.previousExperimentStatus === AppConstants.COMPLETE && (value.value === AppConstants.NEW || value.value === AppConstants.PAUSED)) {
            this.toastr.error('Can not mark a previously completed experiment as New/Paused. !!');
            value.source.value = "";
            this.experimentDetails.experimentStatus = this.previousExperimentStatus;
          }
          else if (this.previousExperimentStatus === AppConstants.INPROGRESS && (value.value === AppConstants.NEW)) {
            this.toastr.error('Can not mark an In Progress experiment as New. !!');
            value.source.value = "";
            this.experimentDetails.experimentStatus = this.previousExperimentStatus;
          }
          else {
            this.experimentService.UpdateExperimentStatus(this.experimentPlanningId, value.value)
              .subscribe(result => {
                this.toastr.success('Experiment status updated successfully !!');
                this.getExperimentDetails();
              },
                error => {
                  this.toastr.error('Experiment status update failed !!');
                })
          }
        }
        else {
          value.source.value = "";
          this.getExperimentDetails();
        }

      },
      error => {
        console.log(error);
      },
      () => {

      }
    );

  }


  editTemplate(experimentPlanningId) {
    this.router.navigate(['/Template', experimentPlanningId, 1]);

  }
  onclickExperimentId() {
    this.router.navigate([AppConstants.CEREBROEXPERIMENTPAGE, this.experimentPlanningId]);
  }

  onAttributeValueChange(process, stage, event) {
    this.isDataChanged = true;
    this.activeProcess = this.objectClone.deepClone(process);
    this.activeStage = stage;
  }

  onTabClick(event, stageDetails, index) {
    this.selectedSourceInfoTab.setValue(0);
    this.loadTabs(event, stageDetails, index);
  }

  onProcessTabClick(event, processDetails: ProcessDetailModel[], index) {
    this.selectedSourceInfoTab.setValue(0);
    this.loadTabs(event, processDetails, index);
  }

  loadTabs(event, model, index) {
    this.failedFiles = [];
    this.isFileDetailsVisible = false;
    this.rowData = [];
    this.isFilesProcessed = false;
    if (model.length > 0)
      this.activeProcess = this.objectClone.deepClone(model[index]);
    else if (event.tab.textLabel !== AppConstants.RESULTSTABNAME) {
      var oneRingProcess = this.experimentDetails.templateModel.stageDetails[index].processDetails.find(p => p.processTypeId === ProcessTypes.OneRing);
      this.activeProcess = oneRingProcess === undefined || oneRingProcess === null ? this.objectClone.deepClone(this.experimentDetails.templateModel.stageDetails[index].processDetails[0]) : this.objectClone.deepClone(oneRingProcess);

    }
    this.foundInvalidFiles = false;
    if (this.isDataChanged) {
      let dialogConfig = this.experimentService.getDialogConfig();
      let message = `You have unsaved changes in stage: ${this.activeStage.name} and process: ${this.activeProcess.name}. Do you want to save changes?`;
      let headerMessage = "Update Process";
      dialogConfig.data = { message: message, headerMessage: headerMessage };
      this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);

      this.dialogRef.afterClosed().subscribe(
        status => {
          if (status) {
            this.onSaveProcess(this.activeProcess);
          }
          this.isDataChanged = false;
          this.loadResults(event, index, model);
        },
        error => {
          console.log(error);
        },
        () => {
        }
      );
    }
    else {
      this.loadResults(event, index, model);
    }
  }

  onFileSectionTabClick(event, process) {
    if (event.tab.textLabel === AppConstants.FILESTATUSTABNAME) {
      this.onclickRefreshStatus(process.processDetailId);
    } else if (event.tab.textLabel === AppConstants.FILESTABNAME) {
      this.onclickRefreshFiles(process);
    } else if (event.tab.textLabel === AppConstants.StrainRegistration) {
      this.getFileDetailsByExperimentProcessId(process);
    } else  if (event.tab.textLabel === AppConstants.GetStrainInformation) {
      this.loadProcessInfoData(process, AppConstants.GetStrainInformation);
    } else if (event.tab.textLabel === AppConstants.JoinData) {
      this.loadProcessInfoData(process, "");
    }
  }

  loadResults(event, index = -1, isProcess) {
    if (event.tab.textLabel === AppConstants.RESULTSTABNAME) {
      this.getExperimentFileData(this.experimentPlanningId);
    }
    else
      this.loadOneRingDetails(index, isProcess);
  }

  sampleDaysLst: any;
  loadOneRingDetails(index = -1, model = null) {
    if (this.activeProcess !== undefined && this.activeProcess.processTypeId !== null && this.activeProcess.processTypeId !== undefined && this.activeProcess.apiTypeModel !== undefined && this.activeProcess.processTypeId === ProcessTypes.OneRing) {

      this.experimentService.GetOneRingStrainInfo(this.activeProcess.processDetailId)
        .subscribe(
          result => {
            if (result !== undefined && result?.apiTypeModel !== undefined && result?.apiTypeModel.apiTypeDetails !== undefined && this.activeProcess !== undefined && this.activeProcess.apiTypeModel !== undefined) {
              this.activeProcess.apiTypeModel.apiTypeDetails.experimentId = result.apiTypeModel?.apiTypeDetails?.experimentId;
              this.activeProcess.apiTypeModel.apiTypeDetails.groupTypeId = result.apiTypeModel?.apiTypeDetails?.groupTypeId;
              this.activeProcess.apiTypeModel.apiTypeDetails.vesselId = result.apiTypeModel?.apiTypeDetails?.vesselId;
              this.activeProcess.apiTypeModel.apiTypeDetails.analyticalMethods = result.apiTypeModel?.apiTypeDetails?.analyticalMethods;
              this.sampleDaysLst = result.apiTypeModel?.apiTypeDetails?.lstSampleDays;
              this.activeProcess.apiTypeModel.apiTypeDetails.lstSampleDays = result.apiTypeModel?.apiTypeDetails?.lstSampleDays;
              if (result.apiTypeModel?.apiTypeDetails?.dynamicGridResults != undefined && result.apiTypeModel?.apiTypeDetails?.dynamicGridResults != null) {
                this.getStrainGridColumns(result.apiTypeModel?.apiTypeDetails?.dynamicGridResults);
                this.activeProcess.apiTypeModel.apiTypeDetails.dynamicGridResults = [];
                this.activeProcess.apiTypeModel.apiTypeDetails.dynamicGridResults = this.objectClone.deepClone(result.apiTypeModel?.apiTypeDetails?.dynamicGridResults);
                if (index > -1) {
                  let oneRingProcess = undefined;
                  if (model !== null && model.length > 0) {
                    oneRingProcess = model.find(p => p.processTypeId === ProcessTypes.OneRing) !== undefined ? model.find(p => p.processTypeId === ProcessTypes.OneRing) : undefined;
                    this.activeProcess = this.objectClone.deepClone(model[index]);
                  }
                  else
                    oneRingProcess = this.experimentDetails.templateModel.stageDetails[index].processDetails.find(p => p.processTypeId === ProcessTypes.OneRing);
                  if (oneRingProcess !== undefined) {
                    oneRingProcess.apiTypeModel.apiTypeDetails.dynamicGridResults = result.apiTypeModel?.apiTypeDetails?.dynamicGridResults;
                    oneRingProcess.apiTypeModel.apiTypeDetails.experimentId = result.apiTypeModel?.apiTypeDetails?.experimentId;
                    oneRingProcess.apiTypeModel.apiTypeDetails.groupTypeId = result.apiTypeModel?.apiTypeDetails?.groupTypeId;
                    oneRingProcess.apiTypeModel.apiTypeDetails.vesselId = result.apiTypeModel?.apiTypeDetails?.vesselId;
                    oneRingProcess.apiTypeModel.apiTypeDetails.analyticalMethods = result.apiTypeModel?.apiTypeDetails?.analyticalMethods;
                    this.sampleDaysLst = result.apiTypeModel?.apiTypeDetails?.lstSampleDays;
                    oneRingProcess.apiTypeModel.apiTypeDetails.lstSampleDays = result.apiTypeModel?.apiTypeDetails?.lstSampleDays;

                    this.experimentService.GetOneRingDetails(this.activeProcess.apiTypeModel?.apiTypeDetails?.groupTypeId, this.activeProcess.apiTypeModel?.apiTypeDetails?.vesselId, this.activeProcess.apiTypeModel?.apiTypeDetails?.analyticalMethods)
                      .subscribe(
                        result => {
                          this.activeProcess.apiTypeModel.apiTypeDetails.groupTypeName = result.groupName;
                          this.activeProcess.apiTypeModel.apiTypeDetails.analyticalMethodsName = result.methodName;
                          this.activeProcess.apiTypeModel.apiTypeDetails.vesselName = result.vesselName;
                          oneRingProcess.apiTypeModel.apiTypeDetails.groupTypeName = result.groupName;
                          oneRingProcess.apiTypeModel.apiTypeDetails.analyticalMethodsName = result.methodName;
                          oneRingProcess.apiTypeModel.apiTypeDetails.vesselName = result.vesselName;
                        });
                  }
                }
              }
            }
          }
        );
    }
  }

  onSaveProcess(process) {
    this.experimentService.UpdateProcess(process)
      .subscribe(result => {
        this.isDataChanged = false;
        this.toastr.success('Process updated successfully !!');
        this.getExperimentDetails();
      },
        error => {
          this.isDataChanged = false;
          console.log(error);
          this.toastr.error('Process update failed !!');
        });
  }

  makeProcessComplete(process: ProcessDetailModel) {

    let areAllProcessComplete = true;
    this.experimentDetails.templateModel.stageDetails.forEach(element => {
      let val = element.processDetails.filter(x => !x.isComplete);
      if (val !== undefined && val.length > 0) {
        areAllProcessComplete = false;
      }
    });
    this.experimentService.MarkProcessComplete(process, areAllProcessComplete)
      .subscribe(result => {
        this.isDataChanged = false;
        this.toastr.success('Process updated successfully !!');
        this.getExperimentDetails();
      },
        error => {
          this.isDataChanged = false;
          console.log(error);
          this.toastr.error('Process update failed !!');
        });
  }

  isNotOneRingProcess(processTypeId) {
    return processTypeId !== ProcessTypes.OneRing ? true : false;
  }

  isManualUpload(processTypeId) {
    return processTypeId === ProcessTypes.ManualUpload || (processTypeId === ProcessTypes.BioNexus && !(ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env)) ? true : false;
  }

  isFilewatcherUpload(processTypeId, apiTypeId) {
    return (processTypeId == ProcessTypes.FileWatcherUpload || (apiTypeId == this.strainRegistrationApiId && (this.environmentService.currentEnvironment.name.toLowerCase() === AppConstants.Uat_Env 
    || this.environmentService.currentEnvironment.name.toLowerCase() === AppConstants.Prod_Env))) ? true : false;
  }

  initiateFileWatcher(event, process, stageDetailId,stgTabindex,prsTabindex) {
    let dialogConfig = this.experimentService.getDialogConfig();
    let message = "";
    if (this.isValidAnalyst) {
    process.stageDetailId = stageDetailId;

      if ((process.processTypeId === ProcessTypes.FileWatcherUpload || (process.apiTypeId == this.strainRegistrationApiId
        && (this.environmentService.currentEnvironment.name.toLowerCase() === AppConstants.Uat_Env 
        || this.environmentService.currentEnvironment.name.toLowerCase() === AppConstants.Prod_Env)))) 
        {
            if (process.processDetailId != 0) {
              this.experimentService.initiateFileWatcher(process)
              .subscribe(result => {
                this.isDataChanged = false;       
                if (process.processTypeId === ProcessTypes.FileWatcherUpload)
                  this.toastr.info('Currently searching files for processing.');
                this.getExperimentDetails();
              },
                error => {
                  this.isDataChanged = false;
                  console.log(error);
                  this.toastr.error('Initiating file upload failed !!');
                });                   
              this.selectedProcess.setValue(prsTabindex);
              this.selectedStage.setValue(stgTabindex);
            }
          }
    }
    else {
      let headerMessage = "Error";
      let messageType = "Info";

      message = "You are not authorized to upload a file. Contact a Cerebro Admin to be added to an experiment type as an analyst to continue with this process.";
      dialogConfig.data = { message: message, headerMessage: headerMessage, messageType: messageType };
      this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);

    }
  }
  onProcessComplete(event, process, stageDetailId, stgTabindex, prsTabindex) {
    let dialogConfig = this.experimentService.getDialogConfig();
    let message = "";
    if (this.isValidAnalyst) {
      if (event.checked)
        message = `Are you sure you want to mark this ${process.name} process complete?`;
      else
        message = `Are you sure you want to resume the ${process.name} process?`;

      let headerMessage = "Update Process";
      dialogConfig.data = { message: message, headerMessage: headerMessage };
      this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);

      this.dialogRef.afterClosed().subscribe(
        status => {
          if (status) {
            process.stageDetailId = stageDetailId;

            if ((process.processTypeId === ProcessTypes.ManualUpload || (process.processTypeId === ProcessTypes.BioNexus
              && (this.environmentService.currentEnvironment.name.toLowerCase() === AppConstants.Local_Env
                || this.environmentService.currentEnvironment.name.toLowerCase() === AppConstants.Dev_Env
                || this.environmentService.currentEnvironment.name.toLowerCase() === AppConstants.Qa_Env))) && process.isComplete) {
              if (process.processDetailId != 0) {
                this.experimentService.GetFileStatusRecords(process.processDetailId)
                  .subscribe(
                    result => {
                      if (result !== undefined && result.length > 0 && this.rowData.find(x => x.status === 'InQueue' || x.status === 'Processing')) {
                        let headerMessage = AppConstants.MESSAGETYPEWARNING;
                        dialogConfig.data = { message: AppConstants.FILESPROCESSING, headerMessage: headerMessage, messageType: AppConstants.MESSAGETYPEWARNING };
                        this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
                        process.isComplete = !process.isComplete;
                      }
                      else
                        this.isPageRefresh = false;
                      this.makeProcessComplete(process);
                      this.selectedProcess.setValue(prsTabindex);
                      this.selectedStage.setValue(stgTabindex);
                    },
                    error => {
                      console.log(error);
                    })
              }
            }
            else {
              this.isPageRefresh = false;
              this.makeProcessComplete(process);
              this.selectedProcess.setValue(prsTabindex);
              this.selectedStage.setValue(stgTabindex);
            }
          }
          else {
            process.isComplete = !process.isComplete;
          }
        },
        error => {
          console.log(error);
        },
        () => {
        });
    }
    else {
      let headerMessage = "Error";
      let messageType = "Info";

      message = process.isComplete ? "You are not authorized to Complete this step. Please check with your Admin." : "You are not authorized to mark this step In Progress. Please check with you Admin.";
      dialogConfig.data = { message: message, headerMessage: headerMessage, messageType: messageType };
      this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);

      this.dialogRef.afterClosed().subscribe(
        status => {
          if (status) {
            process.isComplete = !process.isComplete;
          }
        },
        error => {
          console.log(error);
        },
        () => {
        });
    }
  }

  populateFileTypes() {

    this.experimentService.GetFileTypes()
      .subscribe(result => {
        this.fileTypes = result.filter(r => r.isActive === true);
        this.fileTypes = this.sortPipe.transform(this.fileTypes, 'asc', 'name');
      },
        error => {
          console.log(error);
        }
      );
  }

  onPageSizeChanged(newPageSize) {
    this.gridApi.paginationSetPageSize(this.paginationPageSize);
  }

  //Executes when user browse the files
  onFileBrowsed(event, processDetailId) {
    if (this.isValidAnalyst) {
      this.browseFiles = event.target.files;
      this.processFiles(this.browseFiles, processDetailId);
    }

  }

  //Executes when user drag and drop the files
  onFilesDroped(files: NgxFileDropEntry[], processDetailId) {
    if (this.isValidAnalyst) {
      this.dropFiles = files;
      this.failedFiles = [];
      this.successFiles = [];
      this.experimentService.DeactivateFileStatusRecords(processDetailId)
        .subscribe(result => {
          if (this.dropFiles.length > 0) {
            var count = 1;
            for (const droppedFile of this.dropFiles) {
              if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                  if (!this.isValidFile(file.name)) {
                    this.foundInvalidFiles = true;
                    this.failedFiles.push(file.name)
                  }
                  else {
                    this.uploadSlectedFile(file, processDetailId);

                    if (count == 1) {
                      this.makeExperimentStatusToInProgress();
                      this.isFilesProcessed = true;
                    }

                    this.fileCount = count;
                    count++;
                  }
                });
              }
            }
          }
          else {
            this.toastr.error('Please select atleast one file !!');
          }
        });
    }
  }

  //It will process and upload the files when user browse the files
  processFiles(files, processDetailId) {
    this.failedFiles = [];
    this.successFiles = [];

    this.experimentService.DeactivateFileStatusRecords(processDetailId)
      .subscribe(result => {
        if (files.length > 0) {
          var count = 1;
          for (const droppedFile of files) {
            if (droppedFile !== undefined && droppedFile.name !== undefined) {
              if (!this.isValidFile(droppedFile.name)) {
                this.failedFiles.push(droppedFile.name)
              }
              else {
                this.uploadSlectedFile(droppedFile, processDetailId);

                if (count == 1) {
                  this.makeExperimentStatusToInProgress();
                  this.isFilesProcessed = true;
                }

                this.fileCount = count;
                count++;
              }
            }
          }
        }
        else {
          this.toastr.error('Please select atleast one file !!');
        }

      });
  }

  makeExperimentStatusToInProgress() {
    if (this.experimentDetails.experimentStatus === AppConstants.NEW) {
      this.experimentDetails.experimentStatus = AppConstants.INPROGRESS;
    }
  }

  //It will validate the file
  isValidFile(name: String) {
    var isValid = false;
    if (name !== undefined) {
      var fileExtension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
      if (this.allowedExtensions.indexOf(fileExtension) !== -1) {
        isValid = true;
        return isValid;
      }
      else {
        return isValid;
      }
    }
    else
      return isValid;
  }

  //It uploads the single file to the server
  uploadSlectedFile(file, processDetailId) {
    const formData = new FormData();
    formData.append(file.name, file);
    formData.append('experimentProcessId', processDetailId);
    this.experimentService.UploadFile(formData)
      .subscribe(
        result => {
          this.successFiles.push(file.name);
        },
        error => {
          this.failedFiles.push(file.name);
        }
      );
  }
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    linkRenderer: LinkRendererComponent
  };

  columnDefs = [
    { headerName: 'File Name', field: 'fileName', sortable: true, filter: true, width: 200, cellRenderer: 'linkRenderer', tooltipValueGetter: (params) => params.value },
    { headerName: 'Version', field: 'runNumber', sortable: true, filter: true, width: 115, tooltipValueGetter: (params) => params.value },
    { headerName: 'File Type', field: 'fileType', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value },
    { headerName: 'Updated By', field: 'uploadedBy', sortable: true, filter: true, width: 160, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Updated Date', field: 'uploadedDate', sortable: true, filter: true, width: 110, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) : '';
      }
    },
    { headerName: 'Active', field: 'isActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 75 },
  ];


  fileStatusDefs = [
    { headerName: 'File Name', field: 'fileName', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value },
    { headerName: 'Status', field: 'status', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value }
  ];

  updateStatus(data, isActive: boolean) {

    if (isActive) {
      this.experimentDetails.templateModel?.stageDetails?.forEach(x => {
        x.processDetails.forEach(y => {

          let files = y.resultFileModel.filter(n => n.fileName == data.fileName && n.fileType === data.fileType);
          if (files.length > 1) {
            let fileIdData = y.resultFileModel?.filter(n => n.fileName == data.fileName && n.isActive == true);
            let dialogConfig = this.experimentService.getDialogConfig();
            let message = "Same file is already active, would you like to disable that file?";

            let headerMessage = "Update File Status";
            dialogConfig.data = { message: message, headerMessage: headerMessage };
            this.dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);

            this.dialogRef.afterClosed().subscribe(
              status => {
                if (fileIdData !== undefined) {
                  this.updateFileStatus(fileIdData[0]?.resultFileId, false)
                }
                this.updateFileStatus(data.resultFileId, true)
              },
              error => {
                console.log(error);
              },
              () => {

              });
          }
          else if (files.length == 1) {
            this.updateFileStatus(data.resultFileId, true)
          }
        });
      });

    }
    else {
      this.updateFileStatus(data.resultFileId, isActive)
    }


  }
  refreshGrid() {
    if (this.gridApi !== undefined)
      this.gridApi.refreshClientSideRowModel('aggregate');
    this.clearSortandFilter();
    this.onCloseEvent();
  }

  clearSortandFilter() {
    if (this.gridColumnApi !== undefined) {
      this.gridColumnApi.applyColumnState({
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
    }
    if (this.gridApi !== undefined) {
      this.gridApi.setFilterModel(null);
      this.gridApi.sizeColumnsToFit();
    }
  }


  refreshGetBNXInfoGrid() {
    if (this.getDataGridApi !== undefined)
      this.getDataGridApi.refreshClientSideRowModel('aggregate');
    this.clearSortandFilterGetBNXInfoGrid();
    this.onCloseEvent();
  }

  clearSortandFilterGetBNXInfoGrid() {
    if (this.getDataGridColumnApi !== undefined) {
      this.getDataGridColumnApi.applyColumnState({
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
    }
    if (this.getDataGridApi !== undefined) {
      this.getDataGridApi.setFilterModel(null);
      this.getDataGridApi.sizeColumnsToFit();
    }
  }

  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
  updateFileStatus(resultFileId, isActive) {
    this.experimentService.UpdateFileStatusById(resultFileId, isActive)
      .subscribe(
        result => {
          this.getExperimentDetails();
          this.toastr.success('File status updated successfully !!');

        },
        error => {
          console.log(error);
          this.toastr.error('Failed to update the file status!!');
        }
      );
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");

    this.paginationPageSize = 10;
    this.paginationNumberFormatter = function (params) {
      return "[" + params.value.toLocaleString() + "]";
    };

  }

  onRegisterGridReady(params) {
    this.registerGridApi = params.api;
    this.registerGridColumnApi = params.columnApi;
    this.registerGridApi.setDomLayout("autoHeight");
    this.paginationPageSize = 10;
    this.paginationNumberFormatter = function (params) {
      return "[" + params.value.toLocaleString() + "]";
    };

  }

  onGetDataGridReady(params) {
    this.getDataGridApi = params.api;
    this.getDataGridColumnApi = params.columnApi;
    this.getDataGridApi.setDomLayout("autoHeight");
    this.paginationPageSize = 10;
    this.getDataGridApi.paginationSetPageSize(this.paginationPageSize);
    this.paginationNumberFormatter = function (params) {
      return "[" + params.value.toLocaleString() + "]";
    };
    this.onGetDataGridFilterChanged(params);

  }

  onStrainInfoExport = (processName) => {
    const params = this.getStrainInfoExportParams(processName);
    this.gridApi.exportDataAsCsv(params);
  };

  getStrainInfoExportParams = (processName) => {
    return {
      fileName: this.experimentDetails.experimentId + "_" + processName + "_" + (new Date()).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    };
  }


  // hold the `MutationObserver` to be disconnected when component is destroyed
  private mutationObserver: MutationObserver;

  onResultsGridReady(params) {
    this.gridApiResults = params.api;
    this.gridColumnApiResults = params.columnApi;
    this.gridApiResults.setDomLayout("autoHeight");

    this.paginationPageSize = 10;
    this.paginationNumberFormatter = function (params) {
      return "[" + params.value.toLocaleString() + "]";
    };

    // css class selectors
    const headerSelector = '.ag-header';
    const scrollSelector = '.ag-body-horizontal-scroll';
    const scrollViewportSelector = '.ag-body-horizontal-scroll-viewport';
    const scrollContainerSelector = '.ag-body-horizontal-scroll-container';

    // get scrollbar elements
    const scrollElement = document.querySelector(scrollSelector);
    const scrollViewportElement = document.querySelector(scrollViewportSelector);
    const scrollContainerElement = document.querySelector(scrollContainerSelector);

    // create scrollbar clones
    const cloneElement = scrollElement.cloneNode(true) as Element;
    const cloneViewportElement = cloneElement.querySelector(scrollViewportSelector);
    const cloneContainerElement = cloneElement.querySelector(scrollContainerSelector);

    // insert scrollbar clone
    const headerElement = document.querySelector(headerSelector);
    headerElement.insertAdjacentElement('afterend', cloneElement);

    // add event listeners to keep scroll position synchronized
    scrollViewportElement.addEventListener('scroll', () => cloneViewportElement.scrollTo({ left: scrollViewportElement.scrollLeft }));
    cloneViewportElement.addEventListener('scroll', () => scrollViewportElement.scrollTo({ left: cloneViewportElement.scrollLeft }));

    // create a mutation observer to keep scroll size synchronized
    this.mutationObserver = new MutationObserver(mutationList => {
      for (const mutation of mutationList) {
        switch (mutation.target) {
          case scrollElement:
            cloneElement.setAttribute('style', scrollElement.getAttribute('style'));
            break;
          case scrollViewportElement:
            cloneViewportElement.setAttribute('style', scrollViewportElement.getAttribute('style'));
            break;
          case scrollContainerElement:
            cloneContainerElement.setAttribute('style', scrollContainerElement.getAttribute('style'));
            break;
        }
      }
    });

    // start observing the scroll elements for `style` attribute changes
    this.mutationObserver.observe(scrollElement, { attributeFilter: ['style'], subtree: true });
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  cancelStatusUpdate() {
    this.experimentService.cancelStatusUpdate();
  }

  ngOnDestroy() {
    // stop observing
    if (this.mutationObserver !== undefined)
      this.mutationObserver.disconnect();


    try {
      if (this.gridApi) {
        this.gridApi.destroy();
        this.gridApi = false;
      }

      if (this.gridApiResults) {
        this.gridApiResults.destroy();
        this.gridApiResults = false;
      }

    } catch (error) {
      console.log(error);

    }
  }

  defaultColDef: {
    flex: 1,
    resizable: true,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true
  }

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivotMode: true
        }
      },
      {
        id: 'filters',
        labelKey: 'filters',
        labelDefault: 'Filters',
        iconKey: 'menu',
        toolPanel: 'agFiltersToolPanel',
      }
    ],
    defaultToolPanel: 'columns',
  };

  generateColumns(data: any[]) {
    let columnDefinitions = [];

    data.map(object => {

      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key,
            field: key,
            sortable: true,
            filterParams: { inRangeInclusive: true },
            filter: true,
            resizable: true,
            tooltipValueGetter: (params) => params.value
          }

          columnDefinitions.push(mappedColumn);
        })
    })
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }


  dtlcolumnDefs: any;
  getFileDetailsById(fileId) {
    this.rowData = [];
    this.experimentService.GetFileDetailsById(fileId)
      .subscribe(
        result => {

          this.rowData = [];
          this.rowData = result;
          if (this.rowData != undefined && this.rowData.length > 0) {
            this.dtlcolumnDefs = this.generateColumns(this.rowData);
            this.isFileDetailsVisible = true;
          }
          else {
            this.dtlcolumnDefs = [];
            this.isFileDetailsVisible = false;
          }
        });
  }
  resultsColumnDefs: any;
  getExperimentFileData(id) {
    this.resultsRowData = [];
    this.experimentService.GetExperimentFileData(id)
      .subscribe(
        result => {
          this.resultsRowData = result != null ? result : [];
          if (this.resultsRowData != undefined && this.resultsRowData.length > 0) {
            this.resultsColumnDefs = this.generateColumns(this.resultsRowData);
            this.isFileDetailsVisible = true;

            this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDRESULTSGRIDFILTER);
          }
          else {
            this.resultsColumnDefs = [];
          }
        });
  }

  strainInfoData: Array<any> = [];
  strainInfoColumnDefs: any = [];;
  getStrainGridColumns(strainInfo) {
    this.strainInfoData = [];
    this.strainInfoData = strainInfo != null ? strainInfo : [];
    if (this.strainInfoData != undefined && this.strainInfoData.length > 0) {
      this.strainInfoColumnDefs = this.generateColumns(this.strainInfoData);
      this.refreshGrid(); this.refreshGetBNXInfoGrid();
      this.gridApi.paginationSetPageSize(this.paginationPageSize);
    }
    else {
      this.strainInfoColumnDefs = [];
    }
  }
  selectedSampleDays: any;
  submitToOneRing(apiDetailsInfo) {

    if (apiDetailsInfo?.dynamicGridResults != null) {
      if (this.filteredGridRows !== undefined && this.filteredGridRows.length > 0)
        this.activeProcess.apiTypeModel.apiTypeDetails.dynamicGridResults = this.objectClone.deepClone(this.filteredGridRows);
      this.activeProcess.apiTypeModel.apiTypeDetails.experimentId = apiDetailsInfo.experimentId;
      this.experimentService.ValidateOneRingStrainInfo(this.activeProcess.apiTypeModel.apiTypeDetails).subscribe(result => {
        if ((result.MissingHeader != null && result.MissingHeader != '') || result.MissingData) {
          let dialogConfig = this.experimentService.getDialogConfig();
          let message = '';

          if (result.MissingHeader != null && result.MissingHeader != '')
            message = "Required header(s): " + result.MissingHeader + "  is missing for sample submission. \n"
          if (result.MissingData)
            message = message + "Required data for column(s) is missing for sample submission."

          let headerMessage = "Error";
          let messageType = "Info"
          dialogConfig.data = { message: message, headerMessage: headerMessage, messageType: messageType };
          this.dialog.open(StatusDialogComponent, dialogConfig);
          return;
        }
        this.activeProcess.apiTypeModel.apiTypeDetails.lstSampleDays = this.selectedSampleDays;
        this.experimentService.SubmitToOneRing(this.activeProcess.apiTypeModel.apiTypeDetails).subscribe(
          results => {
            if (results.OneRingSubErr != null && results.OneRingSubErr != '') {
              this.toastr.error(results.OneRingSubErr);
              return;
            }
            this.toastr.success('Sample information successfully submitted to One Ring !!');
          },
          error => {
            this.toastr.error('Failed to submit strain info to One-Ring.');
          });
      });
    }
  }

  isOneRingSubmitbtnVisible: boolean = false;
  sampleDaySelection(selectedval) {
    this.selectedSampleDays = selectedval;
    this.isOneRingSubmitbtnVisible = false;
    if (selectedval.length != 0) {
      this.isOneRingSubmitbtnVisible = true;
    }
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1 === f2;
  }

  onclickFileMapping(fileMappingId) {
    this.getFileMappingById(fileMappingId)
  }

  onclickRefreshStatus(experimentProcessId) {
    if (experimentProcessId != 0) {
      this.experimentService.GetFileStatusRecords(experimentProcessId)
        .subscribe(
          result => {
            this.rowData = result;
          },
          error => {
            console.log(error);
          })
    }
  }

  onclickRefreshFiles(process) {
    if (process.processDetailId != 0 && process.fileMappingId != 0) {
      this.experimentService.GetResultFiles(process.processDetailId, process.fileMappingId)
        .subscribe(
          result => {
            process.resultFileModel = [];
            process.resultFileModel = result;

            if (this.experimentDetails?.experimentStatus == 'Completed' || this.isRestrictedRole|| (this.isAnalystRole && this.experimentDetails.analyst.analystName.trim() != this.loggedInUserName.trim())) {
              process.resultFileModel?.forEach(x => x.isControlDisabled = true)
            }

          },
          error => {
            console.log(error);
          })
    }
  }


  onclickClearFiles(experimentProcessId) {
    if (experimentProcessId != 0) {
      this.experimentService.DeactivateFileStatusRecords(experimentProcessId)
        .subscribe(
          result => {
            this.rowData = result;
          },
          error => {
            console.log(error);
          })
    }
  }

  getDelimeters() {
    this.fileMappingService.getDelimeters().subscribe(
      result => {
        this.delimeters = result;

      },
      error => {
        console.log(error);
      }
    );
  }
  getFriendlyHeaders() {
    this.fileMappingService.getFrindlyHeaders().subscribe(
      result => {
        this.friendlyHeaders = result;
      },
      error => {
        console.log(error);
      }
    );
  }
  getFileMappingById(fileMappingId: number) {
    if (fileMappingId != 0) {
      this.fileMappingService.getFileMappingById(fileMappingId)
        .subscribe(
          result => {
            this.fileMapping = result;
            this.fileMapping.mappingRules.forEach(element => {
              element.userFriendlyHeaders = this.friendlyHeaders;
              /* element.subMappingRules.forEach(element => {
                element.delimeters = this.delimeters;
                element.userFriendlyHeaders = this.friendlyHeaders;
              }); */
            });

            let dialogConfig = this.fileMappingService.getDialogConfig();
            dialogConfig.panelClass = "dialog-responsive";
            dialogConfig.data = { selectedData: this.fileMapping, selectedGridData: this.rowData, fromRunSheet: true };
            this.dialog.open(CreateFileMappingPopupComponent, dialogConfig);
          },
          error => {
            console.log(error);
          })
    }
  }

  onExport = () => {
    const params = this.getParams();
    this.gridApiResults.exportDataAsCsv(params);
  };

  getParams = () => {
    return {
      fileName: this.experimentDetails.experimentId
    };
  }

  onFirstDataRender(params) {

    params.api.setFilterModel(this.savedFilter);

    if (this.gridApiResults !== undefined)
      this.gridApiResults.setFilterModel(this.savedFilter);
    this.restoreState();
  }

  clearResultsSortandFilter() {
    this.gridApiResults.setFilterModel(null);
    this.commonService.setSessionStorageValue(AppConstants.SAVEDRESULTSGRIDFILTER, JSON.stringify(this.gridApiResults.getFilterModel()));
    this.gridApiResults.setFilterModel(this.savedFilter);
  }

  saveState() {
    this.commonService.setSessionStorageValue(AppConstants.SAVEDRESULTGRIDCOLUMNS, JSON.stringify(this.gridColumnApiResults.getColumnState()));
    this.toastr.success('Saved columns successfully !!');
  }

  restoreState() {
    let state = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDRESULTGRIDCOLUMNS);
    if (state === undefined && state === null) {
      console.log('no columns state to restore by, you must save state first');
      return;
    }
    if (this.gridColumnApiResults !== undefined) {
      this.gridColumnApiResults.applyColumnState({
        state: state,
        applyOrder: true,
      });
    }
  }

  resetState() {
    this.gridColumnApiResults.resetColumnState();
  }

  filteredGridRows: any[] = [];
  onFilterChanged(params) {
    if (this.gridApi !== undefined) {
      this.filteredGridRows = [];
      this.gridApi.forEachNodeAfterFilter(node => {
        this.filteredGridRows.push(node.data);
      });
    }
    if (this.gridApiResults !== undefined) {
      this.savedFilter = this.gridApiResults.getFilterModel();
      this.commonService.setSessionStorageValue(AppConstants.SAVEDRESULTSGRIDFILTER, JSON.stringify(this.savedFilter));
    }
  }

  onCopy() {
    this.gridApiResults.copyToClipboard(true);
  }

  loadOperationTypes() {
    this.service.GetOperationTypes().subscribe(
      result => {
        this.operationTypes = result;
        this.strainRegistrationApiId = result.find(x => x.name === AppConstants.StrainRegistration).apiTypeId;
        this.getStrainInfoApiId = result.find(x => x.name === AppConstants.GetStrainInfo).apiTypeId;
        this.joinDataApiId = result.find(x => x.name === AppConstants.JoinData).apiTypeId;
      },
      error => {
        console.log(error);
      }
    );
  }

  onValidateRegistration(experimentProcessId) {
    let dataToRegister = this.getDataToRigisterWithBionexus();
    this.experimentService.validateDataForRegisterToBionexus(experimentProcessId, dataToRegister).subscribe(
      results => {
        if (results.Status == "Success") {
          this.isBNXDataValidatedForRegister = true;
          this.toastr.success('Validated strains to register with Bionexus successfully !!');
        }
        else if (results.Status == "MissingOtionalFields") {
          let optionalFields = "";
          results.Errors.forEach(optField => {
            optionalFields = optionalFields.length > 0 ? optionalFields + ", " + optField.ErrorMessage : optField.ErrorMessage;
          });
          let optFieldsMessage = "Optional headers " + optionalFields + " are missing for this submission. You may proceed with Registration."
          this.isBNXDataValidatedForRegister = true;
          let dialogConfig = this.service.getDialogConfig();
          dialogConfig.data = { message: optFieldsMessage, headerMessage: "Optional Headers", messageType: "Info" };
          this.dialog.open(StatusDialogComponent, dialogConfig);
        }
        else if (results.Status == "BNXError") {
          this.isBNXDataValidatedForRegister = false;
          this.toastr.error(results.Errors[0].ErrorMessage);
        }
        else if (results.Status == "ValidationError") {
          this.isBNXDataValidatedForRegister = false;
          let dialogConfig = this.service.getDialogConfig();
          dialogConfig.data = { selectedData: results.Errors };
          this.dialog.open(ValidationErrorsPopupComponent, dialogConfig);
        }
        else if (results.Status == "ActiveSubmissionExists") {
          this.isBNXDataValidatedForRegister = false;
          let dialogConfig = this.service.getDialogConfig();
          dialogConfig.data = { message: "There is an active registration in progress. Please wait for the registration to complete and click on Refresh to retrieve the barcodes.", headerMessage: "Active Registration", messageType: "Info" };
          this.dialog.open(StatusDialogComponent, dialogConfig);
        }
        else if (results.Status == "AlreadyRegistered") {
          this.isBNXDataValidatedForRegister = false;
          let dialogConfig = this.service.getDialogConfig();
          dialogConfig.data = { message: "One or more of the Strains you are trying to submit have already been registered.  Please check for the Bionexus Barcodes and filter any records that have already been registered.", headerMessage: "Registered Strain", messageType: "Info" };
          this.dialog.open(StatusDialogComponent, dialogConfig);
        }

      },
      error => {
        this.isBNXDataValidatedForRegister = false;
        this.toastr.error('Failed to validate strains to register with Bionexus.');
      });
  }

  onRegistration(experimentProcessId) {
    this.isBNXDataValidatedForRegister = false;
    let dataToRegister = this.getDataToRigisterWithBionexus();
    this.experimentService.registerToBionexus(experimentProcessId, dataToRegister).subscribe(
      results => {
        let message = results.toLocaleString();
        if (message.includes("successfully")) {
          this.toastr.success('Registered strains with Bionexus successfully !!');
        }
        else {
          this.toastr.error(results);
        }

      },
      error => {
        this.toastr.error('Failed to register strains with Bionexus.');
      });


  }

  getDataToRigisterWithBionexus() {
    if (this.filteredRegisterGridRows !== undefined && this.filteredRegisterGridRows.length > 0) {
      return this.objectClone.deepClone(this.filteredRegisterGridRows);
    }
    else {
      return this.registerData;
    }
  }

  onJoinData(process) {
    this.isJoinDisabled = true;
    let dataToJoin = this.getFilteredGridData();
    this.experimentService.ValidateDataJoinGridHeadersInfo(dataToJoin, this.activeProcess.fileMappingId).subscribe(result => {
      if ((result.MissingHeader != null && result.MissingHeader != '') || result.MissingData) {
        let dialogConfig = this.experimentService.getDialogConfig();
        let message = '';

        if (result.MissingHeader != null && result.MissingHeader != '')
          message = "Required header(s): " + result.MissingHeader + "  is missing for data to join. \n"
        if (result.MissingData)
          message = message + "Some records have missing data in the columns you are trying to join. Please ensure all required fields have data before proceeding."

        let headerMessage = "Error";
        let messageType = "Info"
        dialogConfig.data = { message: message, headerMessage: headerMessage, messageType: messageType };
        this.dialog.open(StatusDialogComponent, dialogConfig);
        return;
      }
      this.experimentService.joinColumns(this.experimentDetails.experimentId, process.processDetailId, dataToJoin).subscribe(
        results => {
          process.apiTypeModel.apiTypeDetails.dynamicGridResults = Object.assign([], results);
          if (results !== undefined && results !== null) {
            this.toastr.success('Columns joined successfully !!');

          }
          else {
            this.toastr.error('Failed to join columns.');
          }

        },
        error => {
          this.toastr.error('Failed to join columns.');
        });
    });
  }

  onGetStrainInformation(process) {
    let filteredData = this.getFilteredGridData();
    this.experimentService.getDataFromBionexus(this.experimentDetails.experimentId, process.processDetailId, filteredData).subscribe(
      results => {
        results.forEach(element => {
          let indexToUpdate = this.getDynamicGridData.findIndex(x => x['Strain Barcode ID'] === element['Strain Barcode ID']);
          this.getDynamicGridData[indexToUpdate] = element;
        });
        process.apiTypeModel.apiTypeDetails.dynamicGridResults = Object.assign([], this.getDynamicGridData);

        if (this.getDataGridApi !== undefined)
          this.getDataGridApi.refreshClientSideRowModel('aggregate');
      },
      error => {
        this.toastr.error('Failed to get strain information from Bionexus.');
      });
  }

  getFilteredGridData() {
    if (this.filteredGetDataGridRows !== undefined && this.filteredGetDataGridRows.length > 0) {
      return this.objectClone.deepClone(this.filteredGetDataGridRows);
    }
    else {
      return this.getDynamicGridData;
    }
  }

  onRefreshBNXData(processDetailId) {
    this.experimentService.GetFileDetailsByExperimentProcessId(processDetailId)
      .subscribe(
        result => {

          if (result === null || result === undefined)
            this.toastr.info("No more barcodes to pull.");
          else {
            this.registerData = [];
            this.registerData = result;
          }
        });
  }

  onRefreshJoinData(process) {  

    this.getDataGridApi.setFilterModel(null);
    this.onGetDataGridFilterChanged(process);
    this.filteredGetDataGridRows = [];
    this.loadProcessInfoData(process, "");
    this.isJoinDisabled = false;
  }

  loadBionexusEntities() {
    this.service.GetBionexusEntities('Entity Type Name').subscribe(
      result => {
        this.bionexusEntities = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadBionexusProjects() {
    this.service.GetBionexusEntities('Project').subscribe(
      result => {
        this.bionexusProjects = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  bionexusBundles: Bundle[];
  loadBionexusBundles() {
    this.service.GetBionexusBundles().subscribe(
      result => {
        this.bionexusBundles = result.filter(x =>  x.bundleType != undefined &&  x.bundleType != null &&  x.bundleType.toLowerCase() == "workflow");
      },
      error => {
        console.log(error);
      }
    );
  }

  getEntityName(entityTypeId) {
    let entity = this.bionexusEntities.find(x => x.listEntryId == entityTypeId)
    this.entityName = entity != undefined ? entity.value : "";
  }

  getProjectNames(projectIds) {
    let projNames = "";
    projectIds.forEach(projectId => {
      let project = this.bionexusProjects.find(x => x.listEntryId == projectId)
      projNames = project != undefined ? projNames.length > 0 ? projNames + ", " + project.value : project.value : projNames;
    });

    this.projectNames = projNames;
  }

  getBundleNames(bundles) {
    let bndlNames = "";
    bundles.forEach(bundleName => {
      let bundle = this.bionexusBundles.find(x => x.name == bundleName)
      bndlNames = bundle != undefined ? bndlNames.length > 0 ? bndlNames + ", " + bundle.name : bundle.name : bndlNames;
    });

    this.bundleNames = bndlNames;
  }
  

  getFileDetailsByExperimentProcessId(process) {
    this.isBNXDataValidatedForRegister = false;
    this.experimentService.GetFileDetailsByExperimentProcessId(process.processDetailId)
      .subscribe(
        result => {
          this.registerData = [];
          this.registerData = result;
          if (this.registerData != undefined && this.registerData.length > 0) {
            this.registerColumnDefs = this.generateColumns(this.registerData);
            this.getEntityName(process.bNXEntityTypeId);
            this.getProjectNames(process.bNXProjectId);            
            this.getBundleNames(process.bNXBundle);
          }
          else {
            this.registerColumnDefs = [];
          }
        });
  }
  
  loadProcessInfoData(process, callingFor) {
    this.experimentService.GetOneRingStrainInfo(process.processDetailId)
        .subscribe(
          result => {
            if (result !== undefined && result?.apiTypeModel !== undefined && result?.apiTypeModel.apiTypeDetails !== undefined) {
              if (result.apiTypeModel?.apiTypeDetails?.dynamicGridResults != undefined && result.apiTypeModel?.apiTypeDetails?.dynamicGridResults != null) {
                this.getStrainGridColumns(result.apiTypeModel?.apiTypeDetails?.dynamicGridResults);
                this.activeProcess = process;
                this.activeProcess.apiTypeModel.apiTypeDetails.dynamicGridResults = [];
                this.activeProcess.apiTypeModel.apiTypeDetails.dynamicGridResults = this.objectClone.deepClone(result.apiTypeModel?.apiTypeDetails?.dynamicGridResults);
                if(callingFor == AppConstants.GetStrainInformation && result.apiTypeModel?.apiTypeDetails?.dynamicGridResults.length == 1 && (result.apiTypeModel?.apiTypeDetails?.dynamicGridResults[0]['Strain Barcode ID'] == null || result.apiTypeModel?.apiTypeDetails?.dynamicGridResults[0]['Strain Barcode ID'] != '')) {
                  process.apiTypeModel.apiTypeDetails.dynamicGridResults = [];
                  this.getDynamicGridData = [];
                }
                else if(callingFor == AppConstants.GetStrainInformation && result.apiTypeModel?.apiTypeDetails?.dynamicGridResults.length > 1) {
                  process.apiTypeModel.apiTypeDetails.dynamicGridResults = result.apiTypeModel?.apiTypeDetails?.dynamicGridResults.filter(x=>x['Strain Barcode ID'] != null && x['Strain Barcode ID'] != '');
                  this.getDynamicGridData = result.apiTypeModel?.apiTypeDetails?.dynamicGridResults.filter(x=>x['Strain Barcode ID'] != null && x['Strain Barcode ID'] != '');
                }
                else {
                  process.apiTypeModel.apiTypeDetails.dynamicGridResults = result.apiTypeModel?.apiTypeDetails?.dynamicGridResults;
                  this.getDynamicGridData = result.apiTypeModel?.apiTypeDetails?.dynamicGridResults;
                }
              }
              this.disableOrEnableJoinButton(process)
            }
          }
      );
  }
  
  onPageSizeChangedRegisterGrid(newPageSize) {
    this.gridApi.paginationSetPageSize(this.registerGridPageSize);
  }

  filteredRegisterGridRows: any[] = [];
  onRegisterFilterChanged(params) {
    this.isBNXDataValidatedForRegister = false;
    if (this.registerGridApi !== undefined) {
      this.filteredRegisterGridRows = [];
      this.registerGridApi.forEachNodeAfterFilter(node => {
        this.filteredRegisterGridRows.push(node.data);
      });
    }
  }

  filteredGetDataGridRows: any[] = [];
  onGetDataGridFilterChanged(params) {
    if (this.getDataGridApi !== undefined) {
      this.filteredGetDataGridRows = [];
      this.getDataGridApi.forEachNodeAfterFilter(node => {
        this.filteredGetDataGridRows.push(node.data);
      });
      this.disableOrEnableJoinButton(this.filteredGetDataGridRows);
    }
  }

  disableOrEnableJoinButton(grdData) {
    this.isJoinDisabled = false;
    if (grdData?.length > 0) {
      const isNullRowNotFound = grdData.map(function (ob) {
        return Object.keys(ob).some(function (k) {
          return ob[k] == null;
        });
      }).find(x => x == true);     
      if (grdData?.length > 0 && (isNullRowNotFound == undefined || !isNullRowNotFound)) {
        this.isJoinDisabled = true;
      }
    }
  }
}
