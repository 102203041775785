import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from '../loading.service';
import { ProjectModel,WebClientAPI} from '../webclient-api';

@Injectable({
    providedIn: 'root'
  })
export class ProjectService {
  private messageSource;
  // currentMessage = this.messageSource.asObservable();
  
    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json' 
        })
    };

    constructor(private webClientAPI: WebClientAPI, private http: HttpClient,private loadingService: LoadingService) { }

       

    public addProject(model: ProjectModel):  Observable<any> {
      this.loadingService.setMessage('Loading...');
      this.messageSource = new BehaviorSubject(model);
      return this.webClientAPI.project_SaveProject(model)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public cancelStatusUpdate() {
      this.messageSource = new BehaviorSubject('cancel');
    }
    
    public getProjects(isFromSecurityGroup): Observable<any> {
      this.loadingService.setMessage('Loading...');
      return this.webClientAPI.project_GetProjects(isFromSecurityGroup)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }
    public getProjectsForAdminUser(): Observable<any> {
      this.loadingService.setMessage('Loading...');
      return this.webClientAPI.project_GetProjectsForAdminUser()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public GetProjectsForAnalystAndObserverUser(): Observable<any> {
      this.loadingService.setMessage('Loading...');
      return this.webClientAPI.project_GetProjectsForAnalystAndObserverUser()
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }


    public checkProjectGroupUsedInAdmin(Id: number,projectGroups:number[]): Observable<any> {
      this.loadingService.setMessage('Loading...');
      return this.webClientAPI.project_CheckProjectGroupUsedInAdmin(Id,projectGroups)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }


    public getProjectById(Id: number): Observable<any> {
      this.loadingService.setMessage('Loading...');
      return this.webClientAPI.project_GetProjectById(Id)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }


    public updateProject(model: ProjectModel):  Observable<any> {
      this.loadingService.setMessage('Updating Project...');
      this.messageSource = new BehaviorSubject(model);
      return this.webClientAPI.project_UpdateProject(model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public updateProjectStatus(Id, isActive): Observable<any> {
      this.loadingService.setMessage('Deleting Templates...');
      this.messageSource = new BehaviorSubject(Id);
      return this.webClientAPI.project_UpdateProjectStatus(Id, isActive)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

  
      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }

      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "23%";
        return dialogConfig;

      }
}