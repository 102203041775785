import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';
@Component({
  selector: 'app-delete-button-renderer',
  template: `
    <button mat-button type="button"  (click)="onClick($event)"><mat-icon>delete</mat-icon></button>
    `
})

export class DeleteButtonRendererComponent implements ICellRendererAngularComp {
    public params: ICellRendererParams;
   
    constructor(private dialog: MatDialog) { }
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
    
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {     
    let dialogConfig = this.getDialogConfig();   
    let message="Are you sure you want to delete the" + " " + this.params.data.fileName + "?"     
        dialogConfig.data = { isActive: $event.checked,message:message, componentName: this.params.data.fileName};
        let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
        status => {
            if (status) {
                this.params.context.componentParent.onClick(this.params.data.resultFileId)
            }     
            else {        
                this.params.context.componentParent.cancelStatusUpdate();       
                
            }
        },
        error =>{
            console.log(error);
        },
        () =>{
            this.params.context.componentParent.refreshGrid(AppConstants.UPDATESTATUS);
        }
        );
  }
  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    return dialogConfig;

  }
}