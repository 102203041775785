import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from '../../custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from '../../custom/edit-button-renderer/edit-button-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ProjectService } from 'src/app/shared/services/project-service/project.service'
import { CreateProjectComponent } from './project-popup/create-project.component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/shared/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { ProjectModel } from 'src/app/shared/services/webclient-api';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  templateUrl: '../../shared/grid-component/grid.component.html',
  styleUrls: ['./project.component.css']
})

export class ProjectComponent implements OnInit, OnDestroy {
  request: FormGroup;
  rowData: any[] = [];
  private gridApi;
  private gridColumnApi;
  public savedFilter: any;
  public savedSort: any;
  public context: any;
  public subscription: any;

  componentName = AppConstants.PROJECTTITLE;
  componentTitle = AppConstants.PROJECTTITLE;
  project: ProjectModel;
  constructor(private fb: FormBuilder, private dialog: MatDialog,
    private service: ProjectService,    
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private commonService: CommonService,
    private _messageService: MessageService) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    })
  }

  ngOnInit() {
 
    this.getProjectInfo();
  }

  getProjectInfo() {
    this.service.getProjects(false)
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => { 
           
            this.rowData.push({
              projectId: r.projectId,
              name: r.name,             
              description: r.description,
              isActive: r.isActive,
              createdDate: r.createdDate,
              createdBy: r.createdBy,
              modifiedDate: r.modifiedDate,
              modifiedBy: r.modifiedBy,
              groups:r.projectGroupsModel

            })
          })
          
          this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDPROJECTFILTER);
          this.savedSort = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDPROJECTSORT);
        },
        error => {
          console.log(error);
        }
      )
  }

  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }

  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  columnDefs = [
    { headerName: 'Name', field: 'name', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },  
    { headerName: 'Description', field: 'description', sortable: true, filter: true, width: 350, tooltipValueGetter: (params) => params.value },
    { headerName: 'Modified By', field: 'modifiedBy', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Modified Date', field: 'modifiedDate', sortable: true, filter: true, width: 200, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) : '';
      }
    },
    { headerName: 'Active', field: 'isActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 150 },
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  updateStatus(projectModel: ProjectModel, isActive: boolean) {
    this.service.updateProjectStatus(projectModel.projectId, isActive)
      .subscribe(result => {
        this.toastr.success('Project updated successfully !!');
        this.getProjectInfo();
      },
        error => {
          this.toastr.error(error, 'Project update failed !!');
        })
  }

  add() {
    this.project = new ProjectModel({
      projectId: 0,
      name: "",
      description: "",
      isActive: true,
      createdDate: new Date(),
      createdBy: "",
      modifiedDate: new Date(),
      modifiedBy: "",
      projectGroupsModel:[]
    });
    this.showPopup();
  }

  update(row) {
    if (row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      this.project = row.data;
      this.showPopup();
    }
  }

  showPopup() {
    let dialogConfig = this.service.getDialogConfig();
    dialogConfig.data = { selectedData: this.project, selectedGridData: this.rowData };
    this.dialog.open(CreateProjectComponent, dialogConfig);
  }
  
  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  refreshGrid() {
    this.getProjectInfo();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.onCloseEvent();
  }
  refreshGridFilters() {
    //this. getApiTypes();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.clearSortandFilter();
    this.onCloseEvent();
  }
  clearSortandFilter() {
    this.gridColumnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
    this.gridApi.setFilterModel(null);    
    this.gridApi.sizeColumnsToFit();
  }
  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onSortChanged(params) {
    this.savedSort = this.gridColumnApi.getColumnState();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDPROJECTSORT, JSON.stringify(this.savedSort));
  }

  onFilterChanged(params) {
    this.savedFilter = this.gridApi.getFilterModel();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDPROJECTFILTER, JSON.stringify(this.savedFilter));
  }

  onFirstDataRender(params) {

    if (this.savedSort != undefined && this.savedSort != null && this.savedSort.length != 0) {
      this.gridColumnApi.applyColumnState({
        state: this.savedSort,
        applyOrder: true,
      });
    }


    this.gridApi.setFilterModel(this.savedFilter);
  }
  
  onColumnMoved(event) {
    this.gridApi.sizeColumnsToFit();
  }

  ngOnDestroy() {
    try { 
        if (this.gridApi) {
            this.gridApi.destroy();
            this.gridApi = false;
        }

    } catch (error) {
      console.log(error);

    }
}
}