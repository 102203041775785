import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from 'src/app/shared/services/loading.service';

import { SecurityGroupModel, WebClientAPI } from './webclient-api';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { catchError, map, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SecurityGroupService {
  private messageSource;

  httpOptions = {
    headers: new HttpHeaders({
      'enctype': 'multipart/form-data'
    })
  };


  constructor(private webClientAPI: WebClientAPI, private loadingService: LoadingService) { }

  public GetSecurityGroups(): Observable<any> {
    this.loadingService.setMessage('Loading...');
    return this.webClientAPI.securityGroup_GetSecurityGroups()
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public GetSecurityGroupById(data): Observable<any> {
    this.loadingService.setMessage('Loading...');
    return this.webClientAPI.securityGroup_GetSecurityGroupById(data)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public GetSecurityGroupByUserId(data): Observable<any> {
    this.loadingService.setMessage('Loading...');
    return this.webClientAPI.securityGroup_GetSecurityGroupsByUserId(data)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public GetUsersByProjectGroup(projectId, groupId):Observable<any> {
    this.loadingService.setMessage('Loading...');
    return this.webClientAPI.securityGroup_GetUsersByProjectGroup(projectId, groupId)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }


  public getADRoles(): Observable<any> {
    this.loadingService.setMessage('Loading...');
    return this.webClientAPI.securityGroup_GetADRoles()
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }

  public InsertSecurityGroup(model: SecurityGroupModel) {
    this.loadingService.setMessage('Saving Security Group...');
    this.messageSource = new BehaviorSubject(model);
    return this.webClientAPI.securityGroup_InsertSecurityGroup(model)
      .map(result => result)
      .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
  }  

  public cancelStatusUpdate() {
    this.messageSource = new BehaviorSubject('cancel');
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    return dialogConfig;

  }
}