import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationSettingsService } from '../application-settings.service';

@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss']
})
export class PostLoginComponent implements OnInit {

  constructor(private router: Router,
    private appSettingsService: ApplicationSettingsService) { }

  ngOnInit(): void {
    this.router.navigate['/'];
  }

}
