import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { UserClaim } from 'src/app/models/userClaim';
import { CommonService } from './common/common.service';
import { SecurityGroupModel } from './webclient-api';
@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(public router: Router, private commonService: CommonService) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {

        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRoles;

        if (!this.doesUserHaveRole(expectedRole)) {
            this.router.navigate(['unauthorized']);
            return false;
        }
        return true;
    }

    public doesUserHaveRole(roleName): boolean {
        let claims = new UserClaim();
        let rolesData = this.commonService.getSessionStorageValue(AppConstants.UIUSERROLE);
        let userHasRole = false;
        if (rolesData !== '' && rolesData !== null) {
            claims = JSON.parse(rolesData, this.commonService.camelCaseReviver) as UserClaim;           
            roleName.forEach(element => {
                let securityGroupModel = claims.securityRoles as SecurityGroupModel[];
                securityGroupModel.forEach(role => {
                    if(role.groupAD.aDName == element) {
                        userHasRole = true;
                    }
                });

                if((element == AppConstants.CEREBRO_OWNER || element == AppConstants.CEREBRO_OWNER_DEV) && claims.isCerebro_Owner)
                    userHasRole = true;
            });
            return userHasRole;
        }

        return userHasRole;
    }
}