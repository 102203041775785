import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { CommonService } from '../common/common.service';
import { UserClaim } from 'src/app/models/userClaim';


@Injectable()
export class httpSetHeaders implements HttpInterceptor {

    constructor(private commonService: CommonService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('graph')) {
            let userInfo = this.commonService.getSessionStorageValue(AppConstants.LOGGEDINUSERINFO);
            if(userInfo !== undefined && userInfo !== null) {
                let email = JSON.parse(userInfo).username;
                let userName = JSON.parse(userInfo).name;
                let localAccountId = JSON.parse(userInfo).localAccountId;
                if(request.url.toLowerCase().indexOf("upload") !== -1)
                {
                    request = request.clone({
                        setHeaders: {
                            'enctype': 'multipart/form-data',
                            'loggedinusername': `${userName}`,
                            'emailId': `${email}`,
                            'localAccountId': `${localAccountId}`
                        }
                    });
                }
                else
                {
                    request = request.clone({
                        setHeaders: {
                            'Content-Type': 'application/json',
                            'loggedinusername': `${userName}`,
                            'emailId': `${email}`,
                            'localAccountId': `${localAccountId}`
                        }
                    });
                }
            }
        }
        return next.handle(request);
    }
}