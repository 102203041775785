import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { LoadingService } from './loading.service';
import * as _ from 'lodash';
import { ExperimentTypeModel, WebClientAPI } from './webclient-api';

@Injectable({
  providedIn: 'root'
})
export class ExperimentTypeService {
  private messageSource;
  
    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json' 
        })
    };
  constructor(private http: HttpClient, private loadingService: LoadingService, private webClientAPI: WebClientAPI) { }

  

  

public getExperimentTypes(val): Observable<any> {
  this.loadingService.setMessage('Loading Experiment Types...');
  return this.webClientAPI.experimentType_GetExperimentTypes(val)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
}

public getExperimentTypesByNamePrefix(prefix, name): Observable<any> {
  this.loadingService.setMessage('Loading Experiment Types By Prefix...');
  return this.webClientAPI.experimentType_GetExperimentTypesByNamePrefix(prefix, name)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
}

public getAnalystsForProjectGroupId(projectId, groupId): Observable<any> {
  this.loadingService.setMessage('Loading security group users...');
  return this.webClientAPI.experimentType_GetAnalystsForProjectGroupId(projectId, groupId)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
}


public CreateExperimentType(model: ExperimentTypeModel): Observable<any> { 
  this.loadingService.setMessage('Creating New Experiment Type...');
  this.messageSource = new BehaviorSubject(model);

  return this.webClientAPI.experimentType_CreateExperimentType(model)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
}

public UpdateExperimentType(model: ExperimentTypeModel): Observable<any> { 
  this.loadingService.setMessage('Updating Experiment Type...');
  this.messageSource = new BehaviorSubject(model);
  return this.webClientAPI.experimentType_UpdateExperimentType(model)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
}
  public cancelStatusUpdate() {
    this.messageSource = new BehaviorSubject('cancel');
  }

  refreshGrid() {
    let currentMessage;
    if(this.messageSource !== undefined) {
      currentMessage = this.messageSource.asObservable();
      this.messageSource = undefined;
    }
    return currentMessage;
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";  
    return dialogConfig;

  }
}
