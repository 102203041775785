import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { DataService } from 'src/app/shared/services/data.service';
import { ExperimentTypeService } from 'src/app/shared/services/experiment-type.service';
import { MessageService } from 'src/app/shared/services/message-service';
import { AnalystModel, ExperimentTypeModel, GroupModel, ProjectModel, SecurityGroupUserModel } from 'src/app/shared/services/webclient-api';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';
import { ProjectService } from 'src/app/shared/services/project-service/project.service'
import { GroupsService } from 'src/app/shared/services/group-service/groups.service'
import { CommonService } from 'src/app/shared/services/common/common.service';
import { ExperimentService } from 'src/app/shared/services/experiment.service';

@Component({
    selector: 'app-experiment-type-popup',
    templateUrl: './experiment-type-popup.component.html',
    styleUrls: ['./experiment-type-popup.component.scss']
})
export class ExperimentTypePopupComponent {
    experimentTypes: ExperimentTypeModel[] = [];
    selectedAnalystsValue: [] = [];
    clickEventTrigger: boolean = false;
    analystsList: Array<AnalystModel> = [];
    experimentTypeId: number;
    experimentTypeName: string;
    description: string;
    analysts: Array<AnalystModel>;
    defaultAnalyst: AnalystModel;
    oldExperimentTypeName: string;
    oldDescription: string;
    oldAnalysts: Array<AnalystModel>;
    oldDefaultAnalyst: AnalystModel;
    experimentIdPrefix: string = "";
    experimentIdSequenceNumber: number;
    isNameValid = true;
    isPrefixValid = true;
    prefixPattern = "[A-Za-z0-9 \\-]*";
    hadDataChanged: boolean = false;
    projects: ProjectModel[] = [];
    groups: GroupModel[] = [];
    selectedProject: number;
    selectedGroup: number;
    selectedProjectOld: number;
    selectedGroupOld: number;
    isProjectSelected: boolean = true;
    isGroupSelected: boolean = true;
    zeroPaddingLst: any[] = [];
    selectedZeroPading: any;
    experimentIdNumber: string = "";
    experimentPrefix: string = "";
    selectedpadding: string = "";
    selectedpaddingdb: string = "";
    isEditDisabled: boolean = false;
    isProjectOrGroupAssigned: boolean = false;
    constructor(public service: ExperimentTypeService,
        private dataService: DataService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ExperimentTypePopupComponent>,
        private toastr: ToastrService,
        private _messageService: MessageService,
        public experimentService: ExperimentService,
        private groupService: GroupsService,
        private commonService: CommonService,
        private projectService: ProjectService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    ngOnInit(): void {
        this.zeroPaddingList();
        this.loadProjects();
        if (this.data.selectedData != null) {
            this.experimentTypeId = this.data.selectedData.experimentTypeId;
            this.selectedProject = this.data.selectedData.projectId;
            if (this.selectedProject != undefined && this.selectedProject != 0) {
                this.projectSelectionChange(this.selectedProject)
            }
            this.selectedGroup = this.data.selectedData.groupId;
            this.selectedProjectOld = this.data.selectedData.projectId;
            this.selectedGroupOld = this.data.selectedData.groupId;
            this.oldExperimentTypeName = this.experimentTypeName = this.data.selectedData.name;
            this.oldDescription = this.description = this.data.selectedData.description;
            this.experimentIdPrefix = this.data.selectedData.prefix;
            this.experimentIdSequenceNumber = this.data.selectedData.sequence;
            this.oldAnalysts = this.analysts = this.data.selectedData.analysts;
            this.oldDefaultAnalyst = this.defaultAnalyst = this.data.selectedData.defaultAnalyst
            this.selectedpaddingdb = this.data.selectedData.zeroPadding;
            this.selectedpadding = this.selectedpaddingdb;
            this.experimentIdNumber = (this.experimentIdPrefix == undefined ? "" : this.experimentIdPrefix) + (this.selectedpadding == undefined ? "" : this.selectedpadding) + (this.experimentIdSequenceNumber == undefined ? "" : this.experimentIdSequenceNumber);
            this.getSecurityGroupUsersForProjectIdGroupId(this.selectedProject, this.selectedGroup)

           
            this.experimentService.hasExperimentWithExperimentTypeId(this.experimentTypeId).subscribe(
                result => { 
                                
                    if (result != undefined && result ==true) {
                        this.isProjectOrGroupAssigned = true;
                        
                    }
                },
                error => {
                    console.log('error');
                    this.toastr.error(error, 'Group load failed !!');
                });

            if (this.experimentTypeName != undefined && this.experimentTypeName != "") {
                this.isEditDisabled = true;

            }
        }
    }

    projectGroupAssignedAlert()
    {
        let dialogConfig = this.service.getDialogConfig();
        let headerMessage = "Info";
        let messageType = "Info"
        let _message = "This experiment type is already mapped to an experiment. You cannot change Project/Group at this point."
        dialogConfig.data = { message: _message, headerMessage: headerMessage, messageType: messageType };
        this.dialog.open(StatusDialogComponent, dialogConfig);

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    createExperimentType() {
        this.checkValidRecord();
    }
    zeroPaddingList() {

        this.zeroPaddingLst.push({ padding: "", val: "" })
        this.zeroPaddingLst.push({ padding: "0", val: "1" })
        this.zeroPaddingLst.push({ padding: "00", val: "2" })
        this.zeroPaddingLst.push({ padding: "000", val: "3" })
        this.zeroPaddingLst.push({ padding: "0000", val: "4" })
        this.zeroPaddingLst.push({ padding: "00000", val: "5" })
        this.zeroPaddingLst.push({ padding: "000000", val: "6" })
    }

    zeroPaddingChange(val) {

        this.selectedpadding = val;
        this.experimentIdNumber = (this.experimentIdPrefix == undefined ? "" : this.experimentIdPrefix) + (val == undefined ? "" : val) + (this.experimentIdSequenceNumber == undefined ? "" : this.experimentIdSequenceNumber);

    }
    selectExperimentPrefix(experimentIdPrefix) {
        this.experimentIdNumber = (experimentIdPrefix == undefined ? "" : experimentIdPrefix) + (this.selectedpadding == undefined ? "" : this.selectedpadding) + (this.experimentIdSequenceNumber == undefined ? "" : this.experimentIdSequenceNumber);
    }

    selectExperimentIdPrefix(experimentIdSequenceNumber) {
        this.experimentIdNumber = (this.experimentIdPrefix == undefined ? "" : this.experimentIdPrefix) + (this.selectedpadding == undefined ? "" : this.selectedpadding) + (experimentIdSequenceNumber == undefined ? "" : experimentIdSequenceNumber);
    }

    editAnalystsSelection() {
        this.hadDataChanged = true;
        let filteredAnalysts = this.analysts?.filter(x => x.analystName === this.defaultAnalyst?.analystName);
        if (filteredAnalysts?.length == 0) {
            this.defaultAnalyst = null;
        }
    }

    projectSelectionChange(projectId) {
        this.hadDataChanged = true;
        this.isProjectSelected = true;
        this.groupService.getGroupsByProjectId(projectId, false)
            .subscribe(
                result => {
                    this.groups = result;
                },
                error => {
                    console.log('error');
                    this.toastr.error(error, 'Group load failed !!');
                });

    }
    loadProjects() {
        this.projectService.getProjectsForAdminUser()
            .subscribe(
                result => {
                    this.projects = result;
                },
                error => {
                    console.log('error');
                    this.toastr.error(error, 'Project load failed !!');
                });

    }

    editGroupSelection(groupId) {
        this.hadDataChanged = true;
        this.isGroupSelected = true;
        this.analystsList = [];
        this.getSecurityGroupUsersForProjectIdGroupId(this.selectedProject, groupId);
    }



    editDefaultAnalystSelection() {
        this.hadDataChanged = true;
    }

    checkValidRecord() {
        let isValid: boolean = false;
        let experimentType = new ExperimentTypeModel({
            name: this.experimentTypeName,
            description: this.description,
            prefix: this.experimentIdPrefix,
            sequence: this.experimentIdSequenceNumber,
            defaultAnalyst: this.defaultAnalyst,
            analysts: this.analysts,
            experimentTypeId: this.experimentTypeId,
            projectId: this.selectedProject,
            zeroPadding: this.selectedpaddingdb,
            groupId: this.selectedGroup,
            isActive: true,
            createdBy: null,
            createdDate: null,
            modifiedBy: null,
            modifiedDate: null
        });        
        if(this.experimentTypeId>0 && this.isProjectOrGroupAssigned && ( this.selectedProjectOld !=this.selectedProject || this.selectedGroupOld!=this.selectedGroup ))
        {
            this.selectedProject=  this.selectedProjectOld;
            this.selectedGroup= this.selectedGroupOld;            
            this.editGroupSelection(this.selectedGroupOld);
            this.analysts= this.oldAnalysts;
            this.defaultAnalyst = this.oldDefaultAnalyst;           
           this.projectGroupAssignedAlert();
           return;
        }
        this.isProjecValueSelected()
        this.isGroupValueSelected()
        this.service.getExperimentTypesByNamePrefix(experimentType.prefix, experimentType.name)
            .subscribe(
                result => {
                    if (this.experimentTypeId == undefined) {
                        if (this.isNewExperimentTypeValid(result, experimentType) && this.isProjectSelected && this.isGroupSelected) {
                            var existingType = this.hasSequence(result, experimentType);
                            if (existingType !== undefined && existingType != null) {
                                let dialogConfig = this.service.getDialogConfig();
                                let headerMessage = "Error";
                                let messageType = "Error"
                                var sequence = "'" + existingType.sequence.toString() + "'";
                                var padding = existingType.zeroPadding == null ? "'" + "'" : "'" + existingType.zeroPadding + "'";
                                dialogConfig.data = { message: this.commonService.formatString(AppConstants.INVALIDSEQUENCE, sequence, padding), headerMessage: headerMessage, messageType: messageType };
                                this.dialog.open(StatusDialogComponent, dialogConfig);
                            }
                            else {
                                this.service.CreateExperimentType(experimentType)
                                    .subscribe(
                                        result => {
                                            this._messageService.filter('refresh');
                                            this.toastr.success('Experiment Type created successfully !!');
                                        },
                                        error => {
                                            console.log('error');
                                            this.toastr.error(error, 'Experiment Type creation failed !!');
                                        }
                                    );
                                this.dialogRef.close();

                            }
                        }
                    }
                    else {
                        if (this.isUpdateExperimentValid(result, experimentType) && this.isProjectSelected && this.isGroupSelected) {
                            if (this.isExperimentTypeModified(experimentType)) {
                                this.service.UpdateExperimentType(experimentType)
                                    .subscribe(
                                        result => {
                                            this._messageService.filter('refresh');
                                            this.toastr.success('Experiment Type updated successfully !!');
                                        },
                                        error => {
                                            console.log('error');
                                            this.toastr.error(error, 'Experiment Type update failed !!');
                                        }
                                    );

                                this.dialogRef.close();
                            }
                            else {
                                let dialogConfig = this.service.getDialogConfig();
                                let headerMessage = "Information";
                                let messageType = "Info"
                                dialogConfig.data = { message: AppConstants.NOCHANGEMESSAGE, headerMessage: headerMessage, messageType: messageType };
                                this.dialog.open(StatusDialogComponent, dialogConfig);
                            }
                        }
                    }

                },
                error => {
                    console.log(error);
                }
            );
    }

    isExperimentTypeModified(experimentType: ExperimentTypeModel) {
        return this.isModified(experimentType.analysts, this.oldAnalysts) || this.oldDefaultAnalyst.analystName !== experimentType.defaultAnalyst.analystName || this.oldDescription !== experimentType.description || this.selectedProjectOld !== this.selectedProject || this.selectedGroupOld !== this.selectedGroup || this.oldExperimentTypeName !== experimentType.name ? true : false
    }

    isModified = (newList, oldList) => {
        let isModified = false;
        const isListModified = analystName => {
            const val = oldList.find(x => x.analystName === analystName);
            if (val === undefined || newList.length !== oldList.length) {
                return isModified = true;
            }
            return isModified;
        };
        for (let i = 0; i < newList.length; i++) {
            if (isListModified(newList[i].analystName) && !isModified) {
                isModified = true;
            };
        };
        return isModified;
    };

    hasSequence(result, experimentType): ExperimentTypeModel {
        var type = result.find((expType) => expType.prefix.trim().toLowerCase() == experimentType.prefix.trim().toLowerCase());

        if (type !== undefined && type !== null) {
            if ((type.zeroPadding === null || type.zeroPadding === '') && (experimentType.zeroPadding === null || experimentType.zeroPadding === '')) {
                type.zeroPadding = null;
                experimentType.zeroPadding = null;
            }

            if (type.sequence < experimentType.sequence || type.sequence > experimentType.sequence || type.zeroPadding != experimentType.zeroPadding)
                return type;
            else
                return null;
        }
        return null;
    }

    isNewExperimentTypeValid(result, experimentType): boolean {

        if (result.some((expType) => expType.name.trim().toLowerCase() == experimentType.name.trim().toLowerCase())) {
            this.isNameValid = false;
        }
        else {
            this.isNameValid = true;
        }

        if (result.some((expType) => expType.prefix.trim().toLowerCase() == experimentType.prefix.trim().toLowerCase() && expType.projectId == experimentType.projectId && expType.groupId == experimentType.groupId)) {
            this.isPrefixValid = false;
        }
        else {
            this.isPrefixValid = true;
        }

        return this.isNameValid && this.isPrefixValid;
    }

    isUpdateExperimentValid(result, experimentType): boolean {

        if (result.some((expType) => expType.name.trim().toLowerCase() == experimentType.name.trim().toLowerCase() && expType.experimentTypeId !== experimentType.experimentTypeId)) {
            this.isNameValid = false;
        }
        else {
            this.isNameValid = true;
        }

        if (result.some((expType) => expType.prefix.trim().toLowerCase() == experimentType.prefix.trim().toLowerCase() && expType.experimentTypeId !== experimentType.experimentTypeId && expType.projectId == experimentType.projectId && expType.groupId == experimentType.groupId)) {
            this.isPrefixValid = false;
        }
        else {
            this.isPrefixValid = true;
        }

        return this.isNameValid && this.isPrefixValid;
    }

    isProjecValueSelected() {
        this.isProjectSelected = true;
        if (this.selectedProject == undefined || this.selectedProject == null) {
            this.isProjectSelected = false
        }
        return this.isProjectSelected;
    }

    isGroupValueSelected() {
        this.isGroupSelected = true;
        if (this.selectedGroup == undefined || this.selectedGroup == null) {
            this.isGroupSelected = false;
        }
        return this.isGroupSelected;
    }

    populateDropdowns() {

        this.dataService.getUserGroup().subscribe(res => {
            res.value.forEach(item => {
                if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env) {
                    if (item.displayName.includes(AppConstants.CEREBRO_ANALYST)) {
                        this.addAnalysts(item);
                    }
                }
                else if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Dev_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Qa_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Local_Env) {
                    if (item.displayName.includes(AppConstants.CEREBRO_ANALYST_DEV)) {
                        this.addAnalysts(item);
                    }
                }
            });
        });
    }

    analystSelected() {
        if (this.analysts == null || this.analysts.length === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    addAnalysts(item) {
        for (let i = 0; i < item.members.length; i++) {
            let user = this.analystsList?.find(x => x?.analystName === item.members[i].displayName);
            let analyst = this.seucrityGroupUsers?.find(x => x.userADId === item.members[i].id)
            if ((user === undefined || user === null) && item.members[i].accountEnabled && item.members[i].displayName !== null && (analyst != undefined && analyst != null)) {
                this.analystsList.push(new AnalystModel({
                    analystId: null,
                    analystName: item.members[i].displayName,
                    analystEmail: item.members[i].mail,
                    createdBy: '', modifiedBy: '', createdDate: null, modifiedDate: null, isActive: true,

                }));
            }
        }
    }

    seucrityGroupUsers: SecurityGroupUserModel[];
    public getSecurityGroupUsersForProjectIdGroupId(projectId, groupId) {

        this.service.getAnalystsForProjectGroupId(projectId, groupId).subscribe(res => {
            this.seucrityGroupUsers = res;
            this.populateDropdowns();
        },
            error => {
                console.log(error);
            });
    }

    compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

    compareByValue(f1: any, f2: any) {
        return f1 && f2 && f1.InstrumentName === f2.InstrumentName;
    }

    compareAnalystFn: ((f1: any, f2: any) => boolean) | null = this.compareAnalystByValue;

    compareAnalystByValue(f1: any, f2: any) {
        return f1 && f2 && f1.analystName === f2.analystName;
    }

    onClose() {
        this.dialogRef.close();
    }

    valueChange(event) {
        this.hadDataChanged = true;

    }
}
