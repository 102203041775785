import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationSettingsService, LogLevel } from 'src/app/application-settings.service';

export const APP_INSIGHTS = new InjectionToken<ApplicationInsights>('APP_INSIGHTS');

@Injectable({ providedIn: 'root' })
export class LoggingService {

  private appInsightsEnabled = false;

  constructor(@Inject(APP_INSIGHTS) public appInsights: ApplicationInsights, private appSettingsService: ApplicationSettingsService) {
    this.appInsightsEnabled = appInsights !== null;
  }

  public debug(message: string, ...optionalParams: any[]): void {
    if (this.appSettingsService.getLogLevel() <= LogLevel.debug) {
      console.debug(message, optionalParams);

      if (this.appInsightsEnabled) {
        this.appInsights.trackTrace({ message: message, severityLevel: LogLevel.debug });
      }
    }
  }

  public info(message: string, ...optionalParams: any[]): void {
    if (this.appSettingsService.getLogLevel() <= LogLevel.info) {
      console.info(message, optionalParams);

      if (this.appInsightsEnabled) {
        this.appInsights.trackTrace({ message: message, severityLevel: LogLevel.info });
      }
    }
  }

  public warn(message: string, ...optionalParams: any[]): void {
    if (this.appSettingsService.getLogLevel() <= LogLevel.warn) {
      console.warn(message, optionalParams);

      if (this.appInsightsEnabled) {
        this.appInsights.trackTrace({ message: message, severityLevel: LogLevel.warn });
      }
    }
  }

  public error(message: string, ...optionalParams: any[]): void {
    if (this.appSettingsService.getLogLevel() <= LogLevel.error) {
      console.error(message, optionalParams);

      if (this.appInsightsEnabled) {
        this.appInsights.trackTrace({ message: message, severityLevel: LogLevel.error });
      }
    }
  }
}
