import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { SecurityGroupService } from 'src/app/shared/services/security-group.service';
import { ProjectModel, GroupModel, SecurityGroupModel, SecurityGroupUserModel } from 'src/app/shared/services/webclient-api'
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message-service';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/shared/services/project-service/project.service';
import { GroupsService } from 'src/app/shared/services/group-service/groups.service'
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DataService } from 'src/app/shared/services/data.service';
import { SortByPipe } from 'src/app/shared/sort/sort-pipe';
@Component({
  templateUrl: './security-group.component.html',
  styleUrls: ['./security-group.component.css']

})

export class SecurityGroupComponent implements OnInit {
  private gridApi;
  public subscription: any;
  private gridColumnApi;
  public context: any;
  projects: ProjectModel[] = [];
  groups: GroupModel[] = [];
  selectedProject: number;
  selectedGroup: number;
  selectedSecurityGroup: string = "";
  selectedSecurityGroupId: number = 0;
  isGroupSelected: any;
  isProjectSelected: any;
  selectedRole: any;
  createdBy: any;
  modifiedBy: any;
  roles: any[];
  sortedData: any;

  componentTitle = AppConstants.SECURITYGROUPTITLE;
  componentName = AppConstants.SECURITYGROUPTITLE;
  securityGroups: SecurityGroupModel[] = [];
  securityGroup: SecurityGroupModel = null;  
  loginUser: any;
  isSecurityGroupEditable: boolean = true;
  isSaveEnable: boolean = false;
  searchAvailableUser: string;
  searchAssignedUser: string;
 
  availableUsers: SecurityGroupUserModel[] = [];
  availableUsersOriginal: SecurityGroupUserModel[] = [];
  assigendUsers: SecurityGroupUserModel[] = [];
  assigendUsersOrigial: SecurityGroupUserModel[] = [];
  assigendUsersOrigialLst: SecurityGroupUserModel[] = [];
  removedSecurityUsers:string[]=[];

  constructor(private toastr: ToastrService, private sortPipe: SortByPipe, private route: ActivatedRoute, private router: Router, private projectsService: ProjectService,
    private groupService: GroupsService,
    private _messageService: MessageService, private service: SecurityGroupService, private dialog: MatDialog, public datepipe: DatePipe, private commonService: CommonService, private dataService: DataService,) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {

    })
  }

  ngOnInit(): void {

    this.loadProjects();
    this.getADRoles();
    this.getSecurityGroups();
    let userInfo = this.commonService.getSessionStorageValue(AppConstants.LOGGEDINUSERINFO);
    if (userInfo !== undefined && userInfo !== null) {
      this.loginUser = JSON.parse(userInfo).name;
      this.createdBy = this.loginUser;
    }
  }



  loadSecurityGroups(model: SecurityGroupModel[]) {

    this.securityGroups.forEach(result => {
      result.securityGroupName = result.project?.name + " - " + result.group?.name + " - " + result.groupAD?.displayName;
    });

  }

  addNew() {
    this.selectedSecurityGroupId = 0;
    this.modifiedBy = null;
    this.selectedProject = null;
    this.selectedGroup = null;
    this.selectedRole = null;
    this.createdBy = this.loginUser;
    this.isSecurityGroupEditable = true;
    this.isSaveEnable = false;
    this.assigendUsers = [];
    this.assigendUsersOrigial = [];
    this.availableUsers=[];
    this.availableUsersOriginal=[];
  }


  insertNewSecurityGroup() {

    this.isSecurityGroupEditable = true;
    let projectname = this.projects?.filter(x => x.projectId == this.selectedProject)[0]?.name;
    let groupname = this.groups?.filter(x => x.groupId == this.selectedGroup)[0]?.name;
    let roleDisplayName = this.roles?.filter(x => x.groupADId == this.selectedRole)[0]?.displayName;
    this.selectedSecurityGroup = projectname + " - " + groupname + " - " + roleDisplayName;

    let securityGroupExist = this.securityGroups?.filter(x => x.securityGroupName?.trim() == this.selectedSecurityGroup?.trim())[0];
    if (this.selectedSecurityGroupId == 0 && (securityGroupExist != null || securityGroupExist != undefined)) {
      let dialogConfig = this.service.getDialogConfig();
      let headerMessage = "Info";
      let messageType = "Info"
      let msg = "Selected security group with the same project, group and role already exists.";
      dialogConfig.data = { message: msg, headerMessage: headerMessage, messageType: messageType };
      this.dialog.open(StatusDialogComponent, dialogConfig);
      return;
    }
    this.securityGroup = new SecurityGroupModel({
      securityGroupId: this.selectedSecurityGroupId,
      groupId: this.selectedGroup,
      projectId: this.selectedProject,
      groupADId: this.selectedRole,
      isActive: true,
      createdDate: new Date(),
      createdBy: this.loginUser,
      modifiedDate: new Date(),
      modifiedBy: this.loginUser,
      securityGroupUsers: this.assigendUsers

    });
    this.saveSecurityGroup()
  }

  saveSecurityGroup() {      
    this.removedSecurityUsers=[];
    this.assigendUsersOrigialLst?.forEach(x=>{      
      var user=this.securityGroup.securityGroupUsers?.filter(y=>y?.name?.toLocaleLowerCase()==x?.name.toLocaleLowerCase())[0]?.name;
      if(user==undefined && user==null)
      {
         this.removedSecurityUsers.push(x.name)
      }      
    });
   
    this.securityGroup.removedSecurityGroupUsers=this.removedSecurityUsers;
    this.service.InsertSecurityGroup(this.securityGroup).subscribe(
      result => {
        this.toastr.success('Save security group successful!!');
        this.getSecurityGroups();
        this.isSecurityGroupEditable = false;
        this.isSaveEnable = false;
      },
      error => {
        console.log('error');
        this.toastr.error(error, 'Insert security group  failed !!');
      });
  }


  getSecurityGroups() {
    this.service.GetSecurityGroups()
      .subscribe(
        result => {
          this.securityGroups = result;
          this.loadSecurityGroups(this.securityGroups);       
        },
        error => {
          console.log('error');
          this.toastr.error(error, 'Security group load failed !!');
        });
  }

  securityGroupChange(securityGroupId) {

    this.assigendUsers = [];
    this.assigendUsersOrigial = [];
    let securitygroup = this.securityGroups?.filter(x => x.securityGroupId == securityGroupId)[0];
    this.selectedSecurityGroupId = securityGroupId;
    if (securitygroup != null || securitygroup != undefined) {
      this.isSecurityGroupEditable = false;
      this.modifiedBy = this.loginUser;
      this.selectedProject = securitygroup.projectId;
      this.projectSelectionChange(this.selectedProject);
      this.selectedGroup = securitygroup.groupId;
      this.selectedRole = securitygroup.groupADId;
      this.createdBy = securitygroup.createdBy;

      this.securityGroup = new SecurityGroupModel({
        securityGroupId: this.selectedSecurityGroupId,
        groupId: this.selectedGroup,
        projectId: this.selectedProject,
        groupADId: this.selectedRole,
        isActive: true,
        createdDate: securitygroup.modifiedDate,
        createdBy: securitygroup.modifiedBy,
        modifiedDate: new Date(),
        modifiedBy: this.loginUser,
        securityGroupUsers: securitygroup?.securityGroupUsers

      });
      this.getUsersOnRole(securitygroup?.groupAD?.iD, true);
      this.assigendUsersOrigial = this.assigendUsers; 
    }
    this.isSaveEnable = false;
  }

  loadProjects() {
    this.projectsService.getProjects(true)
      .subscribe(
        result => {
          this.projects = result;
        },
        error => {
          console.log('error');
          this.toastr.error(error, 'Project load failed !!');
        });

  }

  isSaveEnabled() {
    if (this.selectedGroup != undefined && this.selectedProject != undefined && this.selectedRole != undefined) {
      this.isSaveEnable = true;
    }
  }
  projectSelectionChange(projectId) {
    this.isSaveEnabled();
    this.groupService.getGroupsByProjectId(projectId,true)
      .subscribe(
        result => {
          this.groups = result;
        },
        error => {
          console.log('error');
          this.toastr.error(error, 'Group load failed !!');
        });
  }

  groupSelectionChange() {
    this.isSaveEnabled();
  }

  roleSelectionChange(userRole) {
    this.assigendUsers = [];
    this.assigendUsersOrigial = [];
    let groupAdid = this.roles?.find(x => x.groupADId == userRole)?.iD;
    this.getUsersOnRole(groupAdid, false);
    this.isSaveEnabled();
  }

  getADRoles() {
    this.service.getADRoles().subscribe(
      result => {
        this.roles = result;
      },
      error => {
        console.log('error');
        this.toastr.error(error, 'Roles load failed !!');
      });
  }

  drop(event: CdkDragDrop<SecurityGroupUserModel[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
         
    this.assigendUsersOrigial = this.sortPipe.transform(this.assigendUsers, 'asc', 'name'); ;
    this.isSaveEnable = true;
  }

 
  getUsersOnRole(userRole, onPageLoad) {
    this.availableUsers = [];
    this.assigendUsers = [];
    this.assigendUsersOrigial = [];
    this.dataService.getData(userRole).subscribe(
      result => {
        this.sortedData = this.sortPipe.transform(result.value, 'asc', 'displayName');
        this.sortedData.forEach(x => {
          let item = new SecurityGroupUserModel({
            securityGroupUserId: x.securityGroupUserId,
            securityGroupId: this.selectedSecurityGroupId,
            userADId: x.id,
            name: x.displayName,
            isActive: true,
            createdDate: new Date(),
            createdBy: null,
            modifiedDate: new Date(),
            modifiedBy: null,
            securityGroupModel: null,
          });
          if (onPageLoad) {
            if (this.securityGroup.securityGroupUsers.find(d => d.userADId == x.id && d.isActive) != undefined) {
              this.assigendUsers.push(item);
              this.assigendUsersOrigialLst.push(item);
            }
            else {
              this.availableUsers.push(item)
            }
          }
          else {
            this.availableUsers.push(item)
          }
        })       
        this.availableUsersOriginal =this.availableUsers; 
      },
      error => {
        console.log('error');
        this.toastr.error(error, 'Get user failed !!');
      });

  }

  availableUsersSearch(text: string) {
    if (!text) {
      this.availableUsers = this.availableUsersOriginal;
      return;
    }


    this.availableUsers = this.availableUsersOriginal.filter(
      x => x?.name.toLowerCase().includes(text.toLowerCase())
    );
  }

  assignedUsersSearch(text: string) {
    if (!text) {
      this.assigendUsers = this.assigendUsersOrigial;
      return;
    }

    this.assigendUsers = this.assigendUsersOrigial.filter(
      x => x?.name.toLowerCase().includes(text.toLowerCase())
    );
  }

}