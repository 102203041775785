import { Component,Input, OnInit } from '@angular/core';
import { UserClaim } from 'src/app/models/userClaim';

import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/common-utility/appconstants';
@Component({
  selector: 'app-link-renderer',
  template: ` <button pButton type="button" iconPos="left" (click)="onClick($event)">{{params.value}}</button>`,
  styles:['button {background: none!important; border: none;padding: 0!important;font-family: arial, sans-serif;color: #069;text-decoration: underline;cursor: pointer;}button:hover{color:black !important;}']
  
})
export class LinkRendererComponent implements OnInit {

  data: any;
  params: any;
  label: string;
  claims: UserClaim;  

  //constructor(private commonService: CommonService,public dialog: MatDialog,public ExperimentDetailsService:AnalystExperimentDetailsService) {}  
  constructor(public dialog: MatDialog, private router: Router) {}  
 // sampleCount:string;
  agInit(params) {
    this.params = params;  
    this.label = this.params.label || null;    
    this.data=this.params.data;    
  }

  ngOnInit() {
   // this.ExperimentDetailsService.currentMessage.subscribe(Count => this.sampleCount = Count)
    
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    return dialogConfig;

  }
  // newMessage(SampleCount:string) {
  //   this.ExperimentDetailsService.changeMessage(SampleCount)
  // }
  onClick($event) {
  
    if(this.data?.resultFileId!=undefined && this.data?.resultFileId!=null)
    {
      this.params.context.componentParent.getFileDetailsById(this.params.data.resultFileId)
    }
    else
    {
      this.router.navigate([AppConstants.CEREBRORUNSHEETPAGE, this.data.experimentPlanningId]); 
    }
      //alert();
      //window.open("https://www.google.com","_self");
    
  }
}

