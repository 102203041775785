import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from '../../custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from '../../custom/edit-button-renderer/edit-button-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { GroupsService } from 'src/app/shared/services/group-service/groups.service'
import { CreateGroupComponent } from './group-popup/create-group.component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/shared/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { GroupModel } from 'src/app/shared/services/webclient-api';
import { CommonService } from 'src/app/shared/services/common/common.service';


@Component({
  templateUrl: '../../shared/grid-component/grid.component.html',
  styleUrls: ['./group.component.css']
})

export class GroupComponent implements OnInit, OnDestroy {
  private gridApi;
  private gridColumnApi;
  public savedFilter: any;
  public savedSort: any;
  rowData: any[] = [];
  public context;
  public subscription: any;
  isSaveDisabled = false;
  componentName = AppConstants.GROUP;
  componentTitle = AppConstants.GROUPTITLE;
  group: GroupModel;
  columnDefs = [
    { headerName: 'Name', field: 'name', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    { headerName: 'Description', field: 'description', sortable: true, filter: true, width: 350, tooltipValueGetter: (params) => params.value },
    { headerName: 'Modified By', field: 'modifiedBy', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Modified Date', field: 'modifiedDate', sortable: true, filter: true, width: 200, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) : '';
      }
    },
    { headerName: 'Active', field: 'isActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 150 },
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  ngOnInit() {
    this.getGroupsInfo();
  }

  getGroupsInfo() {
    this.service.getGroups()
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {
            this.rowData.push({
              groupId: r.groupId,
              name: r.name,
              description: r.description,
              isActive: r.isActive,
              createdDate: r.createdDate,
              createdBy: r.createdBy,
              modifiedDate: r.modifiedDate,
              modifiedBy: r.modifiedBy,

            })
          })
          
          this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDGROUPFILTER);
          this.savedSort = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDGROUPSORT);
        },
        error => {
          console.log(error);
        }
      )
  }

  defaultColDef: {
    flex: 1,
    minWidth: 100,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };
  //   hideRequiredControl = new FormControl(false);

  constructor(fb: FormBuilder, private dialog: MatDialog,
    private service: GroupsService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private commonService: CommonService,
    private _messageService: MessageService) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    })
  }

  updateStatus(groupModel: GroupModel, isActive: boolean) {
    this.service.updateGroupStatus(groupModel.groupId, isActive)
      .subscribe(result => {
        this.getGroupsInfo();
        this.toastr.success('Group updated successfully !!');
      },
        error => {
          this.toastr.error(error, 'Group update failed !!');
        })
  }

  add() {

    this.group = new GroupModel({
      groupId: 0,
      name: "",
      description: "",
      isActive: true,
      createdDate: new Date(),
      createdBy: "",
      modifiedDate: new Date(),
      modifiedBy: ""
    });
    this.showPopup();
  }

  update(row) {
    if (row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      this.group = row.data;
      this.showPopup();
    }
  }

  showPopup() {
    let dialogConfig = this.service.getDialogConfig();
    dialogConfig.data = { selectedData: this.group, selectedGridData: this.rowData };
    this.dialog.open(CreateGroupComponent, dialogConfig);
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  refreshGrid() {
    this.getGroupsInfo();
    this.gridApi.refreshClientSideRowModel('aggregate');
    this.onCloseEvent();
  }

  refreshGridFilters() {
    //this. getApiTypes();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.clearSortandFilter();
    this.onCloseEvent();
  }
  clearSortandFilter() {
    this.gridColumnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
    this.gridApi.setFilterModel(null);    
    this.gridApi.sizeColumnsToFit();
  }
  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onSortChanged(params) {
    this.savedSort = this.gridColumnApi.getColumnState();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDGROUPSORT, JSON.stringify(this.savedSort));
  }

  onFilterChanged(params) {
    this.savedFilter = this.gridApi.getFilterModel();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDGROUPFILTER, JSON.stringify(this.savedFilter));
  }

  onFirstDataRender(params) {

    if (this.savedSort != undefined && this.savedSort != null && this.savedSort.length != 0) {
      this.gridColumnApi.applyColumnState({
        state: this.savedSort,
        applyOrder: true,
      });
    }


    this.gridApi.setFilterModel(this.savedFilter);
  }
  
  onColumnMoved(event) {
    this.gridApi.sizeColumnsToFit();
  }

  ngOnDestroy() {
    try { 
        if (this.gridApi) {
            this.gridApi.destroy();
            this.gridApi = false;
        }

    } catch (error) {
      console.log(error);

    }
}
}