export class AppConstants {
  public static get TOKEN(): string {
    return "msal.idtoken";
  }

  public static get CEREBROEXPERIMENTPAGE(): string {
    return "/ExperimentSetup";
  }
  public static get  CEREBRO_OWNER(): string {
    return "RES-SG-Cerebro_Owner";
  }
  public static get  CEREBRO_OWNER_DEV(): string {
    return "RES-SG-Cerebro_DevT_Owner";
  }
  public static get NOCHANGEMESSAGE(): string {
    return "There are no changes to save. Please make changes and try again.";
  } 

  public static get CHANGEEXPRERIMENTSTATUSMESSAGE(): string {
    return "Are you sure you want to change the experiment status?";
  } 

  public static get COMPLETEEXPRERIMENTSTATUSMESSAGE(): string {
    return "You are about to change Experiment Status to Completed, but some steps are still In Progress.  Are you sure you want to proceed?";
  }

  public static get CEREBRORUNSHEETPAGE(): string {
    return "/ExperimentInformation";
  }

  public static get CEREBROAPITYPESPAGE(): string {
    return "/ApiTypes";
  }

  public static get CEREBROAPITYPEDETAILSPAGE(): string {
    return "/ApiType";
  }
 
  public static get CEREBRO_ADMIN(): string {
    return "RES-SG-Cerebro_Admin";
  }

  public static get CEREBRO_ANALYST(): string {
    return "RES-SG-Cerebro_Analyst";
  }

  public static get CEREBRO_OBSERVER(): string {
    return "RES-SG-Cerebro_Observer";
  }

  public static get CEREBRO_ADMIN_DEV(): string {
    return "RES-SG-Cerebro_DevT_Admin";
  }

  public static get CEREBRO_ANALYST_DEV(): string {
    return "RES-SG-Cerebro_DevT_Analyst";
  }

  public static get CEREBRO_OBSERVER_DEV(): string {
    return "RES-SG-Cerebro_DevT_Observer";
  }

  public static get Dev_Env(): string {
    return "dev";
  }

  public static get CEREBROLOGIN(): string {
    return "/postlogin";
  }

  public static get CEREBROHOME(): string {
    return "/Home";
  }

  public static get UNAUTHORIZED(): string {
    return "/unauthorized";
  }
  
  public static get  CEREBRO_OWNER_ID(): string {
    return "50c7bbd4-8822-420f-8dc4-8205572e9357";
  }
  public static get  CEREBRO_OWNER_DEV_ID(): string {
    return "b84f7340-5291-4165-b57a-b70dd230e103";
  }

  public static get Prod_Env(): string {
    return "prod";
  }

  public static get Qa_Env(): string {
    return "test";
  }

  public static get Uat_Env(): string {
    return "uat"
  }

  public static get Local_Env(): string {
    return "local"
  }

  public static get AUTHUSERINFO(): string {
    return "userInfo";
  }

  public static get UIUSERROLE(): string {
    return "uiUserRole";
  }

  public static get ANALYSTUSERS(): string {
    return "uiAnalystUsers";
  }

  public static get CLICKTYPE(): string {
    return "clickType";
  }

  public static get LOADING(): string {
    return "Loading...";
  }

  public static get USERPROPERTYFILTER(): string {
    return "$select=id,givenName,surname,displayName,mail,jobTitle,businessPhones,mobilePhone,displayName,streetAddress,city,postalCode,state,country,department,officeLocation,onPremisesSamAccountName,companyName"
  }

  public static get LOGGEDINUSERINFO(): string {
    return "userInfo";
  }

  public static get SAVEDDASHBOARDFILTER(): string {
    return "cerebro-filterinfo";
  }

  public static get SAVEDAPITYPEFILTER(): string {
    return "apitype-filterinfo";
  }

  public static get SAVEDTEMPLATEFILTER(): string {
    return "template-filterinfo";
  }

  public static get SAVEDFILEMAPPINGFILTER(): string {
    return "filemapping-filterinfo";
  }

  public static get SAVEDEXPERIMENTTYPEFILTER(): string {
    return "experimenttype-filterinfo";
  }

  public static get SAVEDPROJECTFILTER(): string {
    return "project-filterinfo";
  }

  public static get SAVEDGROUPFILTER(): string {
    return "group-filterinfo";
  }

  public static get SAVEDAPITYPESORT(): string {
    return "apitype-sortinfo";
  }

  public static get SAVEDTEMPLATESORT(): string {
    return "template-sortinfo";
  }

  public static get SAVEDFILEMAPPINGSORT(): string {
    return "filemapping-sortinfo";
  }

  public static get SAVEDEXPERIMENTTYPESORT(): string {
    return "experimenttype-sortinfo";
  }

  public static get SAVEDPROJECTSORT(): string {
    return "project-sortinfo";
  }

  public static get SAVEDGROUPSORT(): string {
    return "group-sortinfo";
  }

  public static get SAVEDRESULTSGRIDFILTER(): string {
    return "cerebro-resultsfilterinfo";
  }

  public static get SAVEDRESULTGRIDCOLUMNS(): string {
    return "cerebro-resultcolumnsinfo";
  }

  public static get SAVEDDASHBOARDSORT(): string {
    return "cerebro-sortinfo";
  }  
  public static get UPDATESTATUS(): string {
    return "Update Status";
  }
  public static get GROUP(): string {
    return "Group";
  }
  public static get GROUPTITLE(): string {
    return "Groups";
  }  
  public static get MODIFYGROUPTITLE(): string {
    return "Modify Group";
  } 
  public static get GROUPNEW(): string {
    return "New Group";
  } 

  public static get PROJECTTITLE(): string {
    return "Projects";
  }  
  public static get MODIFYPROJECTTITLE(): string {
    return "Modify Project";
  } 
  public static get PROJECTNEW(): string {
    return "New Project";
  } 
  public static get APITYPETITLE(): string {
    return "API Types";
  }  
  public static get TEMPLATETITLE(): string {
    return "Templates";
  }  
  public static get FILELINEAGETITLE(): string {
    return "File Mapping";
  }   
  public static get MODIFYFILELINEAGETITLE(): string {
    return "Modify File Mapping";
  }  
    public static get FILEMAPPINGTITLE(): string {
    return "File Mappings";
  }   
  public static get MODIFYFILEMAPPINGTITLE(): string {
    return "Modify File Mapping";
  }  
  public static get FILEMAPPINGNEW(): string {
    return "New File Mapping";
  }
  public static get CREATETEMPLATETITLE(): string {
    return "New Templates";
  }  
  public static get TEMPLATENEW(): string {
    return "New Template";
  }
  public static get TEMPLATEMODIFY(): string {
    return "Modify Template";
  }

  public static get GOTOTEMPLATES(): string {
    return "Go to Templates";
  }

  public static get GOTOEXPERIMENT(): string {
    return "Go to Experiment";
  }


  public static get EXPERIMENTTYPE(): string {
    return "Experiment Type";
  }

  public static get SELECT(): string {
    return "Select All";
  }

  public static get DESELECT(): string {
    return "Deselect All";
  }

  public static get EXPERIMENTTYPETITLE(): string {
    return "Experiment Types";
  }

  public static get DASHBOARD(): string {
    return "Dashboard";
  }

  public static get COMPLETE(): string {
    return "Completed";
  }
  
  public static get INPROGRESS(): string {
    return "InProgress";
  }

  public static get INPROGRESSUI(): string {
    return "In Progress";
  }

  public static get NEW(): string {
    return "New";
  }

  public static get PAUSED(): string {
    return "Paused";
  }

  public static get RESULTSTABNAME(): string {
    return "Results";
  }

  public static get FILESTATUSTABNAME(): string {
    return "File Status";
  }

  public static get FILESTABNAME(): string {
    return "Files";
  }

  public static get MESSAGETYPEINFO(): string {
    return "Info";
  }

  public static get MESSAGEHEADERINFO(): string {
    return "Information";
  }
  public static get MESSAGETYPEWARNING(): string {
    return "Warning";
  }
  
  public static get MESSAGETYPECONFIRMATION(): string {
    return "Confirmation";
  }
  public static get MESSAGETYPECONFIRM(): string {
    return "Confirm";
  }
  public static get MESSAGEUSERONERING(): string {
    return "You do not have sufficient permissions to create experiment in One-Ring. Do you want to proceed to create an experiment in Cerebro ?";
  }
  public static get FILESPROCESSING(): string {
    return "The system is currently processing manually uploaded files. As a result, 'Complete' is temporarily unavailable. Please try again later.";
  }
  public static get PasteText(): string {
    return "Paste (CTRL+V) Column Information Here";
  }

  public static get StrainRegistration(): string {
    return "Strain Registration";
  }

  public static get GetStrainInfo(): string {
    return "Get Strain Info";
  }  
  
  public static get GetStrainInformation(): string {
    return "Get Strain Information";
  }

  public static get JoinData(): string {
    return "Field Concatenation";
  }
  public static get SECURITYGROUPTITLE(): string {
    return "Security Group";
  }  

  public static get FILEMAPPING(): string {
    return "You have not created a File Mapping for this Project/Group combination.  Please create a File Mapping first before proceeding with the Template creation.";
  }

  public static get APITYPEMAPPING(): string {
    return "You have not created an API Type for this Project/Group combination.  If you are going to add a OneRing Process please create an API Type first before proceeding with the Template creation.";
  }

  public static get INVALIDSEQUENCE(): string {
    return "The experiment prefix has a sequence in progress. Please select the same sequence {0} and padding {1} to follow or select a different prefix.";
  }

}