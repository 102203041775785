import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialogConfig } from '@angular/material/dialog';
@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.css']
})
export class StatusDialogComponent implements OnInit {
  public params: ICellRendererParams;
  message: any;
  headerMessage: any;
  messageType: any;
  isControlDisabled: boolean = false;
  constructor(public dialogRef: MatDialogRef<StatusDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    if(this.data.message != undefined) {
      this.message = this.data.message;
    }
    else {
      this.message = this.data.isActive?"Are you sure you want to enable the" + " " + this.data.componentName + "?":"Are you sure you want to disable the" + " " + this.data.componentName + "?";
    }

    if(this.data.headerMessage != undefined) {
      this.headerMessage = this.data.headerMessage;
    }

    if(this.data.messageType != undefined) {
      this.messageType = this.data.messageType;
    }
    
  }

  onSubmit(){
    this.dialogRef.close(true);
  }

  onCancel(){
    this.dialogRef.close(false);
  }
  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    return dialogConfig;

  }

}
